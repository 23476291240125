import { regex } from '@wings-shared/core';
import { auditFields } from '@uplink/shared';
import { VALIDATION_REGEX } from '../../../../../Shared/Enums/Spacing.enum';

export const fields = {
  ...auditFields,
  id: {
    label: 'Address',
  },
  addressType: {
    label: 'Address Type*',
    rules: 'required',
  },
  addressLine1: {
    label: 'Address Line 1*',
    rules: 'required|string|between:1,200',
  },
  addressLine2: {
    label: 'Address Line 2',
    rules: 'string|between:1,200',
  },
  countryReference:{
    label: 'Country*',
    rules: 'required'
  },
  stateReference:{
    label: 'State',
  },
  cityReference:{
    label: 'City*',
    rules: 'required'
  },
  zipCode:{
    label: 'Zip Code',
    rules: 'string|between:1,10',
  },

    
}