import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme)=>({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
  root: {
    fontSize:'14px',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  tableWraper: {
    fontSize:'14px',
    '& .heading, & td, & th':{
      fontSize:'14px'
    },
    '& th':{
      padding:'10px 5px'
    },
    '& th:not(:first-child):before':{
      top:'10px'
    }
  },
}));