export enum MixPanelTrackingEvents {
    LOGIN = 'Login',
    VENDOR_GENERALINFORMATION = 'Vendor General Information',
    VENDOR_CONTACT = 'Vendor Contact',
    VENDOR_ADDRESS = 'Vendor Address',
    VENDOR_DOCUMENT = 'Vendor Document',
    VENDOR_USER = 'Vendor User',

    VENDOR_LOCATION_LIST = 'Vendor Location List',
    VENDOR_LOCATION_GENERALINFORMATION = 'Vendor Location General Information',
    VENDOR_LOCATION_CONTACT = 'Vendor Location Contact',
    VENDOR_LOCATION_ADDRESS = 'Vendor Location Address',
    VENDOR_LOCATION_DOCUMENT = 'Vendor Location Document',
    VENDOR_LOCATION_OPERATIONALESSENTIAL = 'Vendor Location Operational Essential',
    VENDOR_LOCATION_PRICING = 'Vendor Location Pricing',
    VENDOR_LOCATION_BULLETINS = 'Vendor Location Bulletins',
  }