import React, { FC, useEffect } from 'react';

import { EyeHideIcon, EyeShowIcon } from '@uvgo-shared/icons';
import { FlatIconButton } from '@uvgo-shared/buttons';

import { TableColumn } from '../types';
import { useStyles } from './ListItem.styles';

export declare type ListItemProps = {
  column: TableColumn;
  onClick: () => void;
  showAll: Boolean;
};

export const ListItem: FC<ListItemProps> = ({ column, onClick, showAll }: ListItemProps) => {
  const classes: Record<string, string> = useStyles();
  const [ isHidden, setIsHidden ] = React.useState<boolean>(column.hide);

  useEffect(() => {
    if (showAll) setIsHidden(false);
  }, [ showAll ]);

  const clickHandler = () => {
    setIsHidden(!isHidden);
    onClick();
  };

  return (
    <div className={classes.root}>
      <div className={classes.control}>
        <FlatIconButton onClick={clickHandler}>
          {isHidden ? (
            <div className={classes.hiddenItemBtn}>
              <EyeHideIcon size="large" />
            </div>
          ) : (
            <EyeShowIcon size="large" />
          )}
        </FlatIconButton>
      </div>
      <div className={classes.label}>{column.label}</div>
    </div>
  );
};
