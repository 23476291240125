export enum VENDOR_LEVEL_COMPARISON_FILTERS {
  VENDOR_NAME = 'Name',
  VENDOR_CODE = 'Code',
  VENDOR_STATUS = 'Status',
}

export enum VENDOR_LEVEL_DATA_FILTERS {
  NAME = 'Name',
  CODE = 'Code',
  STATUS = 'Status',
}
