import { regex } from '@wings-shared/core';
import { auditFields } from '@uplink/shared';
import { VALIDATION_REGEX } from '../../../Shared/Enums/Spacing.enum';

export const fields = {
  ...auditFields,
  id: {
    label: 'Vendor Location Id',
  },
  coordinatingOffice: {
    label: 'Coordinating Office',
  },
  isSupervisoryAgentAvailable: {
    label: 'Supervisory Agent Available on Request',
  },
  agentDispatchedFrom: {
    label: 'Agent Dispatched From',
  },
  isPrincipleOffice: {
    label: 'Principle Office',
  },
  vendorLevel: {
    label: 'Vendor Level*',
    // rules: 'required'
  },
  certifiedMemberFeeSchedule: {
    label: 'Certified Member Fee Schedule',
  },
  certifiedMemberFee: {
    label: 'Certified Member Fee',
    rules: 'numeric|between:0,999999.99'
  },
  contractRenewalDate: {
    label: 'Contract Renewal Date',
  },
  complianceDiligenceDueDate: {
    label: 'Compliance Due Diligence Due Date',
    rules: 'required'
  },
  startDate: {
    label: 'Opening Date',
    // rules: 'required'
  },
  endDate: {
    label: 'Closing Date',
  },
  isProprietary: {
    label: 'Proprietary',
  },
  netSuitId: {
    label: 'NetSuite ID',
    rules: 'numeric|between:0,999999'
  },
  creditProvidedBy: {
    label: 'Credit Provided By',
  },
  locationAirfield: {
    label: 'Location on Airfield',
    rules: `string|between:2,200|regex:${VALIDATION_REGEX.TWO_WHITESPACE_STRING}`
  },
  airToGroundFrequency: {
    label: 'Air to Ground Frequency (ARINC) ',
    rules: 'numeric|between:0,999.99999|regex:/^\\d+(\\.\\d{1,5})?$/'
  },
  managerName: {
    label: 'Manager Name',
    rules: `string|between:2,100|regex:${VALIDATION_REGEX.TWO_WHITESPACE_STRING}`
  },
  asstManagerName: {
    label: 'Asst Manager Name',
    rules: 'string|between:2,100'
  },
  appliedOperationType: {
    label: 'Operation Type',
    rules: 'required'
  },
  appliedOperationTypeId: {
    label: 'Operation Type Id',
  },
  provideCoordinationFor: {
    label: 'Provides Coordination For',
  },
  commsCopyFor: {
    label: 'Comms Copy For',
  },
  customers: {
    label: 'Customer',
  },
  appliedPaymentOptions: {
    label: 'Payment Option',
  },
  appliedCreditAvailable: {
    label: 'Credit Available',
  },
  creditProvidedFor: {
    label: 'Credit Provided For',
  },
  appliedMainServicesOffered: {
    label: 'Main Service\'s*',
    rules: 'required'
  },
};
