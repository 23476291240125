import { regex } from '@wings-shared/core';
import { auditFields } from '@uplink/shared';
import { EDITOR_TYPES } from '@uplink-shared/form-controls';
import { VALIDATION_REGEX } from '../../../Shared/Enums/Spacing.enum';

export const fields = {
  ...auditFields,
  id: {
    label: 'Contact',
  },
  contactMethod: {
    label: 'Contact Method*',
    rules: 'required',
  },
  contact: {
    label: 'Contact*',
    rules: `required|string|between:3,200|regex:${VALIDATION_REGEX.TWO_WHITESPACE_STRING}`,
    placeholder: 'Please select contact Method'
  },
  contactType: {
    label: 'Contact Type*',
    rules: 'required',
  },
  contactName:{
    label: 'Contact Name',
    rules: 'string|between:0,200'
  },
  contactUsegeType: {
    label: 'Usage Type*',
    rules: 'required',
  },
  title:{
    label: 'Title',
    rules: `string|between:2,50|regex:${VALIDATION_REGEX.TWO_WHITESPACE_STRING}`,
  },
  isSMSCompatible:{
    label: 'SMS Compatible',
    type: EDITOR_TYPES.CHECKBOX,
  },
}