import React, { FC, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import {
  newVendorOnboarding,
  CustomList,
  VendorOnBoardSlideOneNTwoModel,
  VendorOnBoardSlideThreeDocument,
  CustomDialog,
} from '../../../Shared';
import { styles } from './Slide3.styles';
import { SidebarStore } from '@uplink-shared/layout';
import { withStyles, Grid, Avatar, Box, Typography } from '@material-ui/core';
import { IAPIPageResponse, IClasses, UIStore } from '@wings-shared/core';
import { SlideOneAndTwoStore, SlideThreeStore, VendorLocationStore } from '../../../../Stores';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { UploadIcon } from '@uvgo-shared/icons';
import DescriptionIcon from '@material-ui/icons/Description';
import { finalize, takeUntil } from 'rxjs/operators';
import { AuthStore } from '@uplink-shared/security';
import { useUnsubscribe } from '@wings-shared/hooks';
import { useGridState } from '@uplink-shared/custom-ag-grid';
import { IAPIDownloadDocumentFile } from '../../../Shared/Interfaces/Request/API-Request-VendorDocument';
import { AlertStore } from '@uvgo-shared/alert';
import { useNavigate } from 'react-router';
import { ModalStore } from '@uvgo-shared/modal-keeper';

interface Props {
  classes?: IClasses;
  registerSaveData: (saveData: () => void) => void;
  activeStep: number;
  setActiveStep: React.Dispatch<number>;
  vendorLocationStore: VendorLocationStore;
  slideThreeStore: SlideThreeStore;
  slideOneAndTwoStore: SlideOneAndTwoStore;
  onNextButtonDisable: (boolean) => void;
}

const Slide3: FC<Props> = ({
  classes,
  registerSaveData,
  slideThreeStore,
  activeStep,
  setActiveStep,
  vendorLocationStore,
  slideOneAndTwoStore,
  onNextButtonDisable,
}) => {
  const unsubscribe = useUnsubscribe();
  const gridState = useGridState();
  const navigate = useNavigate();

  const loadDocuments = (operationTypeId?: number) => {
    UIStore.setPageLoader(true);
    slideThreeStore
      ?.getDocumentsByOperationTypeId(operationTypeId ? operationTypeId : vendorLocationStore.operationTypeId)
      .subscribe(() => UIStore.setPageLoader(false));
  };

  useEffect(() => {
    registerSaveData(saveData);
    return () => registerSaveData(null);
  }, []);

  useEffect(() => {
    onNextButtonDisable(false);
  }, []);

  const saveData = () => {
    setActiveStep(3);
    slideOneAndTwoStore.activeStep = 3;
  };

  useEffect(() => {
    SidebarStore.setNavLinks(newVendorOnboarding(), 'vendor', 'Vendor Locations', '/vendor/locations');
    if (AuthStore.vendorProfile?.id) {
      loadInitialData();
    }
  }, [ activeStep ]);

  useEffect(() => {
    if (vendorLocationStore.operationTypeId) {
      if (slideOneAndTwoStore.tempLocationId) {
        loadUploadedDocumentData(vendorLocationStore.operationTypeId);
      }
    }
  }, [ vendorLocationStore.operationTypeId, slideOneAndTwoStore.tempLocationId ]);

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    slideOneAndTwoStore
      .getByVendorId(AuthStore.vendorProfile.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: VendorOnBoardSlideOneNTwoModel) => {
        if (response[0]) {
          slideOneAndTwoStore.tempLocationId = response[0].tempLocationId;
          if (slideOneAndTwoStore.tempLocationId) {
            loadUploadedDocumentData(response[0].operationType?.id).then(() => {
              if (response[0].operationType) {
                vendorLocationStore.operationTypeId = response[0].operationType?.id;
                loadDocuments(response[0].operationType?.id);
              } else {
                vendorLocationStore.operationTypeId = 0;
              }
            });
          }
        }
      });
  };

  const loadUploadedDocumentData = (operationTypeId?: number): Promise<void> => {
    return new Promise((resolve, reject) => {
      UIStore.setPageLoader(true);
      gridState.setIsProcessing(true);

      slideThreeStore
        .getUploadedDocuments(slideOneAndTwoStore.tempLocationId)
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => {
            UIStore.setPageLoader(false);
            gridState.setIsProcessing(false);
          })
        )
        .subscribe({
          next: (response: IAPIPageResponse<[VendorOnBoardSlideThreeDocument]>) => {
            const results = VendorOnBoardSlideThreeDocument.deserializeList(response.results);

            if (operationTypeId) {
              slideThreeStore?.getAllDocumentsByOperationTypeId(operationTypeId).subscribe({
                next: response => {
                  const filteredItems = results.filter(data =>
                    response.operationTypeDocumentLists.some(
                      item => data.operationTypeDocument.documentName.id === item.documentType.id
                    )
                  );
                  gridState.setGridData(filteredItems);
                  resolve();
                },
                error: err => {
                  reject(err);
                },
              });
            } else {
              resolve();
            }
          },
          error: err => {
            reject(err);
          },
        });
    });
  };

  const downloadFile = (data: VendorOnBoardSlideThreeDocument) => {
    UIStore.setPageLoader(true);
    slideThreeStore
      ?.downloadDocumentFile(AuthStore?.vendorProfile?.id, data.documentUri)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe({
        next: (response: IAPIDownloadDocumentFile) => {
          const link = document.createElement('a');
          link.href = response.documentUri;
          link.target = '_blank';
          link.download = data.operationTypeDocument.documentName.name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        error: error => {
          AlertStore.info(`Error Downloading ${error.message}`);
        },
      });
  };

  const deleteDocument = (item: VendorOnBoardSlideThreeDocument): void => {
    UIStore.setPageLoader(true);
    slideThreeStore
      .deleteDocumentById(item.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe({
        next: () => {
          setActiveStep(2);
          slideOneAndTwoStore.activeStep = 2;
          loadInitialData();
        },
        error: error => {
          AlertStore.info(error.message);
        },
      });
  };

  const getConfirmation = (item: VendorOnBoardSlideThreeDocument): void => {
    ModalStore.open(
      <CustomDialog
        title="Remove this Document"
        message={'Are you sure you want to remove this document?'}
        yesButton="Remove this Document"
        noButton="Cancel"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => deleteDocument(item)}
      />
    );
  };

  const colDefNew = [
    {
      headerName: 'Document Name',
      field: 'operationTypeDocument.documentName.name',
    },
    {
      headerName: 'Other Name',
      field: 'operationTypeDocument.otherName',
    },
    {
      headerName: 'Start Date',
      field: 'startDate',
    },
    {
      headerName: 'Expiration Date',
      field: 'endDate',
    },
    {
      headerName: 'Status',
      field: 'documentStatus.name',
    },
    {
      headerName: 'Last Updated',
      field: 'lastUpdated',
    },
    {
      headerName: 'Document URI',
      field: 'documentUri',
    },
    {
      field: 'actionRenderer',
      headerName: '\u00A0\u00A0\u00A0\u00A0Edit\u00A0\u00A0\u00A0\u00A0\u00A0Delete',
    },
  ];

  const onEdit = item => {
    navigate(
      `/vendor/new-vendor-information/slide3/edit/${item?.operationTypeDocument?.documentName?.id}/${vendorLocationStore.operationTypeId}/${item.id}`
    );
  };

  const onUploadNew = item => {
    navigate(
      `/vendor/new-vendor-information/slide3/new/${item?.documentType?.id}/${vendorLocationStore.operationTypeId}/${item.id}`
    );
  };

  return (
    <>
      <Grid container spacing={3} className={classes.editorWrapperContainer} xs={12}>
        {slideThreeStore?.newVendorDocuments.map((item, index) => {
          return (
            <Grid container spacing={6} className={classes.innerGrid} xs={12} direction="row">
              <Grid item xs={5} style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  style={{
                    backgroundColor: '#2C313D',
                    fontSize: '14px',
                    color: '#FFFFFF',
                    fontWeight: 600,
                    height: 53,
                    width: 53,
                    marginRight: '20px',
                  }}
                >
                  <DescriptionIcon />
                </Avatar>
                <Typography className={classes.heading}>{item.documentType.name}</Typography>
              </Grid>
              <Grid item xs={5}>
                <label>{item.documentType?.description}</label>
              </Grid>
              <Grid item xs={2}>
                <Box className={`${classes.primaryButton}`}>
                  <PrimaryButton variant="contained" startIcon={<UploadIcon />} onClick={() => onUploadNew(item)}>
                    Upload document
                  </PrimaryButton>
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      {gridState.data.length > 0 && (
        <>
          <Typography className={classes.heading}>Uploaded Documents</Typography>
          <CustomList
            classes={classes}
            colDef={colDefNew}
            rowData={gridState.data}
            isHeaderVisible={false}
            onEdit={item => onEdit(item)}
            showEditButton={true}
            onDownload={item => downloadFile(item)}
            onDelete={item => getConfirmation(item)}
            showDeleteButton={true}
            isVendorLocationDocument={true}
            isLoading={UIStore.pageLoading}
          />
        </>
      )}
    </>
  );
};

export default inject(
  'slideThreeStore',
  'vendorLocationStore',
  'slideOneAndTwoStore'
)(withStyles(styles)(observer(Slide3)));
