import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      width: 350,
      padding: 20,
      height: '600px',
    },
    menuRoot:{
      marginTop: '30px',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      flexGrow: 1,
      fontSize: 18,
      fontWeight: 700,
      paddingBottom: '10px',
      borderBottom: '1px solid #D3D3D3',
    },
    closeBtn: {
      flexShrink: 0,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
    sectionHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    sectionFirstTitle: {
      fontSize: 14,
      fontWeight: 400,
      marginBottom: 5,
      color: '#1976D2',
      cursor: 'pointer'
    },
    sectionSecondTitle: {
      fontSize: 14,
      fontWeight: 600,
      marginBottom: 5,
      color: '#7C7C7C',
    },
    toggleActive: {
      borderColor: theme.palette.iconButtonsPalette.borderColor.active,
      color: theme.palette.iconButtonsPalette.textColor.active,
      backgroundColor: theme.palette.iconButtonsPalette.backgroundColor.active,
    },
  };
});
