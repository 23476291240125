import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    customHeight: {
      height: 'calc(100vh - 250px)',
    },
    editorWrapperContainer: {
      overflow: 'auto',
      // height:'100%',
      height:'calc(100vh - 150px)',
      '& .MuiCollapse-entered':{
        paddingBottom:'50px'
      }
    },
    headerActions: {
      justifyContent: 'space-between',
      '& button':{
        backgroundColor:'#005295!important',
        color:'#ffffff',
        '&:hover':{
          backgroundColor:'#63A4FF!important'
        }
      },
      mainContainer:{
        border: '1px solid gray',
        '& [class*="DetailsEditorWrapper-"]':{
          marginTop: theme.spacing(0),
        },
        '& [class*="makeStyles-resetHeight"]':{
          padding: '0',
        }
      }
    },
    // mainContainer:{
    // border: '1px solid gray',
    //   '& [class*="DetailsEditorWrapper-"]':{
    //     marginTop: theme.spacing(0),
    //     overflow: 'inherit',
    //   },
    //   '& [class*="makeStyles-resetHeight"]':{
    //     padding: '0',
    //   }
    // }
  });