import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 10,
      alignItems: 'center',
      height: 40,
    },
    icon: {
      flexShrink: 0,
      width: 10,
      height: 14,
    },
    label: {
      fontSize: 14,
      fontWeight: 400,
      color: '#202020',
    },
    control: {
      flexShrink: 0,
      '& .MuiIconButton-root': {
        color: '#202020 !important',
      },
    },
    hiddenItemBtn: {
      color: '#d7d7d7',
    },
  };
});
