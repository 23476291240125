import { createStyles, Theme } from '@material-ui/core';

export const styles = createStyles((theme: Theme) => ({
  columns: {
    display: 'flex',
    justifyContent: 'space-between',
    textWrap: 'nowrap',
    '& .MuiButton-root': {
      marginTop: '-10px',
      marginLeft: '100px',
      marginBottom: '8px'
    },
    '& .MuiButton-label': {
      textTransform: 'none',
    },
    '& .MuiGrid-grid-xs-4':{
      flexBasis: '0'
    },
    '& .MuiGrid-spacing-xs-3 > .MuiGrid-item':{
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center'
    }
  },
  defaultButton: {
    '& button:hover':{
      backgroundColor: '#FFFFFF',
      color:'#63A4FF',
      textDecoration: 'underline'
    }
  },
}));
