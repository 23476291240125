import { regex } from '@wings-shared/core';
import { auditFields } from '@uplink/shared';

export const fields = {
  ...auditFields,
  id: {
    label: 'Vendor Location Id',
  },
  aircraftParkingOptionLatitudeLongitude:{
    label: 'Aircraft Parking Options Map'
  },
  aircraftHandlingLocationLatitudeLongitude:{
    label: 'Aircraft Handling Location'
  },
  aircraftParkingOptionLatitudeLongitudeCityName:{
    label: 'Aircraft Parking Options Map',
  },
  aircraftHandlingLocationLatitudeLongitudeCityName:{
    label: 'Aircraft Handling Location'
  },
  agentFeesApply:{
    label: 'Agent Fees Apply'
  },
  aircraftParkingField:{
    label: 'Aircraft Parking on Field',
    rules: 'string|between:0,300'
  },
  appliedAircraftParkingOptions:{
    label: 'Aircraft Parking Options'
  },
  aircraftParkingDistanceFBO:{
    label: 'Aircraft Parking Distance from FBO'
  },
  appliedAircraftSpotAccommodation:{
    label: 'Aircraft Spot Accommodation'
  },
  hangarAvailable:{
    label: 'Hangar Available'
  },
  hangarAvailableSpace:{
    label: 'Hangar Available Space',
    rules: `numeric|between:0,999999|regex:${regex.numberOnly}`
  },
  hangerAvailableUom:{
    label: 'Hanger Available UOM'
  },
  towbarRequired:{
    label: 'Towbar Required'
  },
  appliedTowbarScenarios:{
    label: 'Towbar Required For'
  },
  towbarRequirement:{
    label: 'Towbar Requirements',
    rules: 'string|between:0,200'
  },
  appliedAvailableFacilities:{
    label: 'Available Facilities'
  },
  internationalArrivalProcedures:{
    label: 'International Arrival Procedures',
    rules: 'string|between:0,1000'
  },
  domesticArrivalProcedures:{
    label: 'Domestic Arrival Procedures',
    rules: 'string|between:0,1000'
  },
  domesticDepartureProcedures:{
    label: 'Domestic Departure Procedures',
    rules: 'string|between:0,1000'
  },
  internationalDepartureProcedures:{
    label: 'Passenger International Departure Procedures',
    rules: 'string|between:0,1000'
  },
  appliedDisabilityAccommodation:{
    label: 'Disability Accommodation Availability'
  },
  arrivalCrewPaxPassportHandling:{
    label: 'Arrival Crew/Pax Passport Handling'
  },
  luggageHandling:{
    label: 'Arrival Luggage Handling'
  },
  arrivalMeetingPoint:{
    label: 'Arrival Meeting Point',
    rules: 'string|between:0,300'
  },
  earlyCrewArrival:{
    label: 'Minutes Crew should arrive prior to departure',
    rules: `numeric|between:0,3|regex:${regex.numberOnly}`
  },
  earlyPaxArrival:{
    label: 'Minutes Pax should arrive prior to departure',
    rules: `numeric|between:0,3|regex:${regex.numberOnly}`
  },
  customsClearanceTiming:{
    label: 'Customs Clearance Timing (minutes)',
    rules: `numeric|between:0,3|regex:${regex.numberOnly}`
  },
  appliedAmenities:{
    label: 'Amenities'
  },  
};
