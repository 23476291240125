import React, { FC, useEffect, useState } from 'react';
import { TableColumn, TableColumnManagerProps } from './types';
import { useStyles } from './GridColumnManager.styles';
import classNames from 'classnames';
import { ListItem } from './ListItem/ListItem';
import { ColumnsIcon } from '@uvgo-shared/icons';
import { IconButton, Menu } from '@material-ui/core';

const GridColumnManager: FC<TableColumnManagerProps> = ({ columns, onChange, isRowEditing }) => {
  const classes: Record<string, string> = useStyles();
  const [ allColumns, setAllColumns ] = useState<TableColumn[]>([]);
  const [ showAll, setShowAll ] = useState<Boolean>(false);
  const [ isOpen, setIsOpen ] = useState(false);

  const toggleHandle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setShowAll(false);
    if (!columns || !Array.isArray(columns)) return;
    setAllColumns(columns);
  }, [ columns, allColumns ]);

  useEffect(() => {
    if (isRowEditing) {
      showAllColumns();
    }
  }, [ isRowEditing ]);
  
  const updateColumnsHandler = () => {
    onChange(allColumns);
  };

  const showAllColumns = () => {
    const updatedColumns = allColumns.map(column => {
      column.hide = false;
      return column;
    });
    setShowAll(true);
    setAllColumns(updatedColumns);
    updateColumnsHandler();
  };

  return (
    <>
      <IconButton
        className={classNames({ [classes.toggleActive]: isOpen })}
        onClick={toggleHandle}
        disabled={isRowEditing}
      >
        <ColumnsIcon size="large" />
      </IconButton>
      <Menu
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        className={classes.menuRoot}
      >
        <div className={classes.root}>
          <div className={classes.header}>
            <div className={classes.title}>View options</div>
          </div>
          <div className={classes.content}>
            <div className={classes.section}>
              <div className={classes.sectionHeader}>
                <div className={classes.sectionFirstTitle} onClick={showAllColumns}>
                  Show All
                </div>
                <div className={classes.sectionSecondTitle}>Columns</div>
              </div>
              <div className={classes.sectionContent}>
                {allColumns.map((column: TableColumn) => (
                  <ListItem
                    key={column.label}
                    column={column}
                    onClick={() => {
                      column.hide = !column.hide;
                      updateColumnsHandler();
                    }}
                    showAll={showAll}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Menu>
    </>
  );
};

export default GridColumnManager;
