import React, { FC, ReactNode, useEffect, useState } from 'react';
import { CityModel, CountryModel, MixPanelTrackingEvents, StateModel, useBaseUpsertComponent } from '@uplink/shared';
import {
  SETTING_ID,
  VendorAddressModel,
  VendorLocationModel,
  ViewInputControls,
  SidebarMenus,
  COLLECTION_NAMES,
  CustomButton
} from '../../../Shared';
import { SettingsStore, VendorManagementStore, VendorLocationStore } from '../../../../Stores';
import { useUnsubscribe } from '@wings-shared/hooks';
import { useGridState } from '@wings-shared/custom-ag-grid';
import { useStyles } from './UpsertAddress.styles';
import { inject, observer } from 'mobx-react';
import { fields } from './Fields';
import {
  IAPISearchFiltersDictionary,
  IClasses,
  IOptionValue,
  UIStore,
  GRID_ACTIONS,
  SEARCH_ENTITY_TYPE,
  IAPIGridRequest,
  Utilities,
} from '@wings-shared/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  DetailsEditorHeaderSection,
  DetailsEditorWrapper,
  ConfirmNavigate,
  SidebarStore,
  ConfirmDialog
} from '@uplink-shared/layout';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import { SettingNamesMapper } from '../../../../Stores/SettingsMapper';
import { AuthStore } from '@uplink-shared/security';
import { Box, Button, Typography } from '@material-ui/core';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { AnalyticsStore } from '@uplink-shared/analytics';

interface Props {
  settingsStore: SettingsStore;
  vendorLocationStore: VendorLocationStore;
  vendorManagementStore: VendorManagementStore;
  params?: { id: Number };
  classes?: IClasses;
  searchFilters: IAPISearchFiltersDictionary;
}

const UpsertAddress: FC<Props> = observer(
  ({ settingsStore, vendorLocationStore, vendorManagementStore, searchFilters }) => {
    const classes = useStyles();
    const unsubscribe = useUnsubscribe();
    const params = useParams();
    const gridState = useGridState();
    const useUpsert = useBaseUpsertComponent<VendorLocationModel>(params, fields, searchFilters);

    const formRef = useUpsert.form;
    const navigate = useNavigate();
    const [ countryList, setCountryList ] = useState([]);
    const [ statesList, setStatesList ] = useState([]);

    const getBasePath = (): string => {
      if (params.id) {
        return `vendor/addresses/upsert/${params.id}/`;
      }
      return 'vendor/addresses/upsert/';
    };

    useEffect(() => {
      AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_ADDRESS);
      loadInitialData();
      SidebarStore.setNavLinks(SidebarMenus(), getBasePath());
      vendorManagementStore.getVmsCountryCode().subscribe(response => {
        setCountryList(response.results);
      });
    }, []);

    const isEditable = useUpsert.isEditable;
    console.log('params',params)

    const loadInitialData = (pageRequest?: IAPIGridRequest) => {
      if (params.id) {
        const request: IAPIGridRequest = {
          pageNumber: gridState.pagination.pageNumber,
          pageSize: gridState.pagination.pageSize,
          ...pageRequest,
        };
        settingsStore.getSettings( SETTING_ID.SETTING_ADDRESS_TYPE,request).subscribe();
        UIStore.setPageLoader(true);
        vendorManagementStore
          ?.getVendorAddressById(params.id)
          .pipe(
            takeUntil(unsubscribe.destroy$),
            finalize(() => UIStore.setPageLoader(false))
          )
          .subscribe((response: VendorAddressModel) => {
            setFormValues(response);
          });
      }
    };

    const errorHandler = (errors: object, id): void => {
      Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
    };

    const setFormValues = response => {
      useUpsert.setFormValues(response);
      const vendorAddress = VendorAddressModel.deserialize(response);
      const countryModel = new CountryModel({
        id: vendorAddress?.countryReference.countryId,
        commonName: vendorAddress?.countryReference?.name,
        isO2Code: vendorAddress?.countryReference?.code,
      });
      const stateModel = new StateModel({
        id: vendorAddress?.stateReference?.stateId,
        commonName: vendorAddress?.stateReference?.name,
        code: vendorAddress?.stateReference?.code,
      });
      const cityModel = new CityModel({
        id: vendorAddress?.cityReference?.id,
        cappsCode: vendorAddress?.cityReference?.cappsCode,
        commonName: vendorAddress?.cityReference?.commonName,
      });
      useUpsert.getField('id').set(vendorAddress.id);
      useUpsert.getField('addressType').set(vendorAddress.addressType);
      useUpsert.getField('hqAddressCountry').set(countryModel);
      useUpsert.getField('hqAddressState').set(stateModel);
      useUpsert.getField('hqAddressCity').set(cityModel);
      useUpsert.getField('hqAddressZipCode').set(vendorAddress?.zipCode);
      useUpsert.getField('addressLine1').set(response?.addressLine1);
      useUpsert.getField('addressLine2').set(response?.addressLine2);
    };

    const upsertVendorAddress = (): void => {
      UIStore.setPageLoader(true);
      const request = new VendorAddressModel({ ...useUpsert.form.values() });
      vendorManagementStore
        ?.upsertVendorAddress(request.serialize(AuthStore?.vendorProfile?.id))
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe({
          next: (response: VendorAddressModel) => {
            useUpsert.form.reset();
            setFormValues(response);
            useUpsert.resetFormValidations(response, () => {
              navigate('/vendor/addresses');
            });
          },
          error: error => {
            if (error.response.data.errors) {
              errorHandler(error.response.data.errors, request.id.toString());
              return;
            }
            useUpsert.showAlert(error.message, request.id.toString());
          },
        });
    };

    const onValueChange = (value: IOptionValue, fieldKey: string): void => {
      useUpsert.getField(fieldKey).set(value);
      switch (fieldKey) {
        case 'hqAddressCountry':
          vendorManagementStore.cities = [];
          vendorManagementStore.states = [];
          useUpsert.getField('hqAddressState').clear();
          useUpsert.getField('hqAddressCity').clear();
          filterStateByCountry(value);
          setCountryList(vendorManagementStore.countries);
          loadCities('');
          break;
        case 'hqAddressState':
          vendorManagementStore.cities = [];
          useUpsert.getField('hqAddressCity').clear();
          break;
        case 'hqAddressCity':
          vendorManagementStore.cities = [];
          break;
        default:
          break;
      }
      gridState.hasError = Utilities.hasInvalidRowData(gridState.gridApi);
    };

    const onSearch = (searchValue: string, fieldKey: string): void => {
      switch (fieldKey) {
        case 'vendor':
          vendorManagementStore.searchVendor(searchValue);
          break;
        case 'hqAddressCity':
          loadCities(searchValue);
          break;
        default:
          break;
      }
      return;
    };

    const filterStateByCountry = (value?: any) => {
      const filter = value
        ? JSON.stringify([
          {
            propertyName: 'Country.CountryId',
            propertyValue: value.id,
          },
        ])
        : '';

      const request: IAPIGridRequest = {
        filterCollection: filter,
      };
      vendorManagementStore.getVmsStates(request, undefined).subscribe(response => {
        setStatesList(response.results);
      });
    };

    const loadCities = (searchValue: string): void => {
      const countryId: number = useUpsert.getField('hqAddressCountry').value?.id;
      if (!countryId || !searchValue) {
        vendorManagementStore.cities = [];
        return;
      }
      const stateId: number = useUpsert.getField('hqAddressState').value?.id;
      const filters = stateId
        ? Utilities.getFilter('State.StateId', stateId)
        : Utilities.getFilter('Country.CountryId', countryId);

      const searchCityFilter = searchValue
        ? [
          {
            propertyName: 'CommonName',
            propertyValue: searchValue,
          },
          {
            propertyName: 'OfficialName',
            operator: 'or',
            propertyValue: searchValue,
          },
        ]
        : [];

      const filterCollection = [ filters ];
      const request: IAPIGridRequest = {
        filterCollection: JSON.stringify(filterCollection),
        searchCollection: JSON.stringify(searchCityFilter),
      };
      vendorManagementStore.getVmsCities(request).subscribe();
    };
    const getConfirmation = (): void => {
      if (formRef.changed) {
        ModalStore.open(
          <ConfirmDialog
            title="Confirm Changes"
            message={'Canceling will lost your changes. Are you sure you want to cancel?'}
            yesButton="Confirm"
            onNoClick={() => ModalStore.close()}
            onYesClick={() => {
              useUpsert.form.reset();
              loadInitialData();
              ModalStore.close();
            }}
          />
        );
      }
    };

    const isCountrySelected = (): boolean => {
      const { value } = useUpsert.getField('hqAddressCountry');
      return Boolean((value as CountryModel)?.id);
    };

    const groupInputControls = (): IGroupInputControls[] => {
      return [
        {
          inputControls: [
            {
              fieldKey: 'id',
              type: EDITOR_TYPES.TEXT_FIELD,
              isHidden: true,
            },
            {
              fieldKey: 'addressType',
              type: EDITOR_TYPES.DROPDOWN,
              options: settingsStore.vendorAddressType,
            },
            {
              fieldKey: 'addressLine1',
              type: EDITOR_TYPES.TEXT_FIELD,
              isHalfFlex: true,
            },
            {
              fieldKey: 'addressLine2',
              type: EDITOR_TYPES.TEXT_FIELD,
              isFullFlex: true,
            },
            {
              fieldKey: 'hqAddressCountry',
              type: EDITOR_TYPES.DROPDOWN,
              options: countryList,
              searchEntityType: SEARCH_ENTITY_TYPE.COUNTRY,
            },
            {
              fieldKey: 'hqAddressState',
              type: EDITOR_TYPES.DROPDOWN,
              options: statesList,
              isDisabled: !isCountrySelected(),
              searchEntityType: SEARCH_ENTITY_TYPE.STATE,
              getOptionLabel: state => (state as StateModel)?.label,
            },
            {
              fieldKey: 'hqAddressCity',
              type: EDITOR_TYPES.DROPDOWN,
              options: vendorManagementStore.cities,
              isDisabled: !isCountrySelected(),
            },
            {
              fieldKey: 'hqAddressZipCode',
              type: EDITOR_TYPES.TEXT_FIELD,
            },
          ],
        },
      ];
    };

    const dialogHeader = (): ReactNode => {
      return params.id ? 'Edit Address' : 'Add New Address';
    };

    const headerActions = (): ReactNode => {
      return (
        <>
          <Typography variant="h5">{dialogHeader()}</Typography>
          <Box sx={{ display: 'flex' }}>
            <div className={`${classes.defaultButton}`}>
              <Button color='primary' variant="outlined" 
                onClick={() => navigate(-1)} size='large'>
              Cancel
              </Button>
            </div>
            <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => upsertVendorAddress()}
                size="large"
                disabled={!formRef.changed || !formRef.isValid || formRef.hasError}
              >
                Save
              </Button>
            </div>
          </Box>
        </>
      );
    };

    const onFocus = (fieldKey: string): void => {
      switch (fieldKey) {
        case 'vendorStatus':
          settingsStore
            .getSettings(
              SETTING_ID.SETTINGS_VENDOR_STATUS
            )
            .subscribe();
          break;
        case 'hqAddressState':
          const { value } = useUpsert.getField('hqAddressCountry');
          filterStateByCountry(value);
          break;
        case 'hqAddressCountry':
          setCountryList(vendorManagementStore.countries);
          break;
        case 'hqAddressCity':
          if(!useUpsert.getField('hqAddressCity').value){
            vendorManagementStore.cities = [];
            useUpsert.getField('hqAddressCity').clear();
          }
          break;
        default:
          break;
      }
    };

    return (
      <ConfirmNavigate  isBlocker={formRef.changed}>
        <DetailsEditorWrapper
          headerActions={headerActions()}
          isEditMode={isEditable}
          classes={{ headerActions: classes.headerActions }}
        >
          <div className={classes.editorWrapperContainer}>
            <ViewInputControls
              isEditable={true}
              groupInputControls={groupInputControls()}
              onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
              onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
              field={fieldKey => useUpsert.getField(fieldKey)}
              onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
              onFocus={fieldKey => onFocus(fieldKey)}
            />
          </div>
        </DetailsEditorWrapper>
      </ConfirmNavigate>
    );
  }
);
export default inject('settingsStore', 'vendorLocationStore', 'vendorManagementStore')(UpsertAddress);
