import React, { FC, useEffect, useState } from 'react';
import { useUnsubscribe } from '@wings-shared/hooks';
import { CityModel, CountryModel, useBaseUpsertComponent } from '@uplink/shared';
import { IAPISearchFiltersDictionary, IClasses, UIStore } from '@wings-shared/core';
import { RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { Box, FormControlLabel, Radio, RadioGroup, Typography, withStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { SettingsStore, SlideOneAndTwoStore, VendorLocationStore } from '../../../../Stores';
import { SETTING_ID, VendorOnBoardSlideOneNTwoModel, newVendorOnboarding } from '../../../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import { AuthStore } from '@uplink-shared/security';
import { fields } from './Fields';
import { styles } from './Slide4.styles';

interface Props {
  classes?: IClasses;
  settingsStore: SettingsStore;
  slideOneAndTwoStore: SlideOneAndTwoStore;
  searchFilters: IAPISearchFiltersDictionary;
  registerSaveData: (saveData: () => void) => void;
  activeStep: number;
  setActiveStep: React.Dispatch<number>;
  vendorLocationStore: VendorLocationStore;
  onNextButtonDisable: (boolean) => void;
}

const Slide4: FC<Props> = ({
  classes,
  registerSaveData,
  settingsStore,
  searchFilters,
  slideOneAndTwoStore,
  activeStep,
  setActiveStep,
  vendorLocationStore,
  onNextButtonDisable,
}) => {
  const unsubscribe = useUnsubscribe();
  const useUpsert = useBaseUpsertComponent<VendorOnBoardSlideOneNTwoModel>({}, fields, searchFilters);
  const [ selectedAnswer, setSelectedAnswer ] = useState('');

  useEffect(() => {
    registerSaveData(saveData);
    return () => registerSaveData(null);
  }, [ slideOneAndTwoStore.activeStep ]);

  const saveData = () => {
    upsertSlideFour();
  };

  useEffect(() => {
    onNextButtonDisable(UIStore.pageLoading);
  }, []);

  useEffect(() => {
    SidebarStore.setNavLinks(newVendorOnboarding(), 'vendor', 'Vendor Locations', '/vendor/locations');
    settingsStore.getSettings( SETTING_ID.SETTINGS_OPERATON_TYPE).subscribe();
    if (AuthStore.vendorProfile?.id) {
      loadInitialData();
    }
  }, [ activeStep, slideOneAndTwoStore.activeStep ]);

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
  };

  const upsertSlideFour = () => {
    UIStore.setPageLoader(true);
    const request = new VendorOnBoardSlideOneNTwoModel({ ...useUpsert.form.values() });
    const operationTypeId = vendorLocationStore.operationTypeId;
    slideOneAndTwoStore
      .upsertSlideFouFiveSixQuestion(request.serialize(operationTypeId), slideOneAndTwoStore.activeStep + 1)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: VendorOnBoardSlideOneNTwoModel) => {
          RootDataStore.setLocation(
            RootDataStore.locationData.locationId ? true : false,
            0,
            response.locationName,
            AuthStore.vendorProfile.code,
            response.airportReference?.id || RootDataStore.locationData.airportReferenceId,
            response.locationUniqueCode
          );
          if (slideOneAndTwoStore.activeStep === 3) {
            slideOneAndTwoStore.activeStep = 4;
            setActiveStep(4);
          } else if (slideOneAndTwoStore.activeStep === 4) {
            slideOneAndTwoStore.activeStep = 5;
            setActiveStep(5);
          } else if (slideOneAndTwoStore.activeStep === 5) {
            slideOneAndTwoStore.activeStep = 5;
            setActiveStep(5);
          }
        },
        error: error => {
          if (error.response.data.errors) {
            errorHandler(error.response.data.errors, request.id.toString());
            return;
          }
          useUpsert.showAlert(error.message, request.id.toString());
        },
      });
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    slideOneAndTwoStore
      .getByVendorId(AuthStore.vendorProfile.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: VendorOnBoardSlideOneNTwoModel) => {
        if (response[0]) {
          useUpsert.setFormValues(VendorOnBoardSlideOneNTwoModel.deserialize(response[0]));
          if (response[0]?.vendorLocationCityReference) {
            setFormValues(response[0]?.vendorLocationCityReference);
          }
          RootDataStore.setLocation(
            RootDataStore.locationData.locationId ? true : false,
            0,
            response[0].locationName,
            AuthStore.vendorProfile.code,
            response[0].airportReference?.id || RootDataStore.locationData.airportReferenceId,
            response[0].locationUniqueCode
          );
          if (response[0].operationType) {
            vendorLocationStore.operationTypeId = response[0].operationType?.id;
          } else {
            vendorLocationStore.operationTypeId = 0;
          }
          if (slideOneAndTwoStore.activeStep === 3) {
            setSelectedAnswer(response[0].slide4Answer);
          } else if (slideOneAndTwoStore.activeStep === 4) {
            setSelectedAnswer(response[0].slide5Answer);
          } else if (slideOneAndTwoStore.activeStep === 5) {
            setSelectedAnswer(response[0].slide6Answer);
          }
        }
      });
  };

  const setFormValues = vendorAddress => {
    const countryModel = new CountryModel({
      id: vendorAddress?.countryReference.countryId,
      commonName: vendorAddress?.countryReference?.name,
      isO2Code: vendorAddress?.countryReference?.code,
    });
    const cityModel = new CityModel({
      id: vendorAddress?.cityReference?.cityId,
      cappsCode: vendorAddress?.cityReference?.code,
      commonName: vendorAddress?.cityReference?.name,
    });
    useUpsert.getField('hqAddressCountry').set(countryModel);
    useUpsert.getField('hqAddressCity').set(cityModel);
  };

  const handleChange = e => {
    setSelectedAnswer(e.target.value);
    if (activeStep === 3) {
      useUpsert.getField('slide4Answer').set(e.target.value);
    } else if (activeStep === 4) {
      useUpsert.getField('slide5Answer').set(e.target.value);
    } else if (activeStep === 5) {
      useUpsert.getField('slide6Answer').set(e.target.value);
    }
  };

  return (
    <>
      <div className={classes.editorWrapperContainer}>
        <Box className={classes.container}>
          <Typography gutterBottom className={classes.headerText}>
            {activeStep === 3
              ? `If an employee is dispatched to supervise/handle aircraft operations, do you charge a fee for the employee's
            travel expenses?`
              : activeStep === 4
                ? 'Can your company Provide Permit Services for any of the following?'
                : 'Can you extend credit (Direct Bill/Invoice) for Any of the following services?'}
          </Typography>
          <RadioGroup className={classes.answerText} value={selectedAnswer} onChange={handleChange}>
            <FormControlLabel
              value={activeStep === 3 ? 'Yes' : activeStep === 4 ? 'Overflight Permits' : 'Handling Fees'}
              control={<Radio className={classes.radio} />}
              label={activeStep === 3 ? 'Yes' : activeStep === 4 ? 'Overflight Permits' : 'Handling Fees'}
              name="slide4Answer"
            />
            <FormControlLabel
              value={activeStep === 3 ? 'No' : activeStep === 4 ? 'Landing Permits' : 'Airport Fees'}
              control={<Radio className={classes.radio} />}
              label={activeStep === 3 ? 'No' : activeStep === 4 ? 'Landing Permits' : 'Airport Fees'}
              name="slide4Answer"
            />
            <FormControlLabel
              value={activeStep === 3 ? 'Not Applicable' : 'None'}
              control={<Radio className={classes.radio} />}
              label={activeStep === 3 ? 'Not Applicable' : 'None'}
              name="slide4Answer"
            />
          </RadioGroup>
        </Box>
      </div>
    </>
  );
};

export default inject(
  'settingsStore',
  'slideOneAndTwoStore',
  'vendorLocationStore'
)(withStyles(styles)(observer(Slide4)));
