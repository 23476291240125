import React, { ElementType } from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import { VendorRootStore } from './Stores';
import Root from './App';
import { ErrorBoundaryDefault, ThemeContainer, Layout } from '@uplink-shared/layout';

const lifeCycles = singleSpaReact({
  React,
  ReactDOM,
  loadRootComponent: (p: any) =>
    new Promise<ElementType>((resolve, reject) =>{
      resolve(() => {
        return (
          <ThemeContainer store={VendorRootStore} appName="vendor-management">
            <Layout>
              <Root />
            </Layout>
          </ThemeContainer>
        );
      })
    }
    ),
  errorBoundaryClass: ErrorBoundaryDefault,
});

export const { bootstrap, mount, unmount } = lifeCycles;
export default lifeCycles;
