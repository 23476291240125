import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { SettingBaseModel } from './SettingBase.model';
import { ContactMasterModel } from './ContactMaster.model';
import { VendorManagmentModel } from './VendorManagment.model';
import { IAPIRequestVendorContact } from '../Interfaces/Request/API-Request-VendorContact.interface';
import { IAPIResponseVendorContact } from '../Interfaces/Response/API-Response-VendorContact';
import { AuthStore } from '@uplink-shared/security';

@modelProtection
export class VendorContactModel extends CoreModel implements ISelectOption {
  id: number = 0;
  contact: ContactMasterModel = new ContactMasterModel();
  status: SettingBaseModel = new SettingBaseModel();
  vendorId: number;
  vendor: VendorManagmentModel;
  contactUsegeType: SettingBaseModel = new SettingBaseModel();
  accessLevel: SettingBaseModel = new SettingBaseModel();

  constructor(data?: Partial<ContactMasterModel>) {
    super(data);
    Object.assign(this, data);
  }
  // required in auto complete
  public get label(): string {
    return ContactMasterModel.getDisplayContactLabel(this.contact);
  }

  public get value(): string | number {
    return this.id;
  }

  public serialize(id, contactId, statusId, contactUsegeTypeId): IAPIRequestVendorContact {
    return {
      id: id || 0,
      vendorId: AuthStore.vendorProfile?.id,
      contactId: contactId,
      contactUsegeTypeId: contactUsegeTypeId ? contactUsegeTypeId : this.contactUsegeType?.id,
      statusId: statusId != 0 ? statusId : this.status?.id || 1,
      accessLevelId: this.accessLevel?.id || 1,
      userId: this.userId,
    };
  }

  static deserialize(apiData: IAPIResponseVendorContact): VendorContactModel {
    if (!apiData) {
      return new VendorContactModel();
    }
    const data: Partial<VendorContactModel> = {
      ...apiData,
      contact: ContactMasterModel.deserialize(apiData.contact),
      vendor: VendorManagmentModel.deserialize(apiData.vendor),
      contactUsegeType: SettingBaseModel.deserialize(apiData.contactUsegeType),
      accessLevel: SettingBaseModel.deserialize(apiData.accessLevel),
      status: SettingBaseModel.deserialize(apiData.status),
    };
    return new VendorContactModel(data);
  }

  static deserializeList(apiDataList: IAPIResponseVendorContact[]): VendorContactModel[] {
    return apiDataList ? apiDataList.map(apiData => VendorContactModel.deserialize(apiData)) : [];
  }
}
