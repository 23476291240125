import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { SettingBaseModel } from './SettingBase.model';
import { ContactMasterModel } from './ContactMaster.model';
import { VendorLocationModel } from './VendorLocation.model';
import { IAPIRequestVendorLocationContact } from '../Interfaces/Request/API-Request-VendorLocationContact.interface';
import { IAPIResponseVendorLocationContact } from '../Interfaces/Response/API-Response-VendorLocationContact';
import { AuthStore } from '@uplink-shared/security';

@modelProtection
export class VendorLocationContactModel extends CoreModel implements ISelectOption {
  id: number =0;
  contact: ContactMasterModel = new ContactMasterModel();
  status: SettingBaseModel = new SettingBaseModel();
  vendorLocation: VendorLocationModel;
  vendorId: number = 0;
  contactUsegeType: SettingBaseModel = new SettingBaseModel();
  accessLevel: SettingBaseModel = new SettingBaseModel();

  constructor(data?: Partial<ContactMasterModel>) {
    super(data);
    Object.assign(this, data);
  }
  // required in auto complete
  public get label(): string {
    return ContactMasterModel.getDisplayContactLabel(this.contact);
  }

  public get value(): string | number {
    return this.id;
  }

  public serialize(
    id: number,
    vendorLocationIds: number[],
    contactUsegeTypeId: number,
    contactId: number,
    statusId: number,
    accessLevelId: number,
    vendorId: number
  ): IAPIRequestVendorLocationContact {
    return {
      id: id || 0,
      vendorLocationIds: vendorLocationIds,
      contactId: contactId ? contactId : this.contact.id,
      contactUsegeTypeId: contactUsegeTypeId ? contactUsegeTypeId : this.contactUsegeType.id,
      statusId: statusId ? statusId : this.status?.id,
      accessLevelId: accessLevelId ? accessLevelId : this.accessLevel?.id,
      userId: this.userId,
      vendorId: vendorId? vendorId : AuthStore.vendorProfile?.id,
    };
  }

  static deserialize(apiData: IAPIResponseVendorLocationContact): VendorLocationContactModel {
    if (!apiData) {
      return new VendorLocationContactModel();
    }
    const data: Partial<VendorLocationContactModel> = {
      ...apiData,
      id: apiData.id,
      contact: ContactMasterModel.deserialize(apiData.contact),
      vendorLocation: VendorLocationModel.deserialize({
        ...apiData.vendorLocation,
        id: apiData.vendorLocation?.vendorLocationId,
      }),
      contactUsegeType: SettingBaseModel.deserialize(apiData.contactUsegeType),
      accessLevel: SettingBaseModel.deserialize(apiData.accessLevel),
      status: SettingBaseModel.deserialize(apiData.status),
    };
    return new VendorLocationContactModel(data);
  }

  static deserializeList(apiDataList: IAPIResponseVendorLocationContact[]): VendorLocationContactModel[] {
    return apiDataList ? apiDataList.map(apiData => VendorLocationContactModel.deserialize(apiData)) : [];
  }
}
