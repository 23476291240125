import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { SidebarStore } from '@uplink-shared/layout';
import VendorGeneralInformation from '../VendorGeneralInformation/VendorGeneralInformation';
import { SidebarMenus } from '../Shared';

const useStyles = makeStyles(theme => ({
  vmsVendorsContainer: {
    backgroundColor: '',
  },
}));

const CoreModule = () => {
  const classes = useStyles();

  useEffect(() => {
    SidebarStore.setNavLinks(SidebarMenus(), 'vendor');
  }, []);

  return (
    <div className={classes.vmsVendorsContainer}>
      <VendorGeneralInformation />
    </div>
  );
};

export default CoreModule;
