import { Theme, createStyles, lighten } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    muiDialog:{
      '& .MuiDialog-paperWidthSm':{
        maxWidth: '1055px',
      },
      '& .MuiDialogContent-root': {
        padding: theme.spacing(3),
        '& p':{
          marginLeft: theme.spacing(1),
          fontWeight: '700',
          color: theme.palette.text.primary,
        },
        '& .MuiSvgIcon-fontSizeLarge':{
          width: '89.89px',
          height: '57.56px',
          opacity: '15%'
        },
      },
      '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
      },
      '& .MuiDialogTitle-root':{
        backgroundColor: '#004BA0',
        color: '#FFFFFF'
      }
    },
    button: {
      marginLeft:'16px',
      '& button':{
        padding:'4px 10px',
        height: '40px',
        fontSize:'14px',
        fontWeight: '400',
        width:'150px',
        textTransform:'capitalize',
        color:'#1976D2'
      }
    },
    primaryButton:{
      '& button':{
        background: '#1976D2',
        color:'#fff'
      },
      '& button:disabled':{
        background: '#afafaf',
        border:'none'
      }
    },
    dragActive: {
      backgroundColor: '#f8fafc',
    },
    formFileUpload: {
      height: '168px',
      textAlign: 'center',
      position: 'relative',
    },
    inputFileUpload: {
      display: 'none',
    },
    labelFileUpload: {
      margin: '10px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: '2px',
      borderRadius: '1rem',
      borderStyle: 'dashed',
      borderColor: `${lighten(theme.palette.divider, 0.6)}`,
      backgroundColor: theme.palette.background.paper,
      '&:hover':{
        cursor: 'pointer'
      }
    },
    innerText:{
      display: 'flex',
      color: theme.palette.text.primary,
      '& p:nth-child(1)':{
        color: theme.palette.text.primary,
      },
      '& p:nth-child(2)':{
        fontWeight: '400 !important',
        fontStyle: 'italic',
        color: theme.palette.text.primary,
      },
      '& p:nth-child(3)':{
        fontWeight: '700 !important',
        color: '#1976D2'
      }
    },
    dragFileElement: {
      position: 'absolute',
      width: '90%',
      height: '100%',
      borderRadius: '1rem',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
    fileBox:{
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px',
      backgroundColor: '#F1F1F1',
      borderRadius: '4px',
      alignItems: 'center',
      margin: theme.spacing(1),
      marginTop: theme.spacing(3),
      '& p:first-child':{
        color: '#1976D2',
      }
    }
  });
