import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { DataExpiryStore } from '../../Stores';
import { inject, observer } from 'mobx-react';
import { UIStore } from '@wings-shared/core';
import { AuthStore } from '@uplink-shared/security';
import { finalize, takeUntil } from 'rxjs/operators';
import { useUnsubscribe } from '@wings-shared/hooks';
import { SidebarStore } from '@uplink-shared/layout';
import DataExpiryStepper from './Shared/DataExpiryStepper/DataExpiryStepper';

interface Props {
  dataExpiryStore?: DataExpiryStore;
}

const DataExpirationWorkflow: React.FC<Props> = ({ dataExpiryStore }) => {
  const unsubscribe = useUnsubscribe();

  useEffect(() => {
    SidebarStore.setNavLinks([], '/vendor');
    if (AuthStore.vendorProfile?.id) {
      loadExpirationDataByVendorId();
    }
  }, [ AuthStore.vendorProfile?.id ]);

  const loadExpirationDataByVendorId = () => {
    UIStore.setPageLoader(true);
    dataExpiryStore
      ?.getDataExpirationByVendorId(AuthStore.vendorProfile.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe();
  };

  return (
    <Box>
      {dataExpiryStore?.dataExpiration && dataExpiryStore?.dataExpiration.length > 0 && (
        <DataExpiryStepper dataExpirationData={dataExpiryStore?.dataExpiration} />
      )}
    </Box>
  );
};

export default inject('dataExpiryStore')(observer(DataExpirationWorkflow));
