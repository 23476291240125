import { modelProtection } from '@wings-shared/core';


@modelProtection
export class ApprovalSettingModel  {
  id:number;
  entityName:string;
  fieldName:string;
  isApprovalRequired:boolean;
  
  constructor(data?: Partial<ApprovalSettingModel>){
    Object.assign(this,data);
  }
  
  
  static deserialize(apiData: ApprovalSettingModel): ApprovalSettingModel {
    if (!apiData) {
      return new ApprovalSettingModel();
    }
    const data: Partial<ApprovalSettingModel> = {
      ...apiData
    };
    return new ApprovalSettingModel(data);
  }
  
  static deserializeList(apiDataList: ApprovalSettingModel[]): ApprovalSettingModel[] {
    return apiDataList ? apiDataList.map((apiData: any) => ApprovalSettingModel.deserialize(apiData)) : [];
  }
}