import { createStyles, Theme } from '@material-ui/core';
import { THEMES } from '@wings-shared/core';
import { ThemeStore } from '@uplink-shared/layout';

export const styles = createStyles((theme: Theme) => ({
  editorWrapperContainer: {
    overflow: 'auto',
  },
  imageIcon: {
    '& .MuiIconButton-label':{
      height: '15px',
      width: '15px',
      cursor: 'pointer',
      color: '#1976D2',
      pointerEvents: 'all',
      marginLeft: theme.spacing(1),
    }
  },
  container: {
    borderRadius: '16px',
    padding: '50px',
    maxWidth: '417px',
    backgroundColor: ThemeStore.currentTheme === THEMES.LIGHT ? '#F1F1F1' : '',
    margin: 'auto',
    lineHeight: '38px',
  },
  headerText: {
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: '30px',
  },
  indexText: {
    fontWeight: '600',
    fontSize: '14px',
    marginRight: '10px',
  },
  questionLine: {
    display: 'flex',
    marginBottom: '20px',
  },
  questionText: {
    fontWeight: '400',
    fontSize: '14px',
  },
  resultText: {
    fontWeight: '600',
    fontSize: '12px',
    marginBottom: '10px',
  },
  answerText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '400',
  },
  operationTypeText: {
    fontWeight: '400',
    fontSize: '16px',
  },
  radio: {
    color: '#007bff',
  },
  resultLine: {
    display: 'flex',
    alignItems: 'center',
  },
}));
