import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  withStyles,
  Dialog,
  Box,
} from '@material-ui/core';
import React, { FC, useRef, useState } from 'react';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { IClasses, Loader } from '@wings-shared/core';
import { inject, observer } from 'mobx-react';
import { styles } from './UploadDocumentFile.style';
import { AlertStore } from '@uvgo-shared/alert';
import { VendorDocumentStore } from '../../../../Stores';
import { CloseIcon } from '@uvgo-shared/icons';
import CloudUpload from '@material-ui/icons/CloudUpload';

type Props = {
  classes?: IClasses;
  vendorDocumentStore: VendorDocumentStore;
  fileType: string;
  title: string;
  uploadDocumentFile: () => void;
  loader: Loader;
};

const UploadDocumentFile: FC<Props> = ({
  classes,
  vendorDocumentStore,
  fileType,
  title,
  uploadDocumentFile,
  loader,
}) => {
  const [ dragActive, setDragActive ] = useState(false);
  const formRef = useRef(null);
  const inputRef = useRef(null);

  const handleClose = () => {
    vendorDocumentStore.documentRemove = true;
    ModalStore.close();
  };

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const invalidFile = (name: string,file: File) => {
    const parts = name.split('.');
    const extension = parts[parts.length - 1];
    const allowedExtensions = [ '.doc', '.docx', '.pdf', '.jpg', '.jpeg', '.png', '.xls', '.xlsx', '.ppt', '.pptx' ];

    if (!allowedExtensions.includes(`.${extension}`)) {
      AlertStore.info('Application only supports .doc,.docx,.pdf,.jpg,.jpeg,.png,.xls,.xlsx,.ppt,.pptx types');
    }else{
      vendorDocumentStore.file = file;
    }
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    loader.setLoadingState(false);
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      invalidFile(e.dataTransfer.files[0].name,e.dataTransfer.files);
    }
  };

  const handleChange = e => {
    e.preventDefault();
    loader.setLoadingState(false);
    if (e.target.files && e.target.files[0]) {
      invalidFile(e.target.files[0].name,e.target.files);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={true}
      disableBackdropClick={true}
      fullWidth={true}
      className={classes.muiDialog}
    >
      <DialogTitle style={{ textAlign: 'center' }}>Upload Document File</DialogTitle>
      <IconButton
        onClick={handleClose}
        style={{
          position: 'absolute',
          right: 8,
          top: 10,
          color: '#FFFFFF',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography gutterBottom>Attachments (doc, docx, pdf, jpg, jpeg, png, xls, xlsx, ppt, pptx)</Typography>
        <form
          ref={formRef}
          onDragEnter={handleDrag}
          onSubmit={e => e.preventDefault()}
          className={classes.formFileUpload}
        >
          {loader.spinner}
          <input
            ref={inputRef}
            type="file"
            accept={fileType}
            className={classes.inputFileUpload}
            onChange={handleChange}
          />
          <label
            htmlFor={classes.inputFileUpload}
            className={dragActive ? `${classes.labelFileUpload} ${classes.dragActive}` : `${classes.labelFileUpload}`}
            onClick={onButtonClick}
          >
            <CloudUpload fontSize="large" />
            <div className={classes.innerText}>
              <Typography>Drag and Drop to Upload file</Typography>
              <Typography>or</Typography>
              <Typography>Add Attachment</Typography>
            </div>
          </label>
          {dragActive && (
            <div
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
              className={classes.dragFileElement}
            ></div>
          )}
        </form>
        {vendorDocumentStore.file && (
          <Box className={classes.fileBox}>
            <Typography>{vendorDocumentStore.file && `${vendorDocumentStore.file[0].name}`}</Typography>
            <IconButton
              onClick={() => {
                formRef.current.reset();
                vendorDocumentStore.documentUpdated = false;
                vendorDocumentStore.file = null;
              }}
              style={{
                color: '#1976D2',
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.button}`}>
            <Button color="primary" variant="text" onClick={() => handleClose()} size="large">
              Cancel
            </Button>
          </div>
          <div className={`${classes.primaryButton} ${classes.button}`}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => uploadDocumentFile()}
              size="large"
              disabled={!vendorDocumentStore.file}
            >
              Save
            </Button>
          </div>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default inject('vendorDocumentStore')(withStyles(styles)(observer(UploadDocumentFile)));
