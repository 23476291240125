import { HttpClient, baseApiPath } from '@uplink/shared';
import { action, observable } from 'mobx';
import { vendorManagementHeaders } from './Base.store';
import { IAPIPageResponse, Utilities } from '@wings-shared/core';
import { Observable } from 'rxjs';
import { IAPIResponseApprovalData } from '../Modules/Shared/Interfaces/Response/API-Response-ApprovalData.interface';
import { AuthStore, Logger } from '@uplink-shared/security';
import { apiUrls } from './API.url';
import { map, tap } from 'rxjs/operators';
import { ApprovalDataModel } from '../Modules/Shared/Models/ApprovalData.model';
import { AlertStore } from '@uvgo-shared/alert';
import { ENTITY_NAMES } from '../Modules/Shared/Enums/EntityNames.enum';
import { RootDataStore } from '@uplink-shared/layout';
import { FIELD_NAMES_MAPPING } from '../Modules/Shared/Enums/FieldNamesMapping.enum';

export class ApprovalDataStore {
    @observable public oldEntityData;
    @observable public disableState:{key:string,entityName:string,isDisabled}[]=[];
    @observable public approvalDataList:ApprovalDataModel[] =[];
    

    @observable 
    public getDisableState(key:string,entityName:string){
      const mapping = FIELD_NAMES_MAPPING.find(m=>m.entityName==entityName && m.key == key);
      const disabledState = this.approvalDataList.find(f=>
        f.fieldName==mapping?.apiFieldName && 
        f.entityName==entityName);
      return disabledState?true:false;
    }

    @observable 
    public getDisableStateFromStore(key:string,entityName:string):ApprovalDataModel{
      const mapping = FIELD_NAMES_MAPPING.find(m=>m.entityName==entityName && m.key == key);
      const disabledState = this.approvalDataList.find(f=>
        f.fieldName==mapping?.apiFieldName && 
        f.entityName==entityName);
      return disabledState;
    }

    
    
    @action
    public getApprovalData(entityName:string,entityId:number): Observable<ApprovalDataModel[]> {
      
      const filterCollection = JSON.stringify([
        {
          entityName:entityName,
          entityReferenceId:entityId,
          vendorId:AuthStore.vendorProfile.id,
          vendorLocationId:RootDataStore.locationData.locationId
        }
      ]);
      const params: string = Utilities.buildParamString({
        PageNumber: 1,
        PageSize: 30,
        FilterCollection:filterCollection
      });
      const http: HttpClient = new HttpClient({
        baseURL: baseApiPath.vendorManagementCoreUrl,
        headers: vendorManagementHeaders,
      });
      return http.get<IAPIPageResponse<IAPIResponseApprovalData>>(`/${apiUrls.approvalData.getAllApprovalDatas}?${params}`).pipe(
        Logger.observableCatchError,
        map(response => {
          this.approvalDataList = ApprovalDataModel.deserializeList(response.results);
          return this.approvalDataList;
        })
      );
    }

    @action
    public upsertApprovalData(payload: ApprovalDataModel[]): Observable<ApprovalDataModel[]> {
      const http = new HttpClient({ headers: vendorManagementHeaders });
      const upsertRequest: Observable<ApprovalDataModel[]> = http.post<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.approvalData.addList}`, payload)       
      return upsertRequest.pipe(
        Logger.observableCatchError,
        map(response => {
          this.approvalDataList = ApprovalDataModel.deserializeList(response);
          return this.approvalDataList;
        }),
        tap(() => AlertStore.info('Data saved successfully!'))
      );
    }
}