import { modelProtection } from '@wings-shared/core';
import { SettingBaseModel } from './SettingBase.model';
import { VendorManagmentModel } from './VendorManagment.model';
import { IAPIRequestApprovalData } from '../Interfaces/Request/API-Request-ApprovalData.interface';
import { IAPIResponseApprovalData } from '../Interfaces/Response/API-Response-ApprovalData.interface';
import { FIELD_NAMES_MAPPING } from '../Enums/FieldNamesMapping.enum';
import { ApprovalSettingModel } from './ApprovalSetting.model';
import { AuthStore } from '@uplink-shared/security';
import { RootDataStore } from '@uplink-shared/layout';
import { ENTITY_NAMES } from '../Enums/EntityNames.enum';

@modelProtection
export class ApprovalDataModel  {
    id: number
    entityName: string
    fieldName: string
    oldValue: string
    newValue: string
    comment: string
    statusId: number
    status: SettingBaseModel
    entityReferenceId: number
    parentReferenceId: number
    vendorId: number
    vendor: VendorManagmentModel
    isReferentialField: boolean
    isListField: boolean
    isNewRecord: boolean
    constructor(data?: Partial<ApprovalDataModel>){
      Object.assign(this,data);
    }
  
    static serialize(formData,oldData, fieldKey, entityName, entityReferenceId,approvalId)
    {
      const mapping = FIELD_NAMES_MAPPING.find(m=>m.key==fieldKey && m.entityName==entityName);
      let oldValue = oldData[fieldKey];
      let newValue = formData[fieldKey];
      if(mapping?.isJSONObject)
      {
        oldValue=JSON.stringify(oldData[fieldKey]);
        newValue = JSON.stringify(formData[fieldKey]);
      }
      const parentReferenceId=mapping?.isListType?entityReferenceId:0;
      if(mapping?.isSendReferenceId)
      {
        oldValue = oldData[fieldKey]?oldData[fieldKey].id:0;
        newValue = formData[fieldKey]?formData[fieldKey].id:0;
      }
      return {
        userId: '',
        id: approvalId?approvalId:0,
        entityName: mapping?.entityName,
        fieldName: mapping?.apiFieldName,
        oldValue: oldValue != null ? `${oldValue}` : null,
        newValue: newValue != null ? `${newValue}`: null,
        entityReferenceId: entityReferenceId,
        vendorId: AuthStore.vendorProfile.id,
        vendorLocationId:RootDataStore.locationData.locationId,
        comment:'add by vendor',
        parentReferenceId:parentReferenceId,

      };
    }
    
    static serializeList(entityName:string, formData, oldData, entityReferenceId,approvalDataStore )
    :ApprovalDataModel[]{
      const entityNames = FIELD_NAMES_MAPPING.filter(m=>m.entityName==entityName);
      const approvalDataModels:ApprovalDataModel[]=[];
      oldData = JSON.parse(JSON.stringify(oldData));
      entityNames.forEach((mapping: any) =>{ 
        if(JSON.stringify(formData[mapping.key]) !== JSON.stringify(oldData[mapping.key]))
        {
          const existingApprovalData = approvalDataStore.getDisableStateFromStore(mapping.key,entityName);
          approvalDataModels.push(
            ApprovalDataModel.serialize(
              formData,
              oldData,
              mapping.key,
              entityName,
              entityReferenceId,
            existingApprovalData?.id
            ))
        }
        
      });
      return approvalDataModels;
    }
  
    static deserialize(apiData: IAPIResponseApprovalData): ApprovalDataModel {
      if (!apiData) {
        return new ApprovalDataModel();
      }
      const data: Partial<ApprovalDataModel> = {
        ...apiData,
        status:SettingBaseModel.deserialize(apiData.status),
        vendor:VendorManagmentModel.deserialize(apiData.vendor)
      };
      return new ApprovalDataModel(data);
    }
  
    static deserializeList(apiDataList: IAPIResponseApprovalData[]): ApprovalDataModel[] {
      return apiDataList ? apiDataList.map((apiData: any) => ApprovalDataModel.deserialize(apiData)) : [];
    }
}