import { OktaAuthOptions } from '@okta/okta-auth-js';
import { ENVIRONMENT_VARS, EnvironmentVarsStore } from '@uplink-shared/env-store';
import { AuthStore } from '@uplink-shared/security';

class ConfigureOkta {
  static start(){
    const environmentStore = new EnvironmentVarsStore();
    const config: OktaAuthOptions = {
      issuer: `${environmentStore.getVar(ENVIRONMENT_VARS.UPLINK_OKTA_URL)}/oauth2/${environmentStore.getVar(
        ENVIRONMENT_VARS.OKTA_AUTHORIZATION_SERVER_ID
      )}`,
      clientId: environmentStore.getVar(ENVIRONMENT_VARS.OKTA_CLIENT_ID),
    };
    AuthStore.configure(config);
  }

}
export default ConfigureOkta;
