import { Theme, createStyles } from '@material-ui/core';

export const styles = createStyles((theme: Theme) => ({
  defaultButton: {
    '& button': {
      border: '1px solid #1976D2',
      padding: '4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: '400',
      minWidth: '100px',
      textTransform: 'capitalize',
      color: '#1976D2',
      marginTop: theme.spacing(6),
    },
    '& button:disabled': {
      border: 'none',
      padding: '4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: '400',
      minWidth: '100px',
      textTransform: 'capitalize',
      color: '#ffffff',
      marginTop: theme.spacing(6),
    },
  },
  noOutlineButton: {
    '& button': {
      padding: '4px 10px',
      height: '40px',
      fontSize: '14px',
      fontWeight: '400',
      minWidth: '100px',
      textTransform: 'capitalize',
      color: '#1976D2',
      marginTop: theme.spacing(6),
    },
    '& button:hover': {
      backgroundColor: '#FFFFFF',
      textDecoration: 'underline',
      color: '#63A4FF'
    },
  },
  dialog: {
    padding: '10px',
    '& .MuiTypography-body1': {
      fontSize: '18px',
    },
    '& .MuiRadio-root': {
      width: '16px',
      height: '16px',
    },
    '& .MuiRadio-root + .MuiFormControlLabel-label': {
      fontSize: '18px',
    },
  },
  pageText: {
    fontSize: '14px !important',
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '18px',
  },
  primaryButton: {
    '& button': {
      background: '#1976D2',
      color: '#fff',
    },
  },
  modalButton: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
  },
}));
