import { IAPIFilterDictionary } from '@wings-shared/core';
import { VENDOR_PRICING_COMPARISON_FILTERS } from '../../../Shared';

export const gridFilters: IAPIFilterDictionary<VENDOR_PRICING_COMPARISON_FILTERS>[] = [
  {
    columnId: 'vendor.name',
    apiPropertyName: 'Vendor.Name',
    uiFilterType: VENDOR_PRICING_COMPARISON_FILTERS.VENDOR_NAME,
  },
  {
    columnId: 'vendor.code',
    apiPropertyName: 'Vendor.Code',
    uiFilterType: VENDOR_PRICING_COMPARISON_FILTERS.VENDOR_CODE,
  },
  {
    columnId: 'vendorLocation.vendorLocationName',
    apiPropertyName: 'VendorLocation.VendorLocationName',
    uiFilterType: VENDOR_PRICING_COMPARISON_FILTERS.VENDOR_LOCATION_NAME,
  },
  {
    columnId: 'vendorLocation.AirportReference',
    apiPropertyName: 'VendorLocation.AirportReference.AirportCode',
    uiFilterType: VENDOR_PRICING_COMPARISON_FILTERS.AIRPORT_CODE,
  },
  {
    columnId: 'serviceItem.name',
    apiPropertyName: 'ServiceItem.Name',
    uiFilterType: VENDOR_PRICING_COMPARISON_FILTERS.SERVICE_NAME,
  },
  {
    columnId: 'parameter.name',
    apiPropertyName: 'Parameter.Name',
    uiFilterType: VENDOR_PRICING_COMPARISON_FILTERS.PARAMETER,
  },
  {
    columnId: 'currency.name',
    apiPropertyName: 'Currency.Name',
    uiFilterType: VENDOR_PRICING_COMPARISON_FILTERS.CURRENCY,
  },
  {
    columnId: 'uom.name',
    apiPropertyName: 'UOM.Name',
    uiFilterType: VENDOR_PRICING_COMPARISON_FILTERS.UNITS,
  },
  {
    columnId: 'status',
    apiPropertyName: 'Status.Name',
    uiFilterType: VENDOR_PRICING_COMPARISON_FILTERS.PRICING_STATUS,
  },
];
  