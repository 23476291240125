import { CoreModel,ISelectOption, modelProtection } from '@wings-shared/core'
import { IAPIVMSVendorComparison } from '../Interfaces';
import { SettingBaseModel } from './SettingBase.model';

@modelProtection
export class ContactMasterModel extends CoreModel implements ISelectOption {
  id:number =0;
  contactMethod:SettingBaseModel=new SettingBaseModel();
  contact: string='';
  contactType:SettingBaseModel = new SettingBaseModel();
  contactName: string='';
  contactUsegeType:SettingBaseModel = new SettingBaseModel();
  title?: string;
  isSMSCompatible: boolean=false;
  isSMSOpt: boolean=false;
  status:SettingBaseModel = new SettingBaseModel();
  accessLevel:SettingBaseModel = new SettingBaseModel();

  constructor(data?: Partial<ContactMasterModel>) {
    super(data);
    Object.assign(this, data);
  }
  // required in auto complete
  public get label(): string {
    return ContactMasterModel.getDisplayContactLabel(this);
  }

  public get value(): string | number {
    return this.id;
  }
  
  static getDisplayContactLabel(value:ContactMasterModel){
    const contactName= value.contactName ?  `-${value.contactName}` : ''
    return `${value.contactMethod.name}-${value?.contact}-${value?.contactType.name}${contactName}`
  }

  public serialize(contactId: number) {
    return {
      userId: '',
      id: contactId ? contactId : 0,
      contactMethodId: this.contactMethod.id,
      contact: this.contact,
      contactTypeId: this.contactType.id,
      contactName: this.contactName?.replace(/^\s*\s*$/, ''),
      contactUsegeTypeId: this.contactUsegeType.id,
      title: this.title?.replace(/^\s*\s*$/, '') || null,
      isSMSCompatible: this.isSMSCompatible || false,
      isSMSOpt: this.isSMSOpt || false,
      statusId: this.status?.id || 1,
      accessLevelId: this.accessLevel?.id || 1
    };
  }

  static deserialize(apiData: ContactMasterModel): ContactMasterModel {
    if (!apiData) {
      return new ContactMasterModel();
    }
    const data: Partial<ContactMasterModel> = {
      ...apiData,
      contactMethod: SettingBaseModel.deserialize(apiData.contactMethod),
      contactType: SettingBaseModel.deserialize(apiData.contactType),
      accessLevel: SettingBaseModel.deserialize(apiData.accessLevel),
      contactUsegeType: SettingBaseModel.deserialize(apiData?.contactUsegeType),
      status: SettingBaseModel.deserialize(apiData.status),
      isSMSCompatible: apiData.isSMSCompatible,
      isSMSOpt: apiData.isSMSOpt,
      contact: apiData.contact?.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
    };
    return new ContactMasterModel(data);
  }



  static deserializeList(apiDataList: IAPIVMSVendorComparison[]): ContactMasterModel[] {
    return apiDataList ? apiDataList.map((apiData) => ContactMasterModel.deserialize(apiData)) : [];
  }
}