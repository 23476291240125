import React from 'react';
import { Box, Paper } from '@material-ui/core';
import { useStyles } from './DataExpiryCard.styles';
import { RootDataStore } from '@uplink-shared/layout';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { UIStore } from '@wings-shared/core';
import { useUnsubscribe } from '@wings-shared/hooks';
import { finalize, takeUntil } from 'rxjs/operators';
import { VendorLocationModel } from '../../../../Shared/Models';
import { DataExpiryStore, VendorLocationStore } from '../../../../../Stores';

interface Props {
  dataExpiryStore?: DataExpiryStore;
  vendorLocationStore: VendorLocationStore;
}

interface DataExpiryCard {
  card: number;
  title: string;
  buttonText: string;
  component: any;
  redirectUrl: string;
  sectionTypeName: string;
  vendorLocation: VendorLocationModel;
}

const DataExpiryCard: React.FC<Props> = ({ dataExpiryStore, vendorLocationStore }) => {
  const classes = useStyles();
  const unsubscribe = useUnsubscribe();
  const navigate = useNavigate();

  const sectionTypeData = {
    VendorAddress: { name: 'Addresses', url: '/vendor/addresses' },
    VendorContact: { name: 'Contacts', url: '/vendor/contact' },
    VendorDocument: { name: 'Documents', url: '/vendor/documents' },
    VendorLocationAddress: { name: 'Addresses', url: '/vendor/location/addresses' },
    VendorLocationContact: { name: 'Contacts', url: '/vendor/location/contact' },
    VendorLocationDocument: { name: 'Documents', url: '/vendor/location/documents' },
    VendorLocationServiceComm: { name: 'Service Comm', url: '/vendor/location/operational-essential' },
    ServiceItemPricing: { name: 'Pricing', url: '/vendor/location/pricing' },
  };

  const getCardData = sectionType => sectionTypeData[sectionType] || { name: '', url: '' };

  const loadVendorLocationData = parseState => {
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.getVendorLocationById(parseState.vendorLocation.vendorLocationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: VendorLocationModel) => {
        if (response) {
          RootDataStore.setLocation(
            true,
            response.id,
            response.name,
            response.code,
            response.airportReference?.id,
            '',
            response.airportDataManagement,
            response.countryDataManagement,
            response.permitDataManagement
          );
          navigate(getCardData(parseState.sectionType.name)?.url, {
            state: parseState,
          });
        }
      });
  };

  const daysLeftUntilExpiry = lastExpiryDate => {
    const currentDate = new Date();
    const expiryDate = new Date(lastExpiryDate);

    const differenceInMilliseconds = Math.abs(expiryDate - currentDate);

    const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    if (differenceInDays < 7) return 'red';
    if (differenceInDays >= 7 && differenceInDays < 15) return 'yellow';
  };

  return (
    <Box className={classes.innerBox2}>
      {dataExpiryStore?.dataExpiration &&
        [
          ...new Map(
            dataExpiryStore.dataExpiration.map(item => {
              const uniqueKey = `${getCardData(item.sectionType.name).name}-${item.vendorLocation.vendorLocationId}`;
              return [ uniqueKey, item ];
            })
          ).values(),
        ].map(item => {
          return (
            <Paper
              className={classes.paper}
              key={`${item.vendorLocation.vendorLocationId}-${item.sectionType.name}`}
              style={{
                border:
                  daysLeftUntilExpiry(item.lastExpiryDate) === 'red'
                    ? '1px solid #DB063B'
                    : daysLeftUntilExpiry(item.lastExpiryDate) === 'yellow' ? '1px solid #F2C12C' : 'none',
              }}
            >
              <Box>{`Update your ${getCardData(item.sectionType.name).name} for ${item.vendorLocation.name} - ${
                item.vendorLocation.airportReference.displayCode
              }.`}</Box>
              <Box className={classes.primaryButton}>
                <PrimaryButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const parseState = JSON.parse(JSON.stringify(item));
                    loadVendorLocationData(parseState);
                  }}
                >
                  {`Go to ${getCardData(item.sectionType.name).name} Page`}
                </PrimaryButton>
              </Box>
            </Paper>
          );
        })}
    </Box>
  );
};
export default inject('dataExpiryStore', 'vendorLocationStore')(observer(DataExpiryCard));
