import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { VendorManagmentModel } from '../VendorManagment.model';
import { StatusBaseModel } from '../StatusBase.model';
import { SettingBaseModel } from '../SettingBase.model';
import { OperationTypeDocumentList } from './OperationTypeDocumentList.model';
import { IAPIResponseVendorOnBoardSlide3Document } from 
  '../../Interfaces/Response/API-Response-VendorOnBoardSlide3';
import { IAPIRequestVendorOnBoardSlide3Document } from 
  '../../Interfaces/Request/API-Request-VendorOnBoardSlide3.interface';
import { AuthStore } from '@uplink-shared/security';

@modelProtection
export class VendorOnBoardSlideThreeDocument extends CoreModel implements ISelectOption {
  id: number = 0;
  vendor?: VendorManagmentModel = new VendorManagmentModel();
  documentUri: string = '';
  otherName: string = '';
  startDate: Date = new Date();
  endDate?: Date = null;
  tempLocationId: string = '';
  operationTypeDocument: OperationTypeDocumentList = new OperationTypeDocumentList();
  documentStatus: SettingBaseModel = new SettingBaseModel();
  lastUpdated: string = '';

  constructor(data?: Partial<VendorOnBoardSlideThreeDocument>) {
    super(data);
    Object.assign(this, data);
  }

  // required in auto complete
  public get label(): string {
    return this.operationTypeDocument.documentName;
  }

  public get value(): string | number {
    return this.id;
  }

  public serialize(
    documentId: number,
    documentUri: string,
    tempLocationId: string,
    operationTypeId: number
  ): IAPIRequestVendorOnBoardSlide3Document {
    return {
      userId: '',
      id: documentId || 0,
      vendorId: AuthStore.vendorProfile.id,
      tempLocationId: tempLocationId ? tempLocationId : this.tempLocationId,
      operationTypeDocumentId: operationTypeId,
      documentUri: documentUri ? documentUri : this.documentUri,
      startDate: this.startDate,
      endDate: this.endDate || null,
    };
  }

  static deserialize(apiData: IAPIResponseVendorOnBoardSlide3Document): VendorOnBoardSlideThreeDocument {
    if (!apiData) {
      return new VendorOnBoardSlideThreeDocument();
    }
    const data: Partial<VendorOnBoardSlideThreeDocument> = {
      ...apiData,
      vendor: VendorManagmentModel.deserialize(apiData?.vendor),
      tempLocationId: apiData.tempLocationId,
      operationTypeDocument: OperationTypeDocumentList.deserializeDocument(apiData.operationTypeDocument),
      documentUri: apiData.documentUri,
      otherName: apiData.otherName,
      startDate: apiData.startDate,
      endDate: apiData.endDate,
      documentStatus: StatusBaseModel.deserialize(apiData.documentStatus),
      lastUpdated: AuthStore.user?.name
    };
    return new VendorOnBoardSlideThreeDocument(data);
  }

  static deserializeList(apiDataList: IAPIResponseVendorOnBoardSlide3Document[]): VendorOnBoardSlideThreeDocument[] {
    return apiDataList ? apiDataList.map(apiData => VendorOnBoardSlideThreeDocument.deserialize(apiData)) : [];
  }
}
