import React, { useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { SidebarStore } from '@uplink-shared/layout';
import { SidebarMenuOperationalInfo } from '../../../Shared';
import AircraftLogistics from '../../../OperationalInformation/Handler/AircraftLogistics/AircraftLogistics';
import { VendorGeneralInfoCard } from '../../../DataExpirationWorkflow';

const useStyles = makeStyles((theme) => ({
  vmsVendorsContainer:{
    backgroundColor:''
  }
}));

const OperationalInformation = () => {
  const classes = useStyles();

  useEffect(() => {
    SidebarStore.setNavLinks(
      SidebarMenuOperationalInfo(), 'vendor', 'Operational Information', '/vendor/locations', 'panel2'
    );
  }, []);

  return (
    <Box>
      <VendorGeneralInfoCard/>
      <div className={classes.vmsVendorsContainer}>
        <AircraftLogistics />
      </div>
    </Box>
  );
};

export default OperationalInformation;
