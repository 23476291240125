import { IAPIFilterDictionary } from '@wings-shared/core';
import { VENDOR_LOCATION_DATA_FILTER } from '../Shared/Enums/VendorLocation.enum';

export const gridFilters: IAPIFilterDictionary<VENDOR_LOCATION_DATA_FILTER>[] = [
  {
    columnId: 'vendor',
    apiPropertyName: 'Vendor Name',
    uiFilterType: VENDOR_LOCATION_DATA_FILTER.VENDOR_NAME,
  },
  {
    columnId: 'name',
    apiPropertyName: 'Vendor Location',
    uiFilterType: VENDOR_LOCATION_DATA_FILTER.VENDOR_LOCATION_NAME,
  },
  {
    columnId: 'airportReference',
    apiPropertyName: 'Airport',
    uiFilterType: VENDOR_LOCATION_DATA_FILTER.VENDOR_AIRPORT,
  }
]