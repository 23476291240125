import React, { FC, useState } from 'react';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { SettingsStore, SlideOneAndTwoStore, VendorLocationStore } from '../../../../Stores';
import { inject, observer } from 'mobx-react';
import { OperationInfoSettingOptionModel } from '../../Models/OperationInfoSettingOptionModel.model';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  withStyles,
  Typography,
} from '@material-ui/core';
import { IClasses } from '@wings-shared/core';
import { styles } from './Questionaire.styles';
import { useNavigate } from 'react-router';

interface Pattern {
  str: string;
  name: string;
  id: number;
}

interface Question {
  str: string;
  identifier: string;
}

interface Props {
  useUpsert: any;
  settingsStore: SettingsStore;
  vendorLocationStore: VendorLocationStore;
  slideOneAndTwoStore: SlideOneAndTwoStore;
  classes?: IClasses;
  isNavigate?: boolean;
  setActiveStep: React.Dispatch<number>;
}
class Stack {
  items: string[];

  constructor() {
    this.items = [ 'A' ];
  }

  add(element: string) {
    this.items.push(element);
  }
  isEmpty() {
    return this.items.length === 0;
  }
  remove() {
    if (this.items.length > 0) {
      return this.items.pop();
    }
  }
  lastQuestion() {
    if (!this.isEmpty()) {
      for (let i = this.items.length - 1; i >= 0; i--) {
        if (this.items[i].match(/[A-Za-z]/)) {
          return this.items[i];
        }
      }
    }
    return null;
  }
  questionLength() {
    if (!this.isEmpty()) {
      let count = 1;
      for (let i = this.items.length - 1; i >= 0; i--) {
        if (this.items[i] === '1' || this.items[i] === '0') {
          count += 1;
        }
      }
      return count;
    }
    return 1;
  }
}

const mappedArray: Pattern[] = [
  { str: 'A+1+B+0+', name: 'Supervision Only [On Site] - No Airside Access', id: 5 },
  { str: 'A+0+C+0+', name: 'Coordination', id: 6 },
  { str: 'A+1+B+1+D+1+', name: 'Full Service Ground Handler [On Site] - Airside Access', id: 1 },
  { str: 'A+1+B+1+D+0+', name: 'Supervising Ground Handler [On Site] - Airside Access', id: 3 },
  { str: 'A+0+C+1+E+1+', name: 'Supervising Ground Handler  [Travel Required] - Airside Access', id: 2 },
  { str: 'A+0+C+1+E+0+', name: 'Supervision Only [Travel Required] - No Airside Access', id: 4 },
];

const mappedQuestions: Question[] = [
  { str: 'Do you have an office at this airport?', identifier: 'A' },
  { str: 'Can you provide Airside Services?', identifier: 'B' },
  { str: 'Can an employee be dispatched to the airport to supervise?', identifier: 'C' },
  { str: 'Do you provide underwings services?', identifier: 'D' },
  { str: 'Can you provide Airside Services?', identifier: 'E' },
];

const Questionnaire: FC<Props> = observer(
  ({ useUpsert, settingsStore, classes, vendorLocationStore, slideOneAndTwoStore, isNavigate, setActiveStep }) => {
    const [ stack, setStack ] = useState<Stack>(new Stack());
    const [ currentQuestion, setCurrentQuestion ] = useState<string | null>(stack.lastQuestion());
    const [ value, setValue ] = useState('');
    const [ open, setOpen ] = useState(true);

    const navigate = useNavigate();

    const extractQuestionsAndAnswers = (patternStr: string) => {
      const questionAnswerPairs = [];
      const parts = patternStr.split('+').filter(part => part !== '');

      for (let i = 0; i < parts.length; i += 2) {
        const questionIdentifier = parts[i];
        const answer = parts[i + 1];

        const questionObj = mappedQuestions.find(q => q.identifier === questionIdentifier);

        if (questionObj) {
          questionAnswerPairs.push({
            question: questionObj.str,
            answer: answer === '1' ? 'Yes' : 'No',
          });
        }
      }

      return questionAnswerPairs;
    };

    const askNextQuestion = (answer: string) => {
      if (currentQuestion) {
        stack.add('+');
        stack.add(answer);
        stack.add('+');
        const currentPattern = stack.items.join('');
        const mappedPattern = mappedArray.find(pattern => pattern.str.startsWith(currentPattern));
        if (mappedPattern) {
          const nextQuestion = mappedPattern.str
            .substring(currentPattern.length)
            .split('+')
            .find(part => part !== '');
          if (stack.items.join('') === mappedPattern.str) {
            vendorLocationStore.isOperationTypeFieldExist = true;
            useUpsert.getField('appliedOperationType').set(
              new OperationInfoSettingOptionModel({
                operationType: settingsStore.operationType.filter(item => item.id === mappedPattern.id)[0],
              })
            );
            useUpsert.getField('appliedOperationTypeId').set(
              new OperationInfoSettingOptionModel({
                id: useUpsert.getField('appliedOperationTypeId')?.value || 0,
                operationType: settingsStore.operationType.filter(item => item.id === mappedPattern.id)[0],
              })?.id
            );
            vendorLocationStore.operationTypeQuestions = extractQuestionsAndAnswers(mappedPattern.str);
            vendorLocationStore.operationTypeId = useUpsert.getField('appliedOperationType')?.value.operationType.id;
            vendorLocationStore.operationalEssentialDataChanged = true;
            ModalStore.close();
            return;
          }
          if (nextQuestion !== undefined) {
            stack.add(nextQuestion);
            setCurrentQuestion(nextQuestion);
          } else {
            console.log('No more questions in the pattern:', mappedPattern.str);
          }
        } else {
          console.log('No matching pattern found for:', currentPattern);
        }
      }
    };

    const handleAnswer = (answer: string) => {
      askNextQuestion(answer);
      setValue('');
    };

    const handleChange = event => {
      setValue(event.target.value);
    };

    const handlePrevious = () => {
      stack.remove();
      while (!stack.isEmpty()) {
        const removedItem = stack.remove();
        if (removedItem === '1' || removedItem === '0') {
          setValue(removedItem);
        }
        if (removedItem.match(/[A-Za-z]/)) {
          stack.add(removedItem);
          setCurrentQuestion(removedItem);
          break;
        }
      }
    };

    return (
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpen(false);
          }
        }}
        fullWidth={true}
      >
        <div className={classes.dialog}>
          <DialogTitle>
            <div className={classes.heading}>
              <Typography>{mappedQuestions.find(q => q.identifier === currentQuestion)?.str}</Typography>
              <Typography className={classes.pageText}>{`${stack.questionLength()}/4`}</Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <div>
              {currentQuestion && (
                <div>
                  <FormControl>
                    <RadioGroup aria-labelledby="question" value={value} onChange={e => handleChange(e)} row>
                      <FormControlLabel value={'0'} control={<Radio />} label="No" />
                      <FormControlLabel value={'1'} control={<Radio />} label="Yes" />
                    </RadioGroup>
                  </FormControl>
                  <Box className={classes.modalButton}>
                    <Box>
                      {stack.items.length > 2 && (
                        <div className={`${classes.defaultButton}`}>
                          <Button color="primary" variant="outlined" onClick={() => handlePrevious()} size="small">
                            Previous
                          </Button>
                        </div>
                      )}
                    </Box>
                    <Box className={classes.modalButton}>
                      <div className={`${classes.noOutlineButton}`}>
                        <Button
                          color="primary"
                          variant="text"
                          onClick={() => {
                            if (isNavigate && !vendorLocationStore.operationTypeId) {
                              setActiveStep(0);
                              slideOneAndTwoStore.activeStep = 0;
                            }
                            ModalStore.close();
                          }}
                          size="small"
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => handleAnswer(value)}
                          size="medium"
                          disabled={!value}
                        >
                          Save
                        </Button>
                      </div>
                    </Box>
                  </Box>
                </div>
              )}
            </div>
          </DialogContent>
        </div>
      </Dialog>
    );
  }
);

export default inject('vendorLocationStore', 'settingsStore', 'slideOneAndTwoStore')(withStyles(styles)(Questionnaire));
