import { Observable, Subject } from 'rxjs';
import { action } from 'mobx';
import { HttpClient } from '../Tools';
import { PROGRESS_TYPES } from '@uvgo-shared/progress';
import { AlertStore, ALERT_TYPES, IAlert } from '@uvgo-shared/alert';
import { map } from 'rxjs/operators';
import { AuditHistoryModel, IAPIAuditHistory, IAPIPageResponse, Utilities, Loader } from '@wings-shared/core';

export class BaseStore {
  // TODO: ADD PROTECTED
  readonly reset$: Subject<boolean> = new Subject();
  public loader: Loader = new Loader(false, { type: PROGRESS_TYPES.LINEAR });

  @action
  public reset(): void {
    this.reset$.next(true);
  }

  protected showAlertCritical(message: string, id: string): void {
    const alert: IAlert = {
      id,
      message,
      type: ALERT_TYPES.CRITICAL,
      hideAfter: 5000,
    };
    AlertStore.removeAlert(id);
    AlertStore.showAlert(alert);
  }

}
