import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { SettingBaseModel } from '../SettingBase.model';
import { OperationTypeDocumentList } from './OperationTypeDocumentList.model';

@modelProtection
export class VendorOnBoardSlideThree extends CoreModel implements ISelectOption {
  id: number = 0;
  userId: string = '';
  operationType: SettingBaseModel = new SettingBaseModel();
  operationTypeDocumentLists: OperationTypeDocumentList[] = [];

  constructor(data?: Partial<VendorOnBoardSlideThree>) {
    super(data);
    Object.assign(this, data);
  }

  public serialize(): VendorOnBoardSlideThree {
    return {
      id: this.id || 0,
      userId: this.userId,
      
    };
  }

  static deserialize(apiData: VendorOnBoardSlideThree): VendorOnBoardSlideThree {
    if (!apiData) {
      return new VendorOnBoardSlideThree();
    }
    const data: Partial<VendorOnBoardSlideThree> = {
      ...apiData,
      operationType: SettingBaseModel.deserialize(apiData.operationType),
      operationTypeDocumentLists: OperationTypeDocumentList.deserializeList(apiData.operationTypeDocumentLists)
    };
    return new VendorOnBoardSlideThree(data);
  }

  static deserializeList(apiDataList: VendorOnBoardSlideThree[]): VendorOnBoardSlideThree[] {
    return apiDataList ? apiDataList.map(apiData => VendorOnBoardSlideThree.deserialize(apiData)) : [];
  }

  public get label(): string {
    return this.operationType.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
