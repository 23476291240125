import { regex } from '@wings-shared/core';
import { auditFields } from '@uplink/shared';
import { VALIDATION_REGEX } from '../../Shared/Enums/Spacing.enum';

export const fields = {
  ...auditFields,
  id: {
    label: 'Document Id',
  },
  name: {
    label: 'Document Name*',
    rules: 'required',
  },
  otherName: {
    label: 'Other Name',
    rules:`between:2,256|regex:${VALIDATION_REGEX.TWO_WHITESPACE_STRING}`
  },
  status:{
    label: 'Document Status',
  },
  documentUri:{
    label: 'Document*',
    rules: 'string|required',
  },
  startDate:{
    label: 'Start Date*',
    rules: 'required'
  },
  endDate:{
    label: 'Expiration Date',
  },
  comment:{
    label: 'Comments'
  },
  lastUpdated:{
    label: 'Last Updated'
  }
}