import { CoreModel, modelProtection } from '@wings-shared/core';
import { IAPIResponseVendorAddress } from '../Interfaces/Response/API-Response-VendorAddress';
import { IAPIRequestVendorAddress } from '../Interfaces/Request/API-Request-VendorAddress.interface';
import { SettingBaseModel } from './SettingBase.model';
import { CityModel, CountryModel, StateModel } from '@uplink/shared';
import { IAPIResponseVendorUser, IAPIVMSVendorUser } from '../Interfaces/Response/API-Response-VendorUser';
import { IAPIRequestVendorUser, VendorUserLocation } from '../Interfaces/Request/API-Request-VendorUser.interface';
import { VendorManagmentModel } from './VendorManagment.model';
import { VendorUserLocationModel } from './VendorUserLocation.model';
import { UserGroupModel } from './UserGroup.model';
import { AuthStore } from '@uplink-shared/security';

@modelProtection
export class VendorUserModel extends CoreModel {
  id: number = 0;
  vendorId: number = 0;
  vendor?: VendorManagmentModel = new VendorManagmentModel();
  email: string = '';
  givenName: string = '';
  surName: string = '';
  userName: string = null;
  userRole: UserGroupModel = null;
  statusId: SettingBaseModel = new SettingBaseModel();
  vendorUserLocation: VendorUserLocation[] = [];
  oktaUserId?: string = null;
  phoneNo: string = '';

  constructor(data?: Partial<VendorUserModel>) { 
    super(data);
    Object.assign(this, data);
  }

  public serialize(
    id: number, 
    userRole:string, 
    oktaUserId:string, 
    statusId?: number,
  ):VendorUserModel {
    return {
      userId: '',
      id: id || 0,
      vendorId: AuthStore.vendorProfile.id,
      email: this.email,
      givenName: this.givenName?.replace(/^\s*\s*$/, ''),
      surName: this.surName?.replace(/^\s*\s*$/, ''),
      userName: this.userName?.replace(/^\s*\s*$/, '') || null,
      userRole: userRole,
      statusId: statusId ? statusId : this.statusId,
      vendorUserLocation: this.vendorUserLocation.map(data => ({
        userId: data.userId || '',
        id: data?.vendorUserId ? data.id : 0,
        vendorLocationId: data?.vendorUserId ? data?.vendorLocation?.id : data?.id,
      })),
      oktaUserId: oktaUserId ? oktaUserId : 'tempOktaUserId',
      phoneNo: this.phoneNo,
    };
  }

  static deserialize(apiData: IAPIResponseVendorUser): VendorUserModel {
    if (!apiData) {
      return new VendorUserModel();
    }
    const data: Partial<VendorUserModel> = {
      ...apiData,
      id: apiData.id,
      vendor: VendorManagmentModel.deserialize(apiData?.vendor),
      email: apiData.email,
      givenName: apiData.givenName,
      surName: apiData.surName,
      userName: apiData.userName,
      userRole: apiData.userRole ? UserGroupModel.deserialize({
        Id:'',
        Name:apiData.userRole
      }): null,
      // status: SettingBaseModel.deserialize(apiData.status),
      statusId: apiData.status,
      oktaUserId: apiData.oktaUserId,
      phoneNo: apiData.phoneNo,
      vendorUserLocation: VendorUserLocationModel.deserializeList(apiData.vendorUserLocation)
    };
    return new VendorUserModel(data);
  }

  static deserializeList(apiDataList: IAPIResponseVendorUser[]): VendorUserModel[] {
    return apiDataList ? apiDataList.map((apiData: any) => VendorUserModel.deserialize(apiData)) : [];
  }
}
