import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { BaseStore, DataExpiryStore } from '../../../../Stores';
import { IClasses, UIStore } from '@wings-shared/core';
import { RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { useLocation, useNavigate } from 'react-router';
import { useStyles } from './ExpiryAddress.styles';
import { HelpRounded } from '@material-ui/icons';
import { PrimaryButton } from '@uvgo-shared/buttons';
import VendorLocationAddress from '../../../VendorLocations/Components/VendorLocationAddress/VendorLocationAddress';
import { DataExpirationUptoDate } from '../../../Shared/Models/DataExpirationUptoDate.model';
import { finalize, takeUntil } from 'rxjs/operators';
import { useUnsubscribe } from '@wings-shared/hooks';

interface Props {
  classes?: IClasses;
  dataExpiryStore: DataExpiryStore;
}

const ExpiryAddress: React.FC<Props> = ({ dataExpiryStore }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const unsubscribe = useUnsubscribe();

  useEffect(() => {
    if (location.state) {
      dataExpiryStore.dataExpiryState = location.state;
    }
    SidebarStore.setNavLinks([], 'vendor', 'Addresses', '/vendor');
  }, [ location.state, dataExpiryStore ]);

  const handleConfirm = () => {
    UIStore.setPageLoader(true);
    const request = new DataExpirationUptoDate({
      vendorLocationId: RootDataStore.locationData.locationId,
      dataExpSectionTypeId: dataExpiryStore.dataExpiryState.sectionType.id,
    });
    dataExpiryStore
      ?.upsertUpToDateConfirm(request.serialize())
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: DataExpirationUptoDate) => {
          if (response.isExpiryDataUpdate) {
            BaseStore.showAlert('Data updated successfully', 0);
            navigate('/vendor');
          }
        },
        error: error => {
          BaseStore.showAlert(error.message, 0);
        },
      });
  };

  return (
    <Box>
      <Box className={classes.headerConfirmation}>
        <Box className={classes.innerHeader}>
          <HelpRounded style={{ color: '#D16124' }} />
          <Typography>
            Are your <strong>{dataExpiryStore.dataExpiryState?.sectionTypeName}</strong> for{' '}
            {dataExpiryStore.dataExpiryState?.vendorLocation.name} -{' '}
            {dataExpiryStore.dataExpiryState?.vendorLocation.airportReference?.displayCode} up to date?
          </Typography>
        </Box>
        <Box>
          <PrimaryButton variant="text" onClick={() => navigate('/vendor')}>
            Back
          </PrimaryButton>
          <PrimaryButton variant="contained" onClick={handleConfirm}>
            Confirm
          </PrimaryButton>
        </Box>
      </Box>
      <VendorLocationAddress />
    </Box>
  );
};

export default inject('dataExpiryStore')(observer(ExpiryAddress));
