import { HttpClient, baseApiPath } from '@uplink/shared';
import { Observable } from 'rxjs';
import { ApprovalSettingModel } from '../Modules/Shared/Models/ApprovalSetting.model';
import { vendorManagementHeaders } from './Base.store';
import { apiUrls } from './API.url';
import { map } from 'rxjs/operators';
import { IAPIPageResponse, tapWithAction } from '@wings-shared/core';
import { IAPIResponseApprovalStatus } 
  from '../Modules/Shared/Interfaces/Response/API-Response-ApprovalData.interface';
import { Logger } from '@uplink-shared/security';
import { IAPIResponseApprovalSettings } 
  from '../Modules/Shared/Interfaces/Response/API-Response-ApprovalSetting.interface';
import { action } from 'mobx';

export class ApprovalSettingsStore {

    

    @action
  public getApprovalSettingsByEntityName(entityName?: string): Observable<ApprovalSettingModel[]> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementCoreUrl,
      headers: vendorManagementHeaders,
    });
    return http.get<IAPIPageResponse<IAPIResponseApprovalSettings>>(`/${apiUrls.approvalSettings.getByEntityName}/${entityName}`).pipe(
      Logger.observableCatchError,
      map(response => ApprovalSettingModel.deserializeList(response))
    );
  }
}
