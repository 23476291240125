import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  headerConfirmation: {
    padding: '4px 14px 4px 10px',
    borderRadius: '4px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
  },
  cardHeader:{
    width: '100%',
  },
  innerHeader:{
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  }
}));
