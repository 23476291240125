import React from 'react';
import { 
  Box, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Typography 
} from '@material-ui/core';
import { useStyles } from './GuideLineData.style';
import tableData from './Data.json';
// const aerodromeRefCodeTableRowData = JSON.parse(tableData);

const GuideLineInformationData = () => {
  const classes = useStyles();
  return (
    <div className={classes.tableWraper}>
      <TableContainer component={Paper}>
        <Box component="section">
          <Typography variant="h6" align='left' className="heading">
            {tableData.aerodromeRefCodeTableData.tableTitle.title}
          </Typography>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {tableData.aerodromeRefCodeTableData.aerodromeRefCodeTableHeaderData.map(row => (
                  <TableCell key={row.name} align="center">{row.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.aerodromeRefCodeTableData.aerodromeRefCodeTableRowData.map(row => (
                <TableRow key={row.id}>
                  <TableCell align="center">{row.code}</TableCell>
                  <TableCell align="center">{row.wings}</TableCell>
                  <TableCell align="center">{row.wheel}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
      <TableContainer component={Paper}>
        <Box component="section" paddingTop={2}>
          <Typography variant="h6" align='left' className="heading">
            {tableData.aeroplaneDesignGroupTableData.tableTitle.title}
          </Typography>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {tableData.aeroplaneDesignGroupTableData.aeroplaneDesignGroupTableHeaderData.map(row => (
                  <TableCell key={row.name} align="center">{row.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.aeroplaneDesignGroupTableData.aeroplaneDesignGroupTableRowData.map(row => (
                <TableRow key={row.id}>
                  <TableCell align="center">{row.code}</TableCell>
                  <TableCell align="center">{row.wings}</TableCell>
                  <TableCell align="center">{row.wheel}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
      <TableContainer component={Paper}>
        <Box component="section" paddingTop={2}>
          <Typography variant="h6" align='left' className="heading">
            {tableData.aircraftExampleTableData.tableTitle.title}
          </Typography>
          <Table aria-label="customized table">
            <TableBody>
              {tableData.aircraftExampleTableData.aircraftExampleTableRowData.map(row => (
                <TableRow key={row.id}>
                  <TableCell align="left">{row.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    </div>
  );
};

export default GuideLineInformationData;
