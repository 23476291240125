import React, { ReactNode, useEffect } from 'react';
import VendorApp from './index';


const App = () => {
  
  return (
    <VendorApp basePath="vendor" />
  );
}

export default App;
