import {
  CityModel,
  CountryModel,
  HttpClient,
  IAPICity,
  IAPICountry,
  IAPIState,
  NO_SQL_COLLECTIONS,
  StateModel,
  baseApiPath,
} from '@uplink/shared';
import { observable, action } from 'mobx';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';
import { UserGroupModel, VendorManagmentModel } from '../Modules/Shared/Models';
import { Logger } from '@uplink-shared/security';
import { IAPIGridRequest, IAPIPageResponse, Utilities, tapWithAction } from '@wings-shared/core';
import { userManagementHeaders, vendorManagementHeaders } from './Base.store';
import { ENVIRONMENT_VARS, EnvironmentVarsStore } from '@wings-shared/env-store';
import { apiUrls } from './API.url';
import { IAPIResponseVendor } from '../Modules/Shared/Interfaces/Response/API-Response-Vendor';
import { VendorLocationAddressModel } from '../Modules/Shared/Models/VendorLocationAddress.model';
import { VendorAddressModel } from '../Modules/Shared/Models/VendorAddress.model';
import { IAPIResponseVendorAddress } from '../Modules/Shared/Interfaces/Response/API-Response-VendorAddress';
import { VendorUserModel } from '../Modules/Shared/Models/VendorUser.model';
import { COLLECTION_NAMES } from '../Modules/Shared/Enums/CollectionName.enum';
import { IAPIResponseVendorUser } from '../Modules/Shared/Interfaces/Response/API-Response-VendorUser';
import { IAPIUserGroupsResponse } from '../Modules/Shared/Interfaces/Response/API-user-groups-response.interface';


export class VendorUserStore {
  @observable public selectedVendor: VendorManagmentModel;
  @observable public vendorList: VendorManagmentModel[] = [];
  @observable public vendorUser: VendorUserModel[] = [];
  @observable isCellDisable: boolean=false;
  @observable public hasDataLoaded: boolean = true;
  @observable public isLocationRequired: boolean=false;
 

  @action
  public getVendorUser(
    collectionName: COLLECTION_NAMES,
    pageRequest?: IAPIGridRequest
  ): Observable<IAPIPageResponse<VendorUserModel>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: collectionName,
      ...pageRequest,
    });
    return http.get<IAPIPageResponse<IAPIVMSComparison>>(`/${apiUrls.vendorManagement}?${params}`).pipe(
      Logger.observableCatchError,
      map(response => {
        const results = this.getDeserializeList(response.results, collectionName);
        return { ...response, results: results };
      })
    );
  }

  public getDeserializeList(results: any[], collectionName?: COLLECTION_NAMES): any {
    switch (collectionName) {
      case COLLECTION_NAMES.VENDOR_USER:
        this.vendorUser = VendorUserModel.deserializeList(results);
        return this.vendorUser.filter((item) => item.status.id != 3);
    }
  }

  @action
  public getVendorUserById(id: number): Observable<IAPIResponseVendorUser> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementCoreUrl,
      headers: vendorManagementHeaders,
    });
    return http.get<IAPIPageResponse<IAPIResponseVendorUser>>(`/${apiUrls.vendorUser}/${id}`).pipe(
      Logger.observableCatchError,
      map(response => VendorUserModel.deserialize(response))
    );
  }

  public upsertVendorUser(payload: VendorUserModel): Observable<VendorUserModel> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    payload.id = payload.id === null ? 0 : payload.id;
    const isNewRequest: boolean = !(payload.id != null && payload.id !== 0);
    const upsertRequest: Observable<VendorLocationAddressModel> = isNewRequest
      ? http.post<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorUser}`, payload)
      : http.put<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorUser}/${payload.id}`, payload);
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() => 
        payload.statusId === 2 ? AlertStore.info('Vendor User data deleted successfully!')
          :
          AlertStore.info('Vendor User data saved successfully!')
      ),
      map(response => VendorUserModel.deserialize(response))
    );
  }

  public sendNewEmail(email: string,tempPassword?: string,vendorId:string): Observable<string> {
    const env = new EnvironmentVarsStore();
    const headers = {
      'Ocp-Apim-Subscription-Key': env.getVar(ENVIRONMENT_VARS.VENDOR_MANAGEMENT_SUBSCRIPTION_KEY),
      'Ocp-Apim-Trace': true,
    };
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.vendorManagementCoreUrl, headers });
    const tPassword =  btoa(JSON.stringify(tempPassword))
    const payload = {
      userId: '',
      to: email,
      tempPassword: tPassword,
      vendorId:vendorId
    };
    return http.post<IAPIResponse<string>>(apiUrls.vendorUserEmail, payload).pipe(
      Logger.observableCatchError,
      map(response => response.Data)
    );
  }

  @action
  public removVendorUser(vendorAddressId: number): Observable<string> {
    const payload = {
      userId:'string',
      vendorAddressId,
    };
    const http = new HttpClient({ headers: vendorManagementHeaders });
    return http.delete<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorAddress}`, payload).pipe(
      Logger.observableCatchError,
      map((response: any) => response),
      tap(() => AlertStore.info('Vendor Address data deleted successfully!'))
    );
  }
}
