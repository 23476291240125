import {
  IAPIPageResponse,
  IAPISearchFiltersDictionary,
  IClasses,
  IOptionValue,
  Loader,
  UIStore,
  Utilities,
} from '@wings-shared/core';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import React, { FC, ReactNode, useEffect } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Box, IconButton, Typography, withStyles } from '@material-ui/core';
import { styles } from './UpsertSlide3.styles';
import { useBaseUpsertComponent } from '@uplink/shared';
import { useUnsubscribe } from '@wings-shared/hooks';
import { fields } from './Fields';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import { finalize, takeUntil } from 'rxjs/operators';
import { DetailsEditorWrapper, SidebarStore, ConfirmNavigate } from '@uplink-shared/layout';
import Button from '@material-ui/core/Button';
import { PrimaryButton } from '@uvgo-shared/buttons';
import moment from 'moment';
import { CloseIcon, UploadIcon } from '@uvgo-shared/icons';
import { PROGRESS_TYPES } from '@uvgo-shared/progress';
import { AuthStore } from '@uplink-shared/security';
import { AlertStore } from '@uvgo-shared/alert';
import { SlideOneAndTwoStore, SlideThreeStore, VendorDocumentStore } from '../../../../../Stores';
import {
  newVendorOnboarding,
  VendorOnBoardSlideThreeDocument,
  SettingBaseModel,
  VendorOnBoardSlideOneNTwoModel,
  ViewInputControls,
  UploadNewVendorDocumentFile,
} from '../../../../Shared';
import {
  IAPIDocumentFile,
  IAPIDownloadDocumentFile,
} from '../../../../Shared/Interfaces/Request/API-Request-VendorDocument';
import DownloadIcon from '@material-ui/icons/GetApp';

interface Props {
  classes?: IClasses;
  slideThreeStore: SlideThreeStore;
  vendorDocumentStore: VendorDocumentStore;
  slideOneAndTwoStore: SlideOneAndTwoStore;
  searchFilters?: IAPISearchFiltersDictionary;
  navigate?: NavigateFunction;
}

const UpsertDocument: FC<Props> = ({
  classes,
  slideThreeStore,
  vendorDocumentStore,
  slideOneAndTwoStore,
  searchFilters,
}) => {
  const unsubscribe = useUnsubscribe();
  const useUpsert = useBaseUpsertComponent<VendorOnBoardSlideThreeDocument>({}, fields, searchFilters);
  const formRef = useUpsert.form;
  const navigate = useNavigate();
  const params = useParams();
  const progressLoader: Loader = new Loader(false, { type: PROGRESS_TYPES.CIRCLE });

  useEffect(() => {
    SidebarStore.setNavLinks(newVendorOnboarding(), 'vendor', 'Vendor Locations', '/vendor/locations');
    vendorDocumentStore.documentUpdated = false;
    vendorDocumentStore.file = null;
    vendorDocumentStore.documentRemove = false;
    if (params?.id) {
      loadInitialData();
      getDocumentById();
    }
  }, []);

  useEffect(() => {
    if (slideOneAndTwoStore.tempLocationId && params.upsert === 'edit') {
      loadUploadedDocumentData();
    }
  }, [ slideOneAndTwoStore.tempLocationId ]);

  const loadDocumentsOfOperationtype = (operationTypeId: number) => {
    UIStore.setPageLoader(true);
    slideThreeStore?.getAllDocumentsByOperationTypeId(operationTypeId).subscribe(response => {
      UIStore.setPageLoader(false);
      const operationTypeDocumentId = response.operationTypeDocumentLists.filter(item => {
        return item.documentType.id === parseInt(params?.id);
      });
      slideThreeStore.operationTypeId = operationTypeDocumentId[0].id;
    });
  };

  const loadUploadedDocumentData = () => {
    UIStore.setPageLoader(true);
    slideThreeStore
      .getUploadedDocuments(slideOneAndTwoStore.tempLocationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: IAPIPageResponse<[VendorOnBoardSlideThreeDocument]>) => {
        const results = VendorOnBoardSlideThreeDocument.deserializeList(response.results);
        const filteredResult = results.filter(
          item => item?.operationTypeDocument?.documentName?.id === parseInt(params.id)
        );
        useUpsert.setFormValues(filteredResult[0]);
        useUpsert.getField('operationTypeDocument').set(filteredResult[0]?.operationTypeDocument?.documentName);
      });
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    if (AuthStore.vendorProfile?.id) {
      slideOneAndTwoStore
        .getByVendorId(AuthStore.vendorProfile?.id)
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => {
            UIStore.setPageLoader(false);
          })
        )
        .subscribe((response: VendorOnBoardSlideOneNTwoModel) => {
          if (response[0]) {
            slideOneAndTwoStore.tempLocationId = response[0].tempLocationId;
            loadDocumentsOfOperationtype(response[0].operationType.id);
          }
        });
    }
  };

  const downloadFile = (data: VendorOnBoardSlideThreeDocument) => {
    UIStore.setPageLoader(true);
    slideThreeStore
      ?.downloadDocumentFile(AuthStore?.vendorProfile?.id, data.documentUri)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe({
        next: (response: IAPIDownloadDocumentFile) => {
          if (response.documentUri) {
            const link = document.createElement('a');
            link.href = response.documentUri;
            const fileExtension = response.documentUri
              .split('.')
              .pop()
              ?.split('?')[0]
              ?.toLowerCase();
            const fileName = `${data.operationTypeDocument.name}.${fileExtension}`;
            link.download = fileName;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            AlertStore.info('Error: No valid document URI found.');
          }
        },
        error: error => {
          AlertStore.info(`Error Downloading ${error.message}`);
        },
      });
  };

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'operationTypeDocument',
            type: EDITOR_TYPES.DROPDOWN,
            options: [],
            isDisabled: true,
            isFullFlex: true,
          },
          {
            fieldKey: 'documentUri',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'startDate',
            type: EDITOR_TYPES.DATE,
            isHalfFlex: true,
          },
          {
            fieldKey: 'endDate',
            type: EDITOR_TYPES.DATE,
            isHalfFlex: true,
          },
        ],
      },
    ];
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    useUpsert.getField(fieldKey).set(value);
    vendorDocumentStore.documentUpdated = true;
    switch (fieldKey) {
      case 'startDate':
        const startDate = moment(useUpsert.getField('startDate').value);
        const endDate = moment(useUpsert.getField('endDate').value);
        if (startDate.utc().isSameOrAfter(endDate.utc(), 'day')) {
          useUpsert.getField('endDate').set(null);
        }
        break;
      case 'endDate':
        const startDatee = moment(useUpsert.getField('startDate').value);
        const endDatee = moment(useUpsert.getField('endDate').value);
        if (endDatee.utc().isSameOrBefore(startDatee.utc(), 'day')) {
          useUpsert.showAlert('Expiration Date cannot be less than or equal to Start Date', '0');
          useUpsert.getField('endDate').set(null);
        }
        break;
      default:
        break;
    }
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      default:
        break;
    }
    return;
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      default:
        break;
    }
  };

  const dialogHeader = (): ReactNode => {
    return params.upsert === 'edit' ? 'Edit Document' : 'Vendor documents upload for verification';
  };

  const headerActions = (): ReactNode => {
    return (
      <>
        <Typography variant="h5">{dialogHeader()}</Typography>
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => navigate('/vendor/new-vendor-information')}
              size="large"
            >
              Cancel
            </Button>
          </div>
          <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => upsertDocument()}
              size="large"
              disabled={!formRef.isValid || formRef.hasError || !vendorDocumentStore.documentUpdated}
            >
              Save
            </Button>
          </div>
        </Box>
      </>
    );
  };

  const upsertDocument = (): void => {
    UIStore.setPageLoader(true);
    const request = new VendorOnBoardSlideThreeDocument({ ...useUpsert.form.values() });
    slideThreeStore
      ?.upsertDocument(
        request.serialize(
          params.upsert === 'edit' ? parseInt(params?.documentId) : 0,
          vendorDocumentStore.documentUri,
          slideOneAndTwoStore.tempLocationId,
          slideThreeStore.operationTypeId
        )
      )
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: VendorOnBoardSlideThreeDocument) => {
          vendorDocumentStore.documentUpdated = false;
          useUpsert.resetFormValidations(response, () => {
            slideOneAndTwoStore.tempLocationId = response.tempLocationId;
            navigate('/vendor/new-vendor-information');
          });
        },
        error: error => {
          if (error.response.data.errors) {
            errorHandler(error.response.data.errors, request.id.toString());
            return;
          }
          useUpsert.showAlert(error.message, request.id.toString());
        },
      });
  };

  const getDocumentById = (): void => {
    UIStore.setPageLoader(true);
    slideThreeStore
      .getDocumentById(params?.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: SettingBaseModel) => {
        if (response) {
          useUpsert.getField('operationTypeDocument').set(response);
        }
      });
  };

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
  };

  const uploadDocumentFile = (): void => {
    UIStore.setPageLoader(true);
    progressLoader.setLoadingState(true);
    vendorDocumentStore
      ?.uploadNewVendorDocument(AuthStore?.vendorProfile?.id, vendorDocumentStore.file[0])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          progressLoader.setLoadingState(false);
        })
      )
      .subscribe({
        next: (response: IAPIDocumentFile) => {
          if (response) {
            vendorDocumentStore.documentUpdated = true;
            vendorDocumentStore.documentUri = response.results;
            vendorDocumentStore.documentRemove = false;
            useUpsert.getField('documentUri').set(response.results);
            useUpsert.showAlert('Document File uploaded successfully', '0');
            ModalStore.close();
          }
        },
        error: error => {
          useUpsert.showAlert(`Records imported with errors ${error.message}`, '0');
        },
      });
  };

  const onRequestImportDocument = (): void => {
    ModalStore.open(
      <UploadNewVendorDocumentFile
        fileType=".doc, .docx, .pdf, .jpg, .jpeg, .png, .xls, .xlsx, .ppt, .pptx"
        title={useUpsert.getField('operationTypeDocument').value?.name}
        uploadDocumentFile={() => uploadDocumentFile()}
        loader={progressLoader}
        documentUri={useUpsert.getField('documentUri').value || ''}
      />
    );
  };

  return (
    <ConfirmNavigate isBlocker={formRef.changed || vendorDocumentStore.documentUpdated}>
      <DetailsEditorWrapper
        headerActions={headerActions()}
        isEditMode={true}
        classes={{ headerActions: classes.headerActions }}
      >
        <div className={classes.editorWrapperContainer}>
          <ViewInputControls
            isEditable={true}
            groupInputControls={groupInputControls()}
            onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
            onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
            field={fieldKey => useUpsert.getField(fieldKey)}
            onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
            onFocus={fieldKey => onFocus(fieldKey)}
          />
          <div className={`${classes.primaryButton} ${classes.customButton}`}>
            <PrimaryButton
              variant="contained"
              color="primary"
              className={classes.primaryButton}
              startIcon={<UploadIcon />}
              onClick={onRequestImportDocument}
            >
              Upload
            </PrimaryButton>
          </div>
          {!vendorDocumentStore.documentRemove && useUpsert.getField('documentUri')?.value && (
            <Box className={classes.fileBox}>
              <Box className={classes.fileName}>
                <DownloadIcon
                  size="medium"
                  onClick={() =>
                    useUpsert.getField('id')?.value &&
                    downloadFile(new VendorOnBoardSlideThreeDocument({ ...useUpsert.form.values() }))
                  }
                />
                <Typography>
                  {vendorDocumentStore.file
                    ? `${
                        useUpsert.getField('operationTypeDocument').value?.name
                    }.${vendorDocumentStore.file[0]?.name.split('.').pop()}`
                    : useUpsert.getField('documentUri')?.value &&
                      `${useUpsert.getField('operationTypeDocument').value?.name}.${useUpsert
                        .getField('documentUri')
                        ?.value.split('.')
                        .pop()}`}
                </Typography>
              </Box>
              <Box className={classes.fileName}>
                <Typography>
                  {vendorDocumentStore.file && `${(vendorDocumentStore.file[0].size / 1024).toFixed(2)}K`}
                </Typography>
                <IconButton
                  onClick={() => {
                    vendorDocumentStore.file = null;
                    vendorDocumentStore.documentUpdated = false;
                    vendorDocumentStore.documentRemove = true;
                  }}
                  style={{
                    color: '#1976D2',
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          )}
        </div>
      </DetailsEditorWrapper>
    </ConfirmNavigate>
  );
};

export default inject(
  'vendorDocumentStore',
  'slideThreeStore',
  'slideOneAndTwoStore'
)(withStyles(styles)(observer(UpsertDocument)));
