export enum VENDOR_LOCATION_DATA_FILTER {
    VENDOR_NAME = 'Vendor Name',
    VENDOR_LOCATION_NAME = 'Vendor Location',
    VENDOR_AIRPORT = 'Airport',
  }

export enum VENDOR_LOCATION_COMPARISON_FILTERS {
    VENDOR_NAME = 'Vendor Name',
    VENDOR_CODE = 'Vendor Code',
    LOCATION_NAME = 'Location Name',
    LOCATION_CODE = 'Location Code',
    AIRPORT_CODE = 'Airport Code',
    LOCATION_STATUS = 'Status',
  }