import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { VendorLocationModel } from './VendorLocation.model';
import { SettingBaseModel } from './SettingBase.model';
import { RootDataStore } from '@uplink-shared/layout';
import { Airports } from './Airports.model';

@modelProtection
export class HandlerBulletinsModel extends CoreModel implements ISelectOption {
  id: number = 0;
  refDataId: number = 0;
  bulletinLevel: SettingBaseModel = new SettingBaseModel();
  bulletinEntity: VendorLocationModel = new VendorLocationModel();
  bulletinType: SettingBaseModel = new SettingBaseModel();
  startDate: Date = new Date();
  endDate: Date = new Date();
  isUFN: boolean = false;
  bulletinText: string = '';
  notamNumber: string = '';
  airportReference: Airports = new Airports();
  status: SettingBaseModel = new SettingBaseModel();

  constructor(data?: Partial<HandlerBulletinsModel>) {
    super(data);
    Object.assign(this, data);
  }

  public serialize(entityValue: VendorLocationModel, statusId: number): HandlerBulletinsModel {
    return {
      userId: this.userId || '',
      id: this.id || 0,
      refDataId: this.refDataId || 0,
      bulletinLevelId: this.bulletinLevel.id,
      bulletinLevelName: this.bulletinLevel.name,
      bulletinEntityId: this.bulletinEntity?.id || entityValue.id,
      bulletinEntityCode: this.bulletinEntity?.code || entityValue.code,
      bulletinEntityName: this.bulletinEntity?.name || entityValue.name,
      bulletinTypeId: this.bulletinType?.id,
      bulletinTypeName: this.bulletinType?.name,
      startDate: this.startDate,
      endDate: !this.isUFN ? this.endDate : null,
      isUFN: this.isUFN || false,
      bulletinText: this.bulletinText,
      notamNumber: this.notamNumber,
      airportId: RootDataStore.locationData.airportReferenceId,
      statusId: statusId,
    };
  }

  static deserialize(apiData: HandlerBulletinsModel): HandlerBulletinsModel {
    if (!apiData) {
      return new HandlerBulletinsModel();
    }
    const data: Partial<HandlerBulletinsModel> = {
      ...apiData,
      id: apiData?.id || apiData.refDataId,
      bulletinLevel: SettingBaseModel.deserialize({
        id: apiData.bulletinLevelId,
        name: apiData.bulletinLevelName,
      }),
      bulletinEntity: VendorLocationModel.deserialize({
        id: apiData.bulletinEntityId,
        name: apiData.bulletinEntityName,
        code: apiData.bulletinEntityCode,
      }),
      bulletinType: SettingBaseModel.deserialize({
        id: apiData.bulletinTypeId,
        name: apiData.bulletinTypeName,
      }),
      startDate: apiData.startDate,
      endDate: apiData.endDate,
      notamNumber: apiData.notamNumber,
      isUFN: apiData.isUFN,
      bulletinText: apiData.bulletinText,
      airportReference: apiData.airportReference
        ? Airports.deserializeAirportReference(apiData.airportReference)
        : null,
      status: SettingBaseModel.deserialize(apiData?.status),
    };
    return new HandlerBulletinsModel(data);
  }

  static deserializeList(apiDataList: HandlerBulletinsModel[]): HandlerBulletinsModel[] {
    return apiDataList ? apiDataList.map(apiData => HandlerBulletinsModel.deserialize(apiData)) : [];
  }

  public get label(): string {
    return this.bulletinLevel.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
