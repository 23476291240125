import { ISubCategorySetting } from '@uplink/shared';
import {
  SETTING_CATEGORIES,
  SETTING_ID,
  VENDOR_ADDRESS_DATA_FILTER,
} from '../../../Shared';
import { SelectOption, IAPIFilterDictionary } from '@wings-shared/core';

export const addressGridFilters: IAPIFilterDictionary<VENDOR_ADDRESS_DATA_FILTER>[] = [
  {
    columnId: 'addressType',
    apiPropertyName: 'AddressType',
    uiFilterType: VENDOR_ADDRESS_DATA_FILTER.ADDRESS_TYPE,
  },
  {
    columnId: 'addressLine1',
    apiPropertyName: 'AddressLine1',
    uiFilterType: VENDOR_ADDRESS_DATA_FILTER.ADDRESS_LINE_1,
  },
  {
    columnId: 'addressLine2',
    apiPropertyName: 'AddressLine2',
    uiFilterType: VENDOR_ADDRESS_DATA_FILTER.ADDRESS_LINE_2,
  },
  {
    columnId: 'countryReference',
    apiPropertyName: 'Country',
    uiFilterType: VENDOR_ADDRESS_DATA_FILTER.COUNTRY,
  },
  {
    columnId: 'stateReference',
    apiPropertyName: 'State',
    uiFilterType: VENDOR_ADDRESS_DATA_FILTER.STATE,
  },
  {
    columnId: 'cityReference',
    apiPropertyName: 'City',
    uiFilterType: VENDOR_ADDRESS_DATA_FILTER.CITY,
  },
  {
    columnId: 'zipcode',
    apiPropertyName: 'Zipcode',
    uiFilterType: VENDOR_ADDRESS_DATA_FILTER.ZIP_CODE,
  },
];


