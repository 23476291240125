import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    customHeight: {
      height: 'calc(100vh - 250px)',
    },
    buttonContainer:{
      '& button:first-child':{
        marginRight: theme.spacing(2)
      },
    },
    root:{
      color: '#1976D2'
    },
    vendorUserConfirmationModal:{
      '& [class*="MuiDialogActions-root"]':{
        justifyContent:'center'
      },
      '& .yesButton:hover':{
        backgroundColor:'#63A4FF'
      }
    }
  });