import React from 'react';
import { INavigationLink } from '@uplink-shared/layout';
import { AdditionalInfoIcon } from '@uvgo-shared/icons';

const SliderSideBarMenu = (): INavigationLink[] => [
  {
    to: '/vendor',
    title: 'Data Reconfirmation',
    expandedMenuBlock: 'panel1',
    icon: <AdditionalInfoIcon />,
    subMenuItem: [
      {
        to: '/vendor',
        title: 'Data Reconfirmation',
      },
    ],
  },
];

export default SliderSideBarMenu;
