import React, { FC, ReactNode, useEffect } from 'react';
import { MixPanelTrackingEvents, useBaseUpsertComponent } from '@uplink/shared';
import { useUnsubscribe } from '@wings-shared/hooks';
import { useGridState } from '@wings-shared/custom-ag-grid';
import { inject, observer } from 'mobx-react';
import { fields } from './Fields';
import {
  IAPISearchFiltersDictionary,
  IClasses,
  UIStore,
  IAPIGridRequest,
  Utilities,
  IAPIPageResponse,
  regex,
} from '@wings-shared/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { useLocation, useNavigate, useParams } from 'react-router';
import { DetailsEditorWrapper, ConfirmNavigate, SidebarStore, RootDataStore } from '@uplink-shared/layout';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import { Box, Button, Typography } from '@material-ui/core';
import { ContactMasterStore, SettingsStore, VendorLocationStore } from '../../../../../Stores';
import {
  ContactMasterModel,
  SETTING_ID,
  SettingBaseModel,
  ViewInputControls,
  VendorLocationContactModel,
  SidebarMenus,
} from '../../../../Shared';
import {
  IAPIResponseVendorLocationContact 
} from '../../../../Shared/Interfaces/Response/API-Response-VendorLocationContact';
import { SettingNamesMapper } from '../../../../../Stores/SettingsMapper';
import { useStyles } from './UpsertLocationContact.styles';
import { forkJoin } from 'rxjs';
import { AnalyticsStore } from '@uplink-shared/analytics';

interface Props {
  settingsStore: SettingsStore;
  vendorLocationStore: VendorLocationStore;
  contactMasterStore: ContactMasterStore;
  params?: { id: Number };
  classes?: IClasses;
  searchFilters: IAPISearchFiltersDictionary;
}

const UpsertLocationContact: FC<Props> = observer(
  ({ settingsStore, searchFilters, vendorLocationStore, contactMasterStore }) => {
    const classes = useStyles();
    const unsubscribe = useUnsubscribe();
    const params = useParams();
    const gridState = useGridState();
    const location = useLocation();
    const isExpired = location.pathname.includes('/expiry/contacts');
    const useUpsert = useBaseUpsertComponent<VendorLocationContactModel>(params, fields, searchFilters);

    const formRef = useUpsert.form;
    const navigate = useNavigate();

    const getBasePath = (): string => {
      if (params.id) {
        return `vendor/location/contact/upsert/${params.id}/${params.contactId}`;
      }
      return 'vendor/location/contact/upsert';
    };

    useEffect(() => {
      contactMasterStore.isPhoneNumberValid = true;
      AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_LOCATION_CONTACT);
      loadInitialData();
      loadVendorLocationContactData();
      isExpired
        ? SidebarStore.setNavLinks([], getBasePath(), 'Contacts')
        : SidebarStore.setNavLinks(SidebarMenus(), getBasePath());
    }, []);

    const isEditable = useUpsert.isEditable;

    const loadInitialData = () => {
      if (params.id) {
        UIStore.setPageLoader(true);
        settingsStore.getSettings(SETTING_ID.SETTING_USAGES_TYPE).subscribe();
        settingsStore.getSettings(SETTING_ID.SETTING_CONTACT_TYPE).subscribe();
        settingsStore.getSettings(SETTING_ID.SETTING_CONTACT_METHOD).subscribe();
        forkJoin([ vendorLocationStore?.getVendorLocationContactById(params.id) ])
          .pipe(
            takeUntil(unsubscribe.destroy$),
            finalize(() => UIStore.setPageLoader(false))
          )
          .subscribe((response: [IAPIPageResponse<IAPIResponseVendorLocationContact>]) => {
            const result = VendorLocationContactModel.deserialize(response[0]);
            useUpsert.setFormValues(ContactMasterModel.deserialize(result.contact));
            useUpsert.getField('contactUsegeType').set(result.contactUsegeType);
            useUpsert.getField('id').set(result.id);
            useUpsert.getField('vendorId').set(result.vendorId);
            useUpsert.getField('vendorLocation').set(result.vendorLocation);
            if (result?.contact?.contactMethod?.id === 1) {
              const regex = /^\+?[0-9]{1,3}([-.\s]?\d{1,4}){1,5}$/;
              const phoneNumber = result?.contact?.contact;
              const isValid = regex.test(phoneNumber);
              if (isValid) {
                contactMasterStore.isPhoneNumberValid = true;
                contactMasterStore.phoneNumberValidationMessage = '';
                if (phoneNumber?.length > 20 || phoneNumber?.length < 7) {
                  contactMasterStore.phoneNumberValidationMessage =
                    'The Contact* field must be between 7 and 20 characters.';
                }
              } else {
                contactMasterStore.isPhoneNumberValid = false;
                contactMasterStore.phoneNumberValidationMessage = 'Please use "+" , "-" and digits only.';
                if (phoneNumber?.length > 20) {
                  contactMasterStore.phoneNumberValidationMessage =
                    'The Contact* field must be between 7 and 20 characters.';
                }
              }
            }
            validateContact(result?.contact?.contactMethod);
          });
      }
    };

    const loadVendorLocationContactData = () => {
      if (params.contactId) {
        UIStore.setPageLoader(true);
        contactMasterStore
          ?.getMasterContactById(params.contactId)
          .pipe(
            takeUntil(unsubscribe.destroy$),
            finalize(() => UIStore.setPageLoader(false))
          )
          .subscribe((response: IAPIPageResponse<IAPIResponseVendorLocationContact>) => {
            useUpsert.getField('isSMSCompatible').set(response.isSMSCompatible);
          });
      }
    };

    const upsertVendorLocationContact = (): void => {
      UIStore.setPageLoader(true);
      const request = new ContactMasterModel({ ...useUpsert.form.values() });
      const websiteUrl = request.contact;
      request.contact = websiteUrl;
      contactMasterStore
        ?.upsertMasterContact(request.serialize(params.contactId))
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe({
          next: (response: ContactMasterModel) => {
            UIStore.setPageLoader(true);
            const vendorLocationRequest = new VendorLocationContactModel();
            vendorLocationRequest.contact = VendorLocationContactModel.deserialize(response);
            contactMasterStore
              .upsertVendorLocationContact(
                vendorLocationRequest.serialize(
                  parseInt(params.id),
                  [ RootDataStore.locationData.locationId ],
                  request.contactUsegeType.id,
                  response.id,
                  response.status.id,
                  response.accessLevel.id,
                  useUpsert.getField('vendorId').value
                )
              )
              .pipe(
                takeUntil(unsubscribe.destroy$),
                finalize(() => UIStore.setPageLoader(false))
              )
              .subscribe({
                next: (response: VendorLocationContactModel) => {
                  useUpsert.form.reset();
                  const result = VendorLocationContactModel.deserialize(response[0]);
                  useUpsert.setFormValues(ContactMasterModel.deserialize(result.contact));
                  useUpsert.getField('contactUsegeType').set(result.contactUsegeType);
                  useUpsert.getField('id').set(result.id);
                  useUpsert.getField('vendorId').set(result.vendorId);
                  useUpsert.getField('vendorLocation').set(result.vendorLocation);
                  validateContact(result?.contact?.contactMethod);
                  useUpsert.resetFormValidations(result.contact, () => {
                    navigate(-1);
                    contactMasterStore.hasDataLoaded = true;
                  });
                },
                error: error => {
                  // useUpsert.showAlert(error.message, vendorLocationRequest.id.toString());
                  if (error.response.data.errors) {
                    errorHandler(error.response.data.errors, vendorLocationRequest.id.toString());
                    return;
                  }
                  useUpsert.showAlert(error.message, vendorLocationRequest.id.toString());
                },
              });
          },
          error: error => {
            if (error.response.data.errors) {
              errorHandler(error.response.data.errors, request.id.toString());
              return;
            }
            useUpsert.showAlert(error.message, request.id.toString());
          },
        });
    };

    const errorHandler = (errors: object, id): void => {
      Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
    };

    const validateContact = (value: SettingBaseModel) => {
      const contactFiled = useUpsert.getField('contact');
      contactFiled.$placeholder = setPlaceHolder(value);
      contactFiled.$rules = getContactRule(value);
      gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi));
    };

    const setPlaceHolder = value => {
      if (!value) {
        return '';
      }
      switch (value.id) {
        case 2:
          return 'Please enter Fax Number';
        case 1:
          return '+xx-xxx-xxxxxxxx';
        case 7:
          return 'Please enter Website URL';
        case 3:
          return 'Please enter Email';
        case 5:
        case 4:
        case 6:
          return 'Please enter Alpha Numeric';
        default:
          return '';
      }
    };

    const getContactRule = (value: SettingBaseModel): string => {
      if (!value) {
        return 'required';
      }
      switch (value.id) {
        case 2:
        case 1:
          return 'required|between:7,20|regex:/^\\+?[0-9]{1,3}([-.\\s]?\\d{1,4}){1,5}$/';
        case 7:
          return 'required|string|between:3,320|regex:/^[^\\s]+\\.[^\\s]+$/';
        case 3:
          return `required|regex:${regex.email}`;
        case 5:
        case 4:
          return `required|between:6,7|regex:${regex.alphaNumericWithoutSpaces}`;
        case 6:
          return `required|between:6,8|regex:${regex.alphaNumericWithoutSpaces}`;
        default:
          return 'required';
      }
    };

    const onValueChange = (value: SettingBaseModel, fieldKey: string): void => {
      useUpsert.getField(fieldKey).set(value);
      contactMasterStore.isPhoneNumberValid = true;
      switch (fieldKey) {
        case 'contactMethod':
          const contactFiled = useUpsert.getField('contact');
          contactFiled.value = '';
          validateContact(value);
          break;
        case 'contact':
          if (!value) {
            contactMasterStore.phoneNumberValidationMessage = 'The Contact* field is required.';
            break;
          }
          const contactMethod = useUpsert.getField('contactMethod')?.value;
          if (contactMethod?.id === 1) {
            const regex = /^\+?[0-9]{1,3}([-.\s]?\d{1,4}){1,5}$/;
            const isValid = regex.test(value);
            if (isValid) {
              contactMasterStore.isPhoneNumberValid = true;
              contactMasterStore.phoneNumberValidationMessage = '';
              if (value?.length > 20 || value?.length < 7) {
                contactMasterStore.phoneNumberValidationMessage =
                  'The Contact* field must be between 7 and 20 characters.';
              }
            } else {
              contactMasterStore.isPhoneNumberValid = false;
              contactMasterStore.phoneNumberValidationMessage = 'Please use "+" , "-" and digits only.';
              if (value?.length > 20) {
                contactMasterStore.phoneNumberValidationMessage =
                  'The Contact* field must be between 7 and 20 characters.';
              }
            }
          }
          break;
        default:
          break;
      }
      gridState.hasError = Utilities.hasInvalidRowData(gridState.gridApi);
    };

    const groupInputControls = (): IGroupInputControls[] => {
      return [
        {
          title: '',
          inputControls: [
            {
              fieldKey: 'id',
              type: EDITOR_TYPES.TEXT_FIELD,
              isHidden: true,
            },
            {
              fieldKey: 'contactName',
              type: EDITOR_TYPES.TEXT_FIELD,
            },
            {
              fieldKey: 'title',
              type: EDITOR_TYPES.TEXT_FIELD,
            },
            {
              fieldKey: 'contactUsegeType',
              type: EDITOR_TYPES.DROPDOWN,
              options: settingsStore.vendorContactUsageType,
            },
            {
              fieldKey: 'contactType',
              type: EDITOR_TYPES.DROPDOWN,
              options: settingsStore.vendorContactType,
            },
            {
              fieldKey: 'contactMethod',
              type: EDITOR_TYPES.DROPDOWN,
              options: settingsStore.vendorContactMethod,
            },
            {
              fieldKey: 'contact',
              type: EDITOR_TYPES.TEXT_FIELD,
              customErrorMessage:
                !contactMasterStore.isPhoneNumberValid && contactMasterStore.phoneNumberValidationMessage,
            },
            {
              fieldKey: 'isSMSCompatible',
              type: EDITOR_TYPES.CHECKBOX,
            },
            {
              fieldKey: 'vendorLocation',
              isHidden: true,
            },
          ],
        },
      ];
    };

    const dialogHeader = (): ReactNode => {
      return params.id ? 'Edit Contact' : 'Add New Contact';
    };

    const headerActions = (): ReactNode => {
      return (
        <>
          <Typography variant="h5">{dialogHeader()}</Typography>
          <Box sx={{ display: 'flex' }}>
            <div className={`${classes.defaultButton}`}>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  navigate(-1);
                }}
                size="large"
              >
                Cancel
              </Button>
            </div>
            <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => upsertVendorLocationContact()}
                size="large"
                disabled={!formRef.changed || !formRef.isValid || formRef.hasError}
              >
                Save
              </Button>
            </div>
          </Box>
        </>
      );
    };

    const onFocus = (fieldKey: string): void => {
      switch (fieldKey) {
        case 'contactMethod':
          settingsStore.getSettings(SETTING_ID.SETTING_CONTACT_METHOD).subscribe();
          break;
        case 'contactType':
          settingsStore.getSettings(SETTING_ID.SETTING_CONTACT_TYPE).subscribe();
          break;
        case 'contactUsegeType':
          settingsStore.getSettings(SETTING_ID.SETTING_USAGES_TYPE).subscribe();
          break;
        default:
          break;
      }
    };

    return (
      <ConfirmNavigate isBlocker={formRef.changed}>
        <DetailsEditorWrapper
          headerActions={headerActions()}
          isEditMode={isEditable}
          classes={{ headerActions: classes.headerActions }}
        >
          <div className={classes.editorWrapperContainer}>
            <ViewInputControls
              isEditable={true}
              groupInputControls={groupInputControls()}
              onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
              onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
              field={fieldKey => useUpsert.getField(fieldKey)}
              onFocus={fieldKey => onFocus(fieldKey)}
            />
          </div>
        </DetailsEditorWrapper>
      </ConfirmNavigate>
    );
  }
);
export default inject('settingsStore', 'vendorLocationStore', 'contactMasterStore')(UpsertLocationContact);
