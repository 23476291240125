import { regex } from '@wings-shared/core';
import { auditFields } from '@uplink/shared';
import { VALIDATION_REGEX } from '../../../Shared/Enums/Spacing.enum';

export const fields = {
  ...auditFields,
  id: {
    label: 'Vendor Location Id',
  },
  vendor: {
    label: 'Vendor Id*',
  },
  name: {
    label: 'Vendor Location Name*',
    rules: 'required|string|between:1,200',
  },
  code: {
    label: 'Vendor Location Code',
    rules: `string|between:2,3|regex:${regex.alphaNumericWithoutSpaces}`,
  },
  airportReference: {
    label: 'Airport*',
    rules: 'required',
  },
  vendorLocationStatus:{
    label: 'Vendor Location Status*',
  },
  locationLegalName:{
    label: 'Location Legal Name',
    rules: 'string|between:1,200',
  },
  rankAtAirport:{
    label: 'Rank at Airport*',
    rules: 'numeric|between:1,99',
  },
  locationStatusDetails:{
    label: 'Location status Details',
    rules: 'string|between:1,200',
  },
  countryDataManagement:{
    label: 'Country Data Management',
  },
  permitDataManagement:{
    label: 'Permit Data Management',
  },
  airportDataManagement:{
    label: 'Airport Data Management',
  }    
}