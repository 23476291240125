import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';

@modelProtection
export class DataExpirationUptoDate extends CoreModel implements ISelectOption {
  vendorLocationId: number = 0;
  dataExpSectionTypeId: number = 0;
  isExpiryDataUpdate: boolean = false;
  message: string = '';

  constructor(data?: Partial<DataExpirationUptoDate>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: DataExpirationUptoDate): DataExpirationUptoDate {
    if (!apiData) {
      return new DataExpirationUptoDate();
    }
    const data: Partial<DataExpirationUptoDate> = {
      ...apiData,
    };
    return new DataExpirationUptoDate(data);
  }

  static deserializeList(apiDataList: DataExpirationUptoDate[]): DataExpirationUptoDate[] {
    return apiDataList ? apiDataList.map(apiData => DataExpirationUptoDate.deserialize(apiData)) : [];
  }

  public serialize() {
    return {
      userId: this.userId || '',
      vendorLocationId: this.vendorLocationId,
      dataExpSectionTypeId: this.dataExpSectionTypeId,
    };
  }

  public get label(): string {
    return this.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
