import React, { FC, ReactNode, useEffect, useState } from 'react';
import { SettingsStore, VendorLocationStore } from '../../../../Stores';
import { useUnsubscribe } from '@wings-shared/hooks';
import { useBaseUpsertComponent } from '@uplink/shared';
import { UIStore, IAPISearchFiltersDictionary, IOptionValue, IClasses } from '@wings-shared/core';
import { forkJoin } from 'rxjs';
import {
  SETTING_ID,
  SettingBaseModel,
  ViewInputControls,
  PassengerLogisticsModel,
  SidebarMenuOperationalInfo,
} from '../../../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import { inject, observer } from 'mobx-react';
import { ConfirmNavigate, DetailsEditorWrapper, RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { MapBoxViewV1 } from '@uplink-shared/mapbox';
import LocationOn from '@material-ui/icons/LocationOn';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography,
  withStyles,
} from '@material-ui/core';
import { styles } from './PassengerLogistics.styles';
import { PassengerLogisticsStore } from '../../../../Stores/PassengerLogistics.store';
import { useNavigate } from 'react-router';
import { fields } from './Fields';
import { CloseIcon } from '@uvgo-shared/icons';

interface Props {
  classes?: IClasses;
  vendorLocationStore: VendorLocationStore;
  settingsStore: SettingsStore;
  passengerLogisticsStore: PassengerLogisticsStore;
  searchFilters: IAPISearchFiltersDictionary;
}

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '0px 24px 24px 24px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiBox-root': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 20px 0px 0px',
  },
  '& .MuiDialog-paperFullScreen': {
    width: '60% !important',
    height: '80% !important',
  },
}));

const PassengerLogistics: FC<Props> = ({
  settingsStore,
  vendorLocationStore,
  passengerLogisticsStore,
  classes,
  searchFilters,
}) => {
  const useUpsert = useBaseUpsertComponent<PassengerLogisticsModel>({}, fields, searchFilters);
  const formRef = useUpsert.form;
  const unsubscribe = useUnsubscribe();
  const navigate = useNavigate();
  const [ disabilityAccommodationsList, setDisabilityAccommodationsList ] = useState([]);
  const [ arrivalCrewPaxPassportHandling, setArrivalCrewPaxPassportHandlingList ] = useState([]);
  const [ internationalDepartureProceduresList, setInternationalDepartureProcedures ] = useState([]);
  const [ luggageHandling, setLuggageHandlingList ] = useState([]);
  const [ appliedAmenities, setAppliedAmenitiesList ] = useState([]);
  const [ yesOrNoList, setYesOrNoListList ] = useState(PassengerLogisticsModel.getYesNo());

  useEffect(() => {
    SidebarStore.setNavLinks(SidebarMenuOperationalInfo(), 'vendor', 'Operational Information', '/vendor/locations');
    passengerLogisticsStore.mapValuechanged = false;
    loadInitialData();
    setFormPlaceholders();
  }, []);

  const setFormPlaceholders = () => {
    useUpsert.getField('aircraftParkingOptionLatitudeLongitudeCityName').$placeholder = 'Choose from Map';
    useUpsert.getField('aircraftHandlingLocationLatitudeLongitudeCityName').$placeholder = 'Choose from Map';
    useUpsert.getField('arrivalMeetingPoint').$placeholder = 'e.g. FBO desk';
    useUpsert.getField('earlyCrewArrival').$placeholder = 'e.g. 30';
    useUpsert.getField('earlyPaxArrival').$placeholder = 'e.g. 30';
    useUpsert.getField('customsClearanceTiming').$placeholder = 'e.g. 30';
    useUpsert.getField('appliedAmenities').$placeholder = 'Select';
  };

  const formattedCoordinates = (latitude, longitude) => {
    return (latitude || longitude) && `${latitude !== null && latitude}, ${longitude !== null && longitude}`;
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    settingsStore.getSettings( SETTING_ID.SETTING_DISABILITY_ACCOMMODATIONS).subscribe(response => {
      setDisabilityAccommodationsList(response.results);
    });
    settingsStore.getSettings( SETTING_ID.SETTING_ARRIVAL_CREW_PAX_PASSPORT_HANDLING).subscribe(response => {
      setArrivalCrewPaxPassportHandlingList(response.results);
    });
    settingsStore.getSettings( SETTING_ID.SETTING_LUGGAGE_HANDLING).subscribe(response => {
      setLuggageHandlingList(response.results);
    });
    settingsStore.getSettings( SETTING_ID.SETTING_AMENITIES).subscribe(response => {
      setAppliedAmenitiesList(response.results);
    });
    settingsStore.getSettings( SETTING_ID.SETTING_INTERNATIONAL_DEPARTURE_PROCEDURES).subscribe(response => {
      setInternationalDepartureProcedures(response.results);
    });
    forkJoin([
      vendorLocationStore.getVendorLocationById(parseInt(RootDataStore.locationData.locationId)),
      vendorLocationStore.getCustomers(),
    ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: [VendorLocationModel, CustomersModel]) => {
        if (response[0].operationalInsight === null) {
          useUpsert.setFormValues(new PassengerLogisticsModel());
        } else {
          useUpsert.setFormValues(PassengerLogisticsModel.deserialize(response[0].operationalInsight));
          setFormValues(response[0].operationalInsight);
        }
      });
  };

  const setFormValues = response => {
    if (response.agentFeesApply === true) {
      useUpsert.getField('agentFeesApply').set(
        SettingBaseModel.deserialize({
          id: 1,
          name: 'Yes',
        })
      );
    } else if (response.agentFeesApply === false) {
      useUpsert.getField('agentFeesApply').set(
        SettingBaseModel.deserialize({
          id: 2,
          name: 'No',
        })
      );
    }
    useUpsert
      .getField('aircraftParkingOptionLatitudeLongitude')
      .set(formattedCoordinates(response.aircraftParkingOptionLatitude, response.aircraftParkingOptionLongitude));
    useUpsert
      .getField('aircraftHandlingLocationLatitudeLongitude')
      .set(formattedCoordinates(response.aircraftHandlingLocationLatitude, response.aircraftHandlingLocationLongitude));
    useUpsert
      .getField('aircraftParkingOptionLatitudeLongitudeCityName')
      .set(formattedCoordinates(response.aircraftParkingOptionLatitude, response.aircraftParkingOptionLongitude));
    useUpsert
      .getField('aircraftHandlingLocationLatitudeLongitudeCityName')
      .set(formattedCoordinates(response.aircraftHandlingLocationLatitude, response.aircraftHandlingLocationLongitude));
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    useUpsert.getField(fieldKey).set(value);
    switch (fieldKey) {
      case 'aircraftParkingOptionLatitudeLongitudeCityName':
        if (!value) {
          useUpsert.getField('aircraftParkingOptionLatitudeLongitude').set(null);
        }
        break;
      case 'aircraftHandlingLocationLatitudeLongitudeCityName':
        if (!value) {
          useUpsert.getField('aircraftHandlingLocationLatitudeLongitude').set(null);
        }
        break;
      default:
        break;
    }
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      case 'appliedDisabilityAccommodation':
        const filteredList = settingsStore.disabilityAccommodations.filter(item => {
          return item.label?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setDisabilityAccommodationsList(filteredList);
        break;
      case 'arrivalCrewPaxPassportHandling':
        const searchedList = settingsStore.arrivalCrewPaxPassportHandling.filter(item => {
          return item.label?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setArrivalCrewPaxPassportHandlingList(searchedList);
        break;
      case 'luggageHandling':
        const searchList = settingsStore.luggageHandling.filter(item => {
          return item.label?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setLuggageHandlingList(searchList);
        break;
      case 'appliedAmenities':
        const filterList = settingsStore.amenities.filter(item => {
          return item.label?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setAppliedAmenitiesList(filterList);
        break;
      case 'agentFeesApply':
        const agentFeesApplyList = PassengerLogisticsModel.getYesNo().filter(item => {
          return item.name?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setYesOrNoListList(agentFeesApplyList);
        break;
      case 'appliedInternationalDepartureProcedures':
        const appliedInternationalDepartureList = settingsStore.internationalDepartureProcedures.filter(item => {
          return item.name?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setInternationalDepartureProcedures(appliedInternationalDepartureList);
        break;
      default:
        break;
    }
    return;
  };

  const onMarkerDragEnd = (letlng: any, fieldKey, placeName: string) => {
    useUpsert.getField(fieldKey[0])?.set(`${letlng.lng}, ${letlng.lat}`);
    const name = placeName ? `${placeName}` : `${letlng.lng}, ${letlng.lat}`;
    passengerLogisticsStore.mapValuechanged = true;
    useUpsert.getField(fieldKey[1])?.set(name);
  };

  const openMapViewDialogue = (fieldKey): ReactNode => {
    // document.body.style.zoom = "150%";
    const fieldValue = useUpsert.getField(fieldKey[0])?.value;
    const fieldLabel = useUpsert.getField(fieldKey[0])?.label;
    const coordinatesArray = fieldValue && fieldValue?.split(',').map(parseFloat);
    ModalStore.open(
      <CustomDialog fullWidth fullScreen open={true} onClose={() => ModalStore.close()}>
        <Box>
          <DialogTitle>{fieldLabel}</DialogTitle>
          <IconButton onClick={() => ModalStore.close()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <MapBoxViewV1
            onMarkerDragEnd={(data, placeName) => onMarkerDragEnd(data, fieldKey, placeName)}
            value={coordinatesArray ? coordinatesArray : [ 28.853220553990308, 53.12500655651093 ]}
          />
        </DialogContent>
      </CustomDialog>
    );
  };

  const openMapIcon = (fieldKey): ReactNode => {
    return <LocationOn onClick={() => openMapViewDialogue(fieldKey)} />;
  };

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: 'Operational Insights:',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'aircraftParkingOptionLatitudeLongitudeCityName',
            type: EDITOR_TYPES.TEXT_FIELD,
            endAdormentValue: openMapIcon([
              'aircraftParkingOptionLatitudeLongitude',
              'aircraftParkingOptionLatitudeLongitudeCityName',
            ]),
            isReadOnly: true,
          },
          {
            fieldKey: 'aircraftHandlingLocationLatitudeLongitudeCityName',
            type: EDITOR_TYPES.TEXT_FIELD,
            endAdormentValue: openMapIcon([
              'aircraftHandlingLocationLatitudeLongitude',
              'aircraftHandlingLocationLatitudeLongitudeCityName',
            ]),
            isReadOnly: true,
          },
          {
            fieldKey: 'aircraftParkingOptionLatitudeLongitude',
            type: EDITOR_TYPES.TEXT_FIELD,
            endAdormentValue: openMapIcon([ 'aircraftParkingOptionLatitudeLongitude' ]),
            isReadOnly: true,
            isHidden: true,
          },
          {
            fieldKey: 'aircraftHandlingLocationLatitudeLongitude',
            type: EDITOR_TYPES.TEXT_FIELD,
            endAdormentValue: openMapIcon([ 'aircraftHandlingLocationLatitudeLongitude' ]),
            isReadOnly: true,
            isHidden: true,
          },
          {
            fieldKey: 'agentFeesApply',
            type: EDITOR_TYPES.DROPDOWN,
            options: yesOrNoList,
          },
          {
            fieldKey: 'appliedInternationalDepartureProcedures',
            type: EDITOR_TYPES.AUTO_COMPLETE,
            options: passengerLogisticsStore.getOperationalInsightsSettingOptions<SettingBaseModel>(
              internationalDepartureProceduresList,
              'internationalDepartureProcedures'
            ),
            isFullFlex: true,
            multiple: true,
            disableCloseOnSelect: true,
          },
          {
            fieldKey: 'appliedDisabilityAccommodation',
            type: EDITOR_TYPES.AUTO_COMPLETE,
            options: passengerLogisticsStore.getOperationalInsightsSettingOptions<SettingBaseModel>(
              disabilityAccommodationsList,
              'disabilityAccommodations'
            ),
            isHalfFlex: true,
            multiple: true,
            disableCloseOnSelect: true,
          },
          {
            fieldKey: 'arrivalCrewPaxPassportHandling',
            type: EDITOR_TYPES.DROPDOWN,
            options: arrivalCrewPaxPassportHandling,
            isHalfFlex: true,
          },
          {
            fieldKey: 'luggageHandling',
            type: EDITOR_TYPES.DROPDOWN,
            options: luggageHandling,
            isFullFlex: true,
          },
          {
            fieldKey: 'arrivalMeetingPoint',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'earlyCrewArrival',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'earlyPaxArrival',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'customsClearanceTiming',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'appliedAmenities',
            type: EDITOR_TYPES.AUTO_COMPLETE,
            options: passengerLogisticsStore.getOperationalInsightsSettingOptions<SettingBaseModel>(
              appliedAmenities,
              'amenities'
            ),
            isHalfFlex: true,
            multiple: true,
            disableCloseOnSelect: true,
          },
        ],
      },
    ];
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      case '':
        break;
      default:
        break;
    }
  };

  const getFieldValue = (fieldKey, index) => {
    const fieldValue = useUpsert.getField(fieldKey)?.value;
    if (fieldValue) {
      const coordinatesArray = fieldValue && fieldValue?.split(',').map(parseFloat);
      return coordinatesArray[index] || fieldValue;
    }
    return null;
  };

  const upsertVendorLocationOperationalInsights = (): void => {
    const request = new PassengerLogisticsModel({ ...useUpsert.form.values() });
    request.aircraftParkingOptionLatitude = getFieldValue('aircraftParkingOptionLatitudeLongitude', 0);
    request.aircraftParkingOptionLongitude = getFieldValue('aircraftParkingOptionLatitudeLongitude', 1);
    request.aircraftHandlingLocationLatitude = getFieldValue('aircraftHandlingLocationLatitudeLongitude', 0);
    request.aircraftHandlingLocationLongitude = getFieldValue('aircraftHandlingLocationLatitudeLongitude', 1);

    UIStore.setPageLoader(true);
    passengerLogisticsStore
      ?.upsertVendorLocationOperationalInsights(
        request.serialize(parseInt(RootDataStore.locationData.locationId)),
        false
      )
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: PassengerLogisticsModel) => {
          useUpsert.form.reset();
          useUpsert.setFormValues(PassengerLogisticsModel.deserialize(response));
          setFormValues(response);
          passengerLogisticsStore.mapValuechanged = false;
        },
        error: error => {
          if (error?.message) {
            useUpsert.showAlert(error?.message, request.id.toString());
            return;
          }
          errorHandler(error.response?.data?.errors, request.id.toString());
        },
      });
  };

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
  };

  const headerActions = (): ReactNode => {
    return (
      <>
        <Typography variant="h5">Available Handler Facilities at Airport</Typography>
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => navigate('/vendor/locations')}
              disabled={!passengerLogisticsStore.mapValuechanged && !formRef.changed}
              size="large"
            >
              Cancel
            </Button>
          </div>
          <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={
                !formRef.isValid || formRef.hasError || (!passengerLogisticsStore.mapValuechanged && !formRef.changed)
              }
              onClick={() => upsertVendorLocationOperationalInsights()}
            >
              Save
            </Button>
          </div>
        </Box>
      </>
    );
  };

  return (
    <>
      <ConfirmNavigate isBlocker={passengerLogisticsStore.mapValuechanged || formRef.changed}>
        <DetailsEditorWrapper
          headerActions={headerActions()}
          isEditMode={true}
          classes={{ headerActions: classes.headerActions }}
        >
          <div className={classes.editorWrapperContainer}>
            <ViewInputControls
              isEditable={true}
              groupInputControls={groupInputControls()}
              onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
              onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
              field={fieldKey => useUpsert.getField(fieldKey)}
              onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
              onFocus={fieldKey => onFocus(fieldKey)}
            />
          </div>
        </DetailsEditorWrapper>
      </ConfirmNavigate>
    </>
  );
};

export default inject(
  'settingsStore',
  'vendorLocationStore',
  'passengerLogisticsStore'
)(withStyles(styles)(observer(PassengerLogistics)));
