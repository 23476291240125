import React, { FC, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { observer, inject } from 'mobx-react';
import { useStyles } from './CustomInputDialog.styles';
import { ServiceItemPricingStore } from '../../../../Stores';

type Props = {
  title: string;
  yesButton: string;
  noButton: string;
  onNoClick: () => void;
  onYesClick: () => void;
  serviceItemPricingStore: ServiceItemPricingStore;
};

const CustomInputDialog: FC<Props> = ({
  serviceItemPricingStore,
  title,
  yesButton,
  noButton,
  onNoClick,
  onYesClick,
}) => {
  const classes = useStyles();

  const [ open, setOpen ] = useState(true);
  const [ hasError, setHasError ] = useState(false);
  const [ isValueChange, setIsValueChange ] = useState(false);

  const handleClose = () => {
    onYesClick();
    setOpen(false);
    setIsValueChange(false);
  };

  const handleChange = e => {
    setIsValueChange(true);
    const newValue = e.target.value;
    if (newValue.length > 300) {
      setHasError(true);
    } else {
      setHasError(false);
      serviceItemPricingStore.commentValue = newValue;
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'lg'} disableBackdropClick>
      <div className={classes.dialog}>
        <div className={classes.header}>
          <DialogTitle>{title}</DialogTitle>
          <Button variant="text" onClick={onNoClick}>
            Skip
          </Button>
        </div>
        <DialogContent>
          <TextField
            inputRef={input => input && input.focus()}
            className={classes.inputText}
            onFocus={e => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
            type="text"
            fullWidth
            variant="standard"
            value={serviceItemPricingStore.commentValue}
            InputProps={{ disableUnderline: true }}
            placeholder="Enter text"
            onChange={handleChange}
            error={hasError}
            multiline
            helperText={hasError ? 'The Formula/Comments field must be between 0 and 300 characters.' : ''}
            rows={6}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onNoClick} className={classes.cancelButton} variant="outlined">
            {noButton}
          </Button>
          <Button
            onClick={handleClose}
            autoFocus
            variant="contained"
            className={classes.yesButton}
            disabled={hasError || !isValueChange}
          >
            {yesButton}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default inject('serviceItemPricingStore')(observer(CustomInputDialog));
