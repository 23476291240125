import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  withStyles,
  Dialog,
  Box,
} from '@material-ui/core';
import React, { FC, useRef, useState } from 'react';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { IClasses, Loader } from '@wings-shared/core';
import { inject, observer } from 'mobx-react';
import { AlertStore } from '@uvgo-shared/alert';
import { VendorDocumentStore } from '../../../../Stores';
import { CloseIcon, UploadIcon } from '@uvgo-shared/icons';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { styles } from './UploadNewVendorDocumentFile.styles';
import { PrimaryButton } from '@uvgo-shared/buttons';

type Props = {
  classes?: IClasses;
  vendorDocumentStore: VendorDocumentStore;
  fileType: string;
  title: string;
  uploadDocumentFile: () => void;
  loader: Loader;
  documentUri?: string
};

const UploadNewVendorDocumentFile: FC<Props> = ({
  classes,
  vendorDocumentStore,
  fileType,
  title,
  uploadDocumentFile,
  loader,
  documentUri
}) => {
  const [ dragActive, setDragActive ] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClose = () => {
    if (!vendorDocumentStore.file && !documentUri) {
      vendorDocumentStore.documentRemove = true;
    }
    ModalStore.close();
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const invalidFile = (name: string, file: File) => {
    const parts = name.split('.');
    const extension = parts[parts.length - 1];
    const allowedExtensions = [ '.doc', '.docx', '.pdf', '.jpg', '.jpeg', '.png', '.xls', '.xlsx', '.ppt', '.pptx' ];

    if (!allowedExtensions.includes(`.${extension}`)) {
      AlertStore.info('Application only supports .doc,.docx,.pdf,.jpg,.jpeg,.png,.xls,.xlsx,.ppt,.pptx types');
    } else {
      vendorDocumentStore.file = file;
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    loader.setLoadingState(false);
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      invalidFile(e.dataTransfer.files[0].name, e.dataTransfer.files);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    loader.setLoadingState(false);
    if (e.target.files && e.target.files[0]) {
      invalidFile(e.target.files[0].name, e.target.files);
    }
  };

  const onButtonClick = () => {
    inputRef?.current?.click();
  };

  const clearFile = (e) => {
    e.stopPropagation();
    vendorDocumentStore.documentUpdated = false;
    vendorDocumentStore.file = null;
  };

  return (
    <Dialog
      onClose={handleClose}
      open={true}
      disableBackdropClick={true}
      fullWidth={true}
      className={classes.muiDialog}
    >
      <Box className={classes.header}>
        <DialogTitle>{`Add ${title} Form`}</DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography gutterBottom>Attachments (doc, docx, pdf, jpg, jpeg, png, xls, xlsx, ppt, pptx)</Typography>
        <input
          ref={inputRef}
          type="file"
          accept={fileType}
          className={classes.inputFileUpload}
          onChange={handleChange}
        />
        <div
          onDragEnter={e => {
            if (!vendorDocumentStore.file) handleDrag(e);
          }}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          onClick={() => {
            if (!vendorDocumentStore.file) onButtonClick();
          }}
          className={`${classes.formFileUpload} ${classes.labelFileUpload} ${
            vendorDocumentStore.file ? '' : classes.label
          }`}
        >
          <form onSubmit={e => e.preventDefault()}>
            {vendorDocumentStore.file ? (
              <Box className={classes.fileBox}>
                <Typography className={classes.fileName}>
                  {vendorDocumentStore.file && `${title}.${vendorDocumentStore.file[0]?.name.split('.').pop()}`}
                </Typography>
                <Box className={classes.fileSizeBox}>
                  <Typography>{`${(vendorDocumentStore.file[0].size / 1024).toFixed(2)}K`}</Typography>
                  <IconButton onClick={(e) => clearFile(e)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            ) : (
              <>
                <label className={dragActive ? `${classes.dragActive} ${classes.label}` : `${classes.label}`}>
                  <CloudUpload fontSize="large" />
                  <div className={classes.innerText}>
                    <Typography>Drag and Drop to Upload file</Typography>
                    <Typography>or</Typography>
                    <Typography>Add Attachment</Typography>
                  </div>
                </label>
                {dragActive && (
                  <div
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                    className={classes.dragFileElement}
                  ></div>
                )}
              </>
            )}
            {loader.spinner}
          </form>
        </div>
      </DialogContent>
      <DialogActions>
        <Box className={`${classes.primaryButton} ${classes.button}`}>
          <PrimaryButton
            variant="contained"
            startIcon={<UploadIcon />}
            onClick={onButtonClick}
          >
            Upload
          </PrimaryButton>
        </Box>
        <div className={`${classes.primaryButton} ${classes.button}`}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => uploadDocumentFile()}
            size="large"
            disabled={!vendorDocumentStore.file}
          >
            Save
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default inject('vendorDocumentStore')(withStyles(styles)(observer(UploadNewVendorDocumentFile)));
