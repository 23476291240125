import React from 'react';
import { INavigationLink } from '@uplink-shared/layout';
import { AdditionalInfoIcon } from '@uvgo-shared/icons';

const newVendorOnboarding = (): INavigationLink[] => [
  {
    to: '/vendor/new-vendor-information',
    title: 'New Vendor Information',
    expandedMenuBlock: 'panel3',
    icon: <AdditionalInfoIcon />,
    subMenuItem: [
      {
        to: '/vendor/new-vendor-information',
        title: 'New Vendor Information',
      },
    ],
  },
];

export default newVendorOnboarding;
  
