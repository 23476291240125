import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { styles } from './HoursData.styles';
import { IClasses } from '@wings-shared/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { LocationHoursModel } from '../../Models/LocationHours.model';
import { VendorLocationStore } from '../../../../Stores/VendorLocation.store';
import { inject } from 'mobx-react';
import { RootDataStore } from '@uplink-shared/layout';
import { AlertStore } from '@uvgo-shared/alert';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { VendorLocationHoursStore } from '../../../../Stores';

interface TimeRange {
  id: string;
  hoursId: number;
  scheduleId: number;
  patternedRecurrenceId: number;
  startTime: string;
  endTime: string;
  is24Hours: boolean;
  isNew?: boolean;
  sequence?: number;
  hoursTypeId?: number;
  statusId?: number;
  accessLevelId?: number;
  startDate?: Date;
  endDate?: Date;
  includeHoliday?: boolean;
  dayOfWeekId?: number;
  patternedRecurrenceDaysofWeekId?: number;
  active?: boolean;
}

interface HoursDataProps {
  classes: IClasses;
  timeData: { [key: string]: TimeRange[] };
  locationHoursList: LocationHoursModel[];
  vendorLocationStore: VendorLocationStore;
  vendorLocationHoursStore: VendorLocationHoursStore;
}

const dayNames = [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ];

const HoursData: FC<HoursDataProps> = ({
  classes,
  timeData,
  locationHoursList,
  vendorLocationStore,
  vendorLocationHoursStore,
}) => {
  const dayOfWeekIds: { [key: string]: number } = {
    Sunday: 1,
    Monday: 2,
    Tuesday: 3,
    Wednesday: 4,
    Thursday: 5,
    Friday: 6,
    Saturday: 7,
  };

  const addDefaultTimeRanges = hoursData => {
    const daysOfWeek = Object.keys(dayOfWeekIds);

    daysOfWeek.forEach(day => {
      if (!hoursData[day] || hoursData[day].length === 0) {
        const defaultObject = {
          id: `default-${day}-00:00-00:00`,
          hoursId: 0,
          scheduleId: 0,
          patternedRecurrenceId: 0,
          startTime: '00:00',
          endTime: '00:00',
          is24Hours: false,
          isNew: true,
          sequence: Math.floor(Math.random() * 999 + 1),
          hoursTypeId: 1,
          statusId: 1,
          accessLevelId: 1,
          startDate: null,
          endDate: null,
          includeHoliday: false,
          dayOfWeekId: dayOfWeekIds[day],
          patternedRecurrenceDaysofWeekId: 0,
          active: true,
        };

        hoursData[day] = [ defaultObject ];
      }
    });

    return hoursData;
  };

  const [ editableTimeData, setEditableTimeData ] = useState(addDefaultTimeRanges(timeData));

  useEffect(() => {
    if (locationHoursList.length > 0) return;

    const updatedTimeData: { [key: string]: TimeRange[] } = dayNames.reduce((acc, day) => {
      acc[day] = [];
      return acc;
    }, {} as { [key: string]: TimeRange[] });

    if (locationHoursList.length === 0) {
      dayNames.forEach(day => {
        updatedTimeData[day].push({
          id: `default-${day}-00:00-00:00`,
          hoursId: 0,
          scheduleId: 0,
          patternedRecurrenceId: 0,
          startTime: '00:00',
          endTime: '00:00',
          is24Hours: false,
          isNew: true,
          sequence: Math.floor(Math.random() * 999 + 1),
          hoursTypeId: 1,
          statusId: 1,
          accessLevelId: 1,
          startDate: null,
          endDate: null,
          includeHoliday: false,
          dayOfWeekId: dayOfWeekIds[day],
          patternedRecurrenceDaysofWeekId: 0,
          active: true,
        });
      });
    }
    setEditableTimeData(updatedTimeData);
  }, []);

  const handleTimeChange = (day: string, index: number, field: 'startTime' | 'endTime', value: string) => {
    vendorLocationStore.isTimeChanged = true;
    const sequenceList = Object.keys(editableTimeData).flatMap(dayKey =>
      editableTimeData[dayKey].map(timeRange => timeRange.sequence)
    );
    let uniqueSequence;
    do {
      uniqueSequence = Math.floor(Math.random() * 999 + 1);
    } while (sequenceList.includes(uniqueSequence));

    setEditableTimeData(prevData => {
      const updatedDayData = [ ...prevData[day] ];
      const currentRange = updatedDayData[index];
      const updatedRange = { ...currentRange, [field]: value };
      if (updatedRange.startTime === '00:01' && updatedRange.endTime === '23:59') {
        updatedRange.is24Hours = true;
        const filteredData = updatedDayData.filter((_, i) => i === index);

        filteredData.unshift(filteredData.pop());
        if (locationHoursList.length) {
          const matchedItem = locationHoursList.find(item => {
            const { schedule } = item;
            return (
              moment(schedule.startTime)
                .utc()
                .format('HH:mm') === '00:01' &&
              moment(schedule.endTime)
                .utc()
                .format('HH:mm') === '23:59'
            );
          });

          if (matchedItem) {
            const { schedule, id: hoursId } = matchedItem;
            filteredData[0] = {
              ...filteredData[0],
              hoursId,
              scheduleId: schedule.id,
              patternedRecurrenceId: schedule.patternedRecurrence?.id || 0,
              hoursTypeId: matchedItem.hoursType?.id,
              sequence: matchedItem.sequence || Math.floor(Math.random() * 999 + 1),
              statusId: matchedItem.status?.id,
              accessLevelId: matchedItem.accessLevel?.id,
              startDate: schedule.startDate,
              endDate: schedule.endDate,
              startTime: '00:01',
              endTime: '23:59',
              is24Hours: true,
              includeHoliday: schedule.includeHoliday,
              dayOfWeekId: dayOfWeekIds[day],
              patternedRecurrenceDaysofWeekId: 0,
              active: true,
            };
          } else {
            filteredData[0] = {
              ...filteredData[0],
              hoursId: 0,
              scheduleId: 0,
              patternedRecurrenceId: 0,
              hoursTypeId: 1,
              statusId: 1,
              accessLevelId: 1,
              startDate: null,
              endDate: null,
              startTime: '00:01',
              endTime: '23:59',
              is24Hours: true,
              includeHoliday: false,
              dayOfWeekId: dayOfWeekIds[day],
              sequence: uniqueSequence,
              patternedRecurrenceDaysofWeekId: 0,
              active: true,
            };
          }
        }

        return {
          ...prevData,
          [day]: filteredData,
        };
      } else {
        updatedRange.is24Hours = false;

        const isDuplicate = updatedDayData.some(
          (range, i) =>
            i !== index && range.startTime === updatedRange.startTime && range.endTime === updatedRange.endTime
        );

        vendorLocationStore.isDuplicateTime = isDuplicate;
        if (isDuplicate) {
          AlertStore.info('This time range already exists for the selected day.');
          return prevData;
        }

        updatedDayData[index] = updatedRange;

        return {
          ...prevData,
          [day]: updatedDayData,
        };
      }
    });
  };

  const handleCheckboxChange = (day: string, index: number, checked: Boolean) => {
    vendorLocationStore.isTimeChanged = true;
    const sequenceList = Object.keys(editableTimeData).flatMap(dayKey =>
      editableTimeData[dayKey].map(timeRange => timeRange.sequence)
    );
    let uniqueSequence;
    do {
      uniqueSequence = Math.floor(Math.random() * 999 + 1);
    } while (sequenceList.includes(uniqueSequence));
    if (checked) {
      setEditableTimeData(prevData => {
        const updatedDayData = [ ...prevData[day] ];
        const fullyUpdatedDayData = updatedDayData.map(range => ({
          ...range,
          is24Hours: false,
        }));
        return { ...prevData, [day]: fullyUpdatedDayData };
      });
    } else {
      setEditableTimeData(prevData => {
        let updatedDayData = [ ...prevData[day] ];
        updatedDayData = [ updatedDayData[index] ];
        if (locationHoursList.length === 0) {
          updatedDayData[index] = { ...updatedDayData[index], is24Hours: !updatedDayData[index].is24Hours };
          updatedDayData[0] = { ...updatedDayData[0], patternedRecurrenceDaysofWeekId: 0 };
          updatedDayData[0] = { ...updatedDayData[0], patternedRecurrenceId: 0 };
          updatedDayData[0] = { ...updatedDayData[0], hoursId: 0 };
          updatedDayData[0] = { ...updatedDayData[0], scheduleId: 0 };
          updatedDayData[0] = { ...updatedDayData[0], startTime: '00:01' };
          updatedDayData[0] = { ...updatedDayData[0], endTime: '23:59' };
          updatedDayData[0] = { ...updatedDayData[0], sequence: uniqueSequence };
          updatedDayData[0] = { ...updatedDayData[0], hoursTypeId: 1 };
          updatedDayData[0] = { ...updatedDayData[0], statusId: 1 };
          updatedDayData[0] = { ...updatedDayData[0], accessLevelId: 1 };
          updatedDayData[0] = { ...updatedDayData[0], includeHoliday: false };
          updatedDayData[0] = { ...updatedDayData[0], active: true };
        }
        locationHoursList.forEach(item => {
          const { schedule, id: hoursId } = item;
          const isTimeMatching =
            moment(schedule.startTime)
              .utc()
              .format('HH:mm') === '00:01' &&
            moment(schedule.endTime)
              .utc()
              .format('HH:mm') === '23:59';

          if (isTimeMatching) {
            updatedDayData[index] = { ...updatedDayData[index], is24Hours: !updatedDayData[index].is24Hours };
            updatedDayData[0] = { ...updatedDayData[0], patternedRecurrenceDaysofWeekId: 0 };
            updatedDayData[0] = { ...updatedDayData[0], patternedRecurrenceId: schedule.patternedRecurrence.id || 0 };
            updatedDayData[0] = { ...updatedDayData[0], hoursId: hoursId || 0 };
            updatedDayData[0] = { ...updatedDayData[0], scheduleId: schedule.id || 0 };
            updatedDayData[0] = { ...updatedDayData[0], startTime: '00:01' };
            updatedDayData[0] = { ...updatedDayData[0], endTime: '23:59' };
            updatedDayData[0] = {
              ...updatedDayData[0],
              sequence: item.sequence || uniqueSequence,
            };
            updatedDayData[0] = { ...updatedDayData[0], hoursTypeId: item.hoursType.id || 1 };
            updatedDayData[0] = { ...updatedDayData[0], statusId: item.status.id || 1 };
            updatedDayData[0] = { ...updatedDayData[0], accessLevelId: item.accessLevel.id || 1 };
            updatedDayData[0] = { ...updatedDayData[0], includeHoliday: schedule.includeHoliday || false };
            updatedDayData[0] = { ...updatedDayData[0], active: true };
          } else {
            updatedDayData[index] = { ...updatedDayData[index], is24Hours: !updatedDayData[index].is24Hours };
            updatedDayData[0] = { ...updatedDayData[0], patternedRecurrenceDaysofWeekId: 0 };
            updatedDayData[0] = { ...updatedDayData[0], patternedRecurrenceId: 0 };
            updatedDayData[0] = { ...updatedDayData[0], hoursId: 0 };
            updatedDayData[0] = { ...updatedDayData[0], scheduleId: 0 };
            updatedDayData[0] = { ...updatedDayData[0], startTime: '00:01' };
            updatedDayData[0] = { ...updatedDayData[0], endTime: '23:59' };
            updatedDayData[0] = { ...updatedDayData[0], sequence: uniqueSequence };
            updatedDayData[0] = { ...updatedDayData[0], hoursTypeId: 1 };
            updatedDayData[0] = { ...updatedDayData[0], statusId: 1 };
            updatedDayData[0] = { ...updatedDayData[0], accessLevelId: 1 };
            updatedDayData[0] = { ...updatedDayData[0], includeHoliday: false };
            updatedDayData[0] = { ...updatedDayData[0], active: true };
          }
          return { ...prevData, [day]: updatedDayData };
        });
        return { ...prevData, [day]: updatedDayData };
      });
      setEditableTimeData(prevData => {
        const updatedDayData = [ ...prevData[day] ];
        const fullyUpdatedDayData = updatedDayData.map(range => ({
          ...range,
          is24Hours: true,
        }));
        return { ...prevData, [day]: fullyUpdatedDayData };
      });
    }
  };

  const handleAddTimeRange = (day: string) => {
    vendorLocationStore.isTimeChanged = true;
    const sequenceList = Object.keys(editableTimeData).flatMap(dayKey =>
      editableTimeData[dayKey].map(timeRange => timeRange.sequence)
    );
    let uniqueSequence;
    do {
      uniqueSequence = Math.floor(Math.random() * 999 + 1);
    } while (sequenceList.includes(uniqueSequence));

    setEditableTimeData(prevData => {
      const newTimeRange: TimeRange = {
        id: `new-${day}-${Date.now()}`,
        hoursId: 0,
        scheduleId: 0,
        patternedRecurrenceId: 0,
        startTime: '00:00',
        endTime: '00:00',
        is24Hours: false,
        isNew: true,
        sequence: uniqueSequence,
        hoursTypeId: 1,
        statusId: 1,
        accessLevelId: 1,
        startDate: null,
        endDate: null,
        includeHoliday: false,
        dayOfWeekId: dayOfWeekIds[day],
        patternedRecurrenceDaysofWeekId: 0,
        active: true,
      };
      return { ...prevData, [day]: [ ...prevData[day], newTimeRange ] };
    });
  };

  const handleDeleteTimeRange = (day: string, index: number) => {
    vendorLocationStore.isTimeChanged = true;
    setEditableTimeData(prevData => {
      const updatedDayData = [ ...prevData[day] ];
      updatedDayData.splice(index, 1);
      return { ...prevData, [day]: updatedDayData };
    });
  };

  function convertToISOFormat(time: string) {
    if (moment(time, 'HH:mm', true).isValid()) {
      const [ hours, minutes ] = time.split(':');
      const date = new Date();
      date.setUTCHours(hours, minutes, 0, 0);
      return date.toISOString();
    }
  }

  const groupDaysByTimeRange = (editableTimeData: { [key: string]: TimeRange[] }) => {
    const groupedTimeData: { [key: string]: any } = {};
    for (const day in editableTimeData) {
      if (editableTimeData.hasOwnProperty(day)) {
        editableTimeData[day].forEach(
          ({
            hoursId,
            sequence,
            hoursTypeId,
            statusId,
            accessLevelId,
            startTime,
            endTime,
            patternedRecurrenceDaysofWeekId,
            startDate,
            endDate,
            is24Hours,
            includeHoliday,
            patternedRecurrenceId,
            scheduleId,
          }) => {
            const timeKey = `${startTime}-${endTime}`;

            let matchingEntry = null;
            for (const entry of locationHoursList) {
              const entryStartTime = moment(entry.schedule.startTime)
                .utc()
                .format('HH:mm');
              const entryEndTime = moment(entry.schedule.endTime)
                .utc()
                .format('HH:mm');

              if (
                entryStartTime === startTime &&
                entryEndTime === endTime &&
                startTime !== '00:00' &&
                endTime !== '00:00'
              ) {
                matchingEntry = entry;
                break;
              }
            }

            if (groupedTimeData[timeKey]) {
              groupedTimeData[timeKey].id = matchingEntry ? hoursId : 0;
              groupedTimeData[timeKey].scheduleRequest.id = matchingEntry ? scheduleId : 0;
              groupedTimeData[timeKey].scheduleRequest.patternedRecurrenceRequest.id = matchingEntry
                ? patternedRecurrenceId
                : 0;
              groupedTimeData[
                timeKey
              ].scheduleRequest.patternedRecurrenceRequest.patternedRecurrenceDaysofWeekRequest.push({
                id: 0,
                dayOfWeekId: dayOfWeekIds[day],
              });
            } else {
              groupedTimeData[timeKey] = {
                id: 0,
                userId: '',
                vendorLocationId: RootDataStore.locationData.locationId,
                sequence,
                hoursTypeId,
                statusId,
                accessLevelId,
                hoursScheduleTypeId: 1,
                scheduleRequest: {
                  id: 0,
                  startTime: convertToISOFormat(startTime),
                  endTime: convertToISOFormat(endTime),
                  startDate,
                  endDate,
                  is24Hours: Boolean(
                    moment(startTime)
                      .utc()
                      .format('HH:mm') === '00:01' &&
                      moment(endTime)
                        .utc()
                        .format('HH:mm') === '23:59'
                  ),
                  includeHoliday,
                  patternedRecurrenceRequest: {
                    id: 0,
                    patternedRecurrenceDaysofWeekRequest: [
                      {
                        id: 0,
                        dayOfWeekId: dayOfWeekIds[day],
                      },
                    ],
                  },
                },
              };
            }
          }
        );
      }
    }

    const newState = Object.values(groupedTimeData);
    return newState;
  };

  useEffect(() => {
    const filteredTimeData = groupDaysByTimeRange(editableTimeData).filter(item => {
      const startTime = moment(item.scheduleRequest.startTime)
        .utc()
        .format('HH:mm');
      const endTime = moment(item.scheduleRequest.endTime)
        .utc()
        .format('HH:mm');
      return !(startTime === '00:00' && endTime === '00:00');
    });
    vendorLocationHoursStore.updatedHoursData = LocationHoursModel.deserializeList(filteredTimeData);
  }, [ editableTimeData ]);

  const handleSwitchChange = (day: string, checked: boolean) => {
    vendorLocationStore.isTimeChanged = true;
    const sequenceList = Object.keys(editableTimeData).flatMap(dayKey =>
      editableTimeData[dayKey].map(timeRange => timeRange.sequence)
    );
    let uniqueSequence;
    do {
      uniqueSequence = Math.floor(Math.random() * 999 + 1);
    } while (sequenceList.includes(uniqueSequence));
    setEditableTimeData(prevSchedule => {
      const dayData = prevSchedule[day];

      const updatedObject = {
        ...dayData[0],
        active: checked,
        startTime: '00:00',
        endTime: '00:00',
      };

      const updateDayData = {
        ...prevSchedule,
        [day]: [ updatedObject ],
      };

      return updateDayData;
    });
  };

  return (
    <Box>
      {Object.keys(editableTimeData).map(day => {
        const timeRanges = editableTimeData[day];
        const is24HoursApplied = timeRanges.some(range => range.is24Hours);
        const isInActive = timeRanges.some(range => !range.active);
        const disableAddTimeRange = timeRanges.some(time => time.startTime === '00:01' && time.endTime === '23:59');
        return (
          <Grid container spacing={3} xs={12} key={day} className={classes.columns}>
            <Grid item xs={2} style={{ textAlign: 'left' }}>
              <label>{day}</label>
              <Box>
                <Switch
                  checked={!isInActive}
                  onChange={e => handleSwitchChange(day, isInActive)}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{
                    transform: 'scale(0.5)',
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={10}>
              {timeRanges.map((timeRange, index) => (
                <Grid container spacing={3} xs={12} key={timeRange.id}>
                  <Grid item xs={8} className={classes.columns}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardTimePicker
                        disabled={is24HoursApplied || isInActive}
                        value={moment(timeRange.startTime, 'HH:mm')}
                        onChange={time => handleTimeChange(day, index, 'startTime', moment(time).format('HH:mm'))}
                        format="HH:mm"
                        ampm={false}
                        margin="dense"
                        variant="inline"
                        invalidDateMessage="Enter Valid Time"
                        keyboardIcon={<AccessTimeIcon />}
                      />
                      <Box style={{ padding: '10px', marginLeft: '10px' }}>
                        <label>to</label>
                      </Box>
                      <KeyboardTimePicker
                        disabled={is24HoursApplied || isInActive}
                        value={moment(timeRange.endTime, 'HH:mm')}
                        onChange={time => handleTimeChange(day, index, 'endTime', moment(time).format('HH:mm'))}
                        format="HH:mm"
                        ampm={false}
                        margin="dense"
                        variant="inline"
                        style={{ marginLeft: 16 }}
                        invalidDateMessage="Enter Valid Time"
                        keyboardIcon={<AccessTimeIcon />}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={4}>
                    {index === 0 && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={is24HoursApplied}
                            onChange={() => handleCheckboxChange(day, index, is24HoursApplied)}
                            color="primary"
                            disabled={isInActive}
                          />
                        }
                        label="Apply 24 Hours"
                      />
                    )}
                    {index != 0 && (
                      <IconButton color="primary" onClick={() => handleDeleteTimeRange(day, index)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <div className={classes.defaultButton}>
              <Button
                variant="text"
                color="primary"
                onClick={() => handleAddTimeRange(day)}
                disabled={is24HoursApplied || disableAddTimeRange || timeRanges.length === 5 || isInActive}
                startIcon={<AddBoxIcon />}
                style={{ fontStyle: 'normal' }}
              >
                Add Time Range
              </Button>
            </div>
          </Grid>
        );
      })}
    </Box>
  );
};

export default inject('vendorLocationStore', 'vendorLocationHoursStore')(withStyles(styles)(HoursData));
