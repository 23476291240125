import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper } from '@material-ui/core';
import { useStyles } from './DataExpiryStepper.styles';
import { DetailsEditorWrapper, RootDataStore, SidebarStore } from '@uplink-shared/layout';
import {
  DataExpirationModel,
  DataExpirationSectionTypeModel,
  SliderSideBarMenu,
  VendorLocationModel,
} from '../../../Shared';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { UIStore } from '@wings-shared/core';
import { VendorLocationStore } from '../../../../Stores';
import { finalize, takeUntil } from 'rxjs/operators';
import { useUnsubscribe } from '@wings-shared/hooks';
interface Props {
  dataExpirationData?: DataExpirationModel[];
  vendorLocationStore: VendorLocationStore;
}

interface DataExpirySliderProps {
  slide: number;
  title: string;
  buttonText: string;
  component: any;
  redirectUrl: string;
  sectionType: DataExpirationSectionTypeModel;
  sectionTypeName: string;
  vendorLocation: VendorLocationModel;
}

const DataExpiryStepper: React.FC<Props> = ({ dataExpirationData, vendorLocationStore }) => {
  const [ activeStep, setActiveStep ] = useState(0);
  const [ dataExpiryState, setDataExpiryState ] = useState<DataExpirySliderProps[]>([]);
  const navigate = useNavigate();
  const classes = useStyles();
  const unsubscribe = useUnsubscribe();

  useEffect(() => {
    if (dataExpirationData && dataExpirationData.length > 0) {
      const uniqueDataMap = new Map();

      dataExpirationData.forEach((item, index) => {
        let sectionTypeName, redirectUrl;
        switch (item.sectionType.name) {
          case 'VendorAddress':
            sectionTypeName = 'Addresses';
            redirectUrl = 'location/expiry/addresses';
            break;
          case 'VendorContact':
            sectionTypeName = 'Contacts';
            redirectUrl = 'location/expiry/contacts';
            break;
          case 'VendorDocument':
            sectionTypeName = 'Documents';
            redirectUrl = 'location/expiry/documents/';
            break;
          case 'VendorLocationAddress':
            sectionTypeName = 'Addresses';
            redirectUrl = 'location/expiry/addresses';
            break;
          case 'VendorLocationContact':
            sectionTypeName = 'Contacts';
            redirectUrl = 'location/expiry/contacts';
            break;
          case 'VendorLocationDocument':
            sectionTypeName = 'Documents';
            redirectUrl = 'location/expiry/documents/';
            break;
          case 'VendorLocationServiceComm':
            sectionTypeName = 'Service Comm';
            redirectUrl = 'location/operational-essential';
            break;
          case 'ServiceItemPricing':
            sectionTypeName = 'Pricing';
            redirectUrl = 'location/expiry/pricing';
            break;
        }

        const uniqueKey = `${sectionTypeName}-${item.vendorLocation.vendorLocationId}`;
        if (!uniqueDataMap.has(uniqueKey)) {
          uniqueDataMap.set(uniqueKey, {
            slide: index,
            title: `Update your ${sectionTypeName} for ${item.vendorLocation.name} - ${item.vendorLocation.airportReference?.displayCode}`,
            buttonText: `Go to ${sectionTypeName} Page`,
            redirectUrl: redirectUrl,
            sectionTypeName: sectionTypeName,
            sectionType: item.sectionType,
            vendorLocation: item.vendorLocation,
          });
        }
      });

      setDataExpiryState(Array.from(uniqueDataMap.values()));
    }
    SidebarStore.setNavLinks(SliderSideBarMenu(), 'vendor');
  }, [ dataExpirationData ]);

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSkip = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const loadVendorLocationData = parseState => {
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.getVendorLocationById(parseState.vendorLocation.vendorLocationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: VendorLocationModel) => {
        if (response) {
          RootDataStore.setLocation(
            true,
            response.id,
            response.name,
            response.code,
            response.airportReference?.id,
            '',
            response.airportDataManagement,
            response.countryDataManagement,
            response.permitDataManagement
          );
          navigate(parseState.redirectUrl, {
            state: parseState,
          });
        }
      });
  };

  return (
    <DetailsEditorWrapper
      headerActions={<Typography variant="h5">{dataExpiryState[activeStep]?.title}</Typography>}
      isEditMode={true}
      classes={{ headerActions: classes.headerActions }}
    >
      <Box>
        <Box className={classes.innerBox2}>
          <Paper className={classes.paper}>
            <Box>
              <Box>{dataExpiryState[activeStep]?.title}</Box>
              <Box className={classes.slideCount}>
                {dataExpiryState[activeStep]?.slide + 1}/{dataExpiryState.length}
              </Box>
            </Box>
            <Box>
              <Box className={classes.primaryButton}>
                <PrimaryButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const sanitizedState = JSON.parse(JSON.stringify(dataExpiryState[activeStep]));
                    loadVendorLocationData(sanitizedState);
                  }}
                >
                  {dataExpiryState[activeStep]?.buttonText}
                </PrimaryButton>
              </Box>
            </Box>
            <Box className={classes.innerBox3}>
              <Box className={classes.defaultButton}>
                {dataExpiryState[activeStep]?.slide !== 0 && (
                  <PrimaryButton disabled={!activeStep} variant="outlined" onClick={handleBack}>
                    Back
                  </PrimaryButton>
                )}
              </Box>
              <Box className={classes.innerBox3}>
                <Box className={classes.primaryButton}>
                  <PrimaryButton
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (activeStep !== dataExpiryState.length - 1) {
                        handleSkip();
                      } else {
                        navigate('/vendor/general-info');
                      }
                    }}
                  >
                    Skip
                  </PrimaryButton>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </DetailsEditorWrapper>
  );
};
export default inject('vendorLocationStore')(observer(DataExpiryStepper));
