import { CoreModel, modelProtection } from '@wings-shared/core';
import { IAPIResponseVendorAddress } from '../Interfaces/Response/API-Response-VendorAddress';
import { IAPIRequestVendorAddress } from '../Interfaces/Request/API-Request-VendorAddress.interface';
import { SettingBaseModel } from './SettingBase.model';
import { CityModel, CountryModel, StateModel } from '@uplink/shared';

@modelProtection
export class VendorAddressModel extends CoreModel {
  // vendorAddressId: number = 0;
  addressTypeId: number = 0;
  addressType: SettingBaseModel = new SettingBaseModel();
  addressLine1: string = '';
  addressLine2: string = '';
  countryReference: CountryModel;
  stateReference: StateModel;
  cityReference: CityModel;
  countryId: number;
  countryCode: string;
  countryName: string = '';
  stateId: number = 0;
  stateCode: string = '';
  stateName: string = '';
  cityId: number = 0;
  cityCode: string = '';
  cityName: string = '';
  zipCode: string = '';
  id: number = 0;
  userId?: string = '';
  constructor(data?: Partial<VendorAddressModel>) { 
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: IAPIResponseVendorAddress): VendorAddressModel {
    if (!apiData) {
      return new VendorAddressModel();
    }
    const data: Partial<VendorAddressModel> = {
      ...apiData,
      id: apiData.vendorAddressId||apiData.id,
      addressTypeId: apiData.addressType?.id,
      addressType: SettingBaseModel.deserialize(apiData.addressType),
      addressLine1: apiData.addressLine1,
      addressLine2: apiData.addressLine2,
      countryReference: CountryModel.deserialize(apiData.countryReference),
      stateReference: StateModel.deserialize(apiData.stateReference),
      cityReference: CityModel.deserialize(apiData.cityReference),
      zipCode: apiData.zipCode,
    };
    return new VendorAddressModel(data);
  }

  public serialize(vendorId):IAPIRequestVendorAddress {
    return {
      userId: this.userId,
      id: this.id || 0,
      addressTypeId: this.addressType.id,
      cityId: this.hqAddressCity.id,
      cityName: this.hqAddressCity.commonName,
      cityCode: this.hqAddressCity.cappsCode,
      stateId: this.hqAddressState?.id,
      stateName: this.hqAddressState?.commonName,
      stateCode: this.hqAddressState?.isoCode,
      countryId: this.hqAddressCountry.id,
      countryCode: this.hqAddressCountry.isO2Code,
      countryName: this.hqAddressCountry.commonName,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2?.replace(/^\s*\s*$/, '') || null,
      zipCode: this.hqAddressZipCode?this.hqAddressZipCode:null,
      vendorId: vendorId,
    };
  }

  static deserializeList(apiDataList: IAPIResponseVendorAddress[]): VendorAddressModel[] {
    return apiDataList ? apiDataList.map((apiData: any) => VendorAddressModel.deserialize(apiData)) : [];
  }
}
