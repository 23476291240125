import { Theme, createStyles, lighten } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    muiDialog: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: '1055px',
      },
      '& .MuiIconButton-root': {
        color: '#1976D2',
      },
      '& .MuiDialogContent-root': {
        color: theme.palette.text.primary,
        '& p': {
          fontSize: '14px',
          fontWeight: '700',
        },
        '& .MuiSvgIcon-fontSizeLarge': {
          width: '89.89px',
          height: '57.56px',
          opacity: '15%',
          color: '#7C7C7C'
        },
      },
      '& .MuiDialogActions-root': {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
      },
      '& .MuiTypography-root': {
        fontWeight: 700,
        fontSize: '18px',
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '16px 24px 0px 0px',
      color: theme.palette.text.primary,
      '& .MuiIconButton-root': {
        color: '#7C7C7C',
      },
    },
    button: {
      marginLeft: '16px',
      '& button': {
        padding: '4px 10px',
        height: '40px',
        fontSize: '14px',
        fontWeight: '400',
        width: '100px',
        textTransform: 'capitalize',
        color: '#1976D2',
        boxShadow: 'none',
      },
      '& button:hover':{
        color: '#fff'
      }
    },
    primaryButton: {
      '& button': {
        backgroundColor: '#1976D2',
        color: '#fff',
        boxShadow: 'none !important',
      },
      '& button:hover':{
        backgroundColor: '#63A4FF',
        color: '#fff',
      },
      '& button:disabled': {
        background: '#afafaf',
        border: 'none',
      },
    },
    dragActive: {
      backgroundColor: '#f8fafc',
    },
    formFileUpload: {
      height: '168px',
      textAlign: 'center',
      position: 'relative',
    },
    inputFileUpload: {
      display: 'none',
    },
    labelFileUpload: {
      margin: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: '2px',
      borderRadius: '1rem',
      borderStyle: 'dashed',
      borderColor: `${lighten(theme.palette.divider, 0.6)}`,
      backgroundColor: theme.palette.background.paper,
    },
    label: {
      cursor: 'pointer',
    },
    innerText: {
      display: 'flex',
      gap: '10px',
      color: theme.palette.text.primary,
      '& p:nth-child(1)': {
        color: theme.palette.text.primary,
      },
      '& p:nth-child(2)': {
        fontWeight: '400 !important',
        fontStyle: 'italic',
        color: theme.palette.text.primary,
      },
      '& p:nth-child(3)': {
        fontWeight: '700 !important',
        color: '#1976D2',
      },
    },
    dragFileElement: {
      position: 'absolute',
      width: '90%',
      height: '100%',
      borderRadius: '1rem',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
    fileBox: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '4px 20px 4px 20px',
      backgroundColor: '#F1F1F1',
      borderRadius: '4px',
      alignItems: 'center',
      margin: theme.spacing(1),
      marginTop: theme.spacing(3),
      width: '592px',
      height: '75px',
      fontWeight: 400,
    },
    fileName:{
      color: '#202020'
    },
    fileSizeBox: {
      display: 'flex',
      gap: '10px',
      color: '#202020',
      alignItems: 'center',
      '& .MuiIconButton-root': {
        color: '#7C7C7C',
      },
    },
  });
