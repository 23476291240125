import { CoreModel, modelProtection } from '@wings-shared/core';
import { IAPIResponseVendorUser } from '../Interfaces/Response/API-Response-VendorUser';
import { VendorLocationModel } from './VendorLocation.model';

@modelProtection
export class VendorUserLocationModel extends CoreModel {
  id: number = 0;
  vendorUserId?: number = 0;
  vendorLocation: VendorLocationModel= new VendorLocationModel()

  constructor(data?: Partial<VendorUserLocationModel>) { 
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: VendorUserLocationModel): VendorUserLocationModel {
    if (!apiData) {
      return new VendorUserLocationModel();
    }
    const data: Partial<VendorUserLocationModel> = {
      ...apiData,
      id: apiData.id,
      vendorUserId: apiData.vendorUserId,
      vendorLocation: VendorLocationModel.deserialize({
        ...apiData.vendorLocation,
        id: apiData.vendorLocation.vendorLocationId,
      })
    };
    return new VendorUserLocationModel(data);
  }

  static deserializeList(apiDataList: IAPIResponseVendorUser[]): VendorUserLocationModel[] {
    return apiDataList ? apiDataList.map((apiData: any) => VendorUserLocationModel.deserialize(apiData)) : [];
  }

  public get label(): string {
    if (this.vendorLocation?.name) {
      const code = this.vendorLocation.airportReference?.getDisplayCode()
        ? `(${this.vendorLocation.airportReference?.getDisplayCode()})`
        : this.vendorLocation.vendorLocationCityReferenceModel?.cityReference?.name
          ? `(${this.vendorLocation.vendorLocationCityReferenceModel.cityReference?.code ||
          this.vendorLocation.vendorLocationCityReferenceModel.cityReference?.name})`
          : '';
      return `${this?.vendorLocation?.name} ${code}`;
    }
    return '';
  }

  public get value(): string {
    if (this.vendorLocation?.value) {
      return this.vendorLocation.value;
    }
    return '';
  }
}
