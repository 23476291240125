import { createStyles, Theme } from '@material-ui/core';

export const styles = createStyles((theme:Theme) => ({
  headerActions: {
    justifyContent: 'space-between',
    borderBottom: '1px solid #D3D3D3',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    '& button':{
      width: '150px',
      marginRight: theme.spacing(1),
    },
    '& h5':{
      fontSize: '1.625rem',
      // color: theme.palette.grey.A700
    },
    '& .MuiTypography-h6':{
      // width: '300px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordWrap: 'normal',
      whiteSpace: 'nowrap',
      fontSize: '1.625rem',
    }
  },
  container:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  customHeight: {
    height: 'calc(100vh - 290px)'
  },
  editorWrapperContainer: {
    overflow: 'auto',
  },
  gridHeight:{
    paddingBottom:'70px'
  },

  defaultButton: {
    marginLeft:'16px',
    '& button':{
      border: '1px solid #1976D2',
      padding:'4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize:'14px',
      fontWeight: '400',
      minWidth:'150px',
      textTransform:'capitalize',
      color:'#1976D2'
    }
  },
  primaryButton:{
    '& button':{
      background: '#1976D2',
      color:'#fff'
    },
    '& button:disabled':{
      background: '#afafaf',
      border:'none'
    }
  },
  primaryButtonWithIcon:{
    '& button':{
      color:'#1976D2',
      background: 'none',
      '& .MuiButton-startIcon':{
        backgroundColor: '#1976D2',
        borderRadius: '2px',
        padding: '2px 4px',
        fontWeight:'400',
        // color: theme.palette.common.white
      }
    }
  }
}));