import { createStyles, Theme } from '@material-ui/core';

export const styles = createStyles((theme: Theme) => ({
  imageIcon: {
    height: '16px',
    width: '16px',
    cursor: 'pointer',
    color: '#1976D2',
    pointerEvents: 'all',
    marginLeft: theme.spacing(1),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
}));
