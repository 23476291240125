import { action, observable } from 'mobx';
import { apiUrls } from '../API.url';
import { map, tap } from 'rxjs/operators';
import { Logger } from '@uplink-shared/security';
import { baseApiPath, HttpClient } from '@uplink/shared';
import { Observable } from 'rxjs';
import { vendorManagementHeaders } from '../Base.store';
import { IAPIPageResponse, tapWithAction } from '@wings-shared/core';
import { VendorOnBoardSlideThree } from 
  '../../Modules/Shared/Models/VendorLocationOnBoarding/VendorOnBoardSlideThree.model';
import { OperationTypeDocumentList } from 
  '../../Modules/Shared/Models/VendorLocationOnBoarding/OperationTypeDocumentList.model';
import { VendorOnBoardSlideThreeDocument } from 
  '../../Modules/Shared/Models/VendorLocationOnBoarding/VendorOnBoardSlideThreeDocument.model';
import { IAPIDocumentFile } from '../../Modules/Shared/Interfaces/Request/API-Request-VendorDocument';
import { SettingBaseModel } from '../../Modules/Shared';
import { AlertStore } from '@uvgo-shared/alert';

export class SlideThreeStore {
  @observable public newVendorDocuments: OperationTypeDocumentList[] = [];
  @observable public uploadedDocumentsList: VendorOnBoardSlideThreeDocument[] = [];
  @observable public operationTypeId: number = 0;

  @action
  public getDocumentsByOperationTypeId(operationTypeId: number): Observable<IAPIPageResponse<VendorOnBoardSlideThree>> {
    const http: HttpClient = new HttpClient({ headers: vendorManagementHeaders });
    return http
      .get<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnBoardSlideThree}/${operationTypeId}`)
      .pipe(
        Logger.observableCatchError,
        map(response => ({ ...response, results: VendorOnBoardSlideThree.deserialize(response) })),
        tapWithAction(response => {
          this.newVendorDocuments = OperationTypeDocumentList.deserializeList(response.operationTypeDocumentLists);
          if (this.uploadedDocumentsList.length > 0) {
            this.newVendorDocuments = this.newVendorDocuments.filter(
              doc =>
                !this.uploadedDocumentsList.some(
                  item => item.operationTypeDocument.documentName.id === doc?.documentType.id
                )
            );
          }
        })
      );
  }

  @action
  public getAllDocumentsByOperationTypeId(
    operationTypeId: number
  ): Observable<IAPIPageResponse<VendorOnBoardSlideThree>> {
    const http: HttpClient = new HttpClient({ headers: vendorManagementHeaders });
    return http
      .get<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnBoardSlideThree}/${operationTypeId}`)
      .pipe(
        Logger.observableCatchError,
        map(response => ({ ...response, results: VendorOnBoardSlideThree.deserialize(response) }))
      );
  }

  @action
  public getUploadedDocuments(tempLocationId: string): Observable<IAPIPageResponse<VendorOnBoardSlideThreeDocument>> {
    const http: HttpClient = new HttpClient({ headers: vendorManagementHeaders });
    return http
      .get<any>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnBoardSlideThreeDocument}/getbytemplocationid?tempLocationId=${tempLocationId}`
      )
      .pipe(
        Logger.observableCatchError,
        map(response => ({ ...response, results: VendorOnBoardSlideThreeDocument.deserializeList(response) })),
        tapWithAction(response => (this.uploadedDocumentsList = response.results))
      );
  }

  @action
  public getDocumentById(documentId: number): Observable<SettingBaseModel> {
    const http: HttpClient = new HttpClient({ headers: vendorManagementHeaders });
    return http
      .get<SettingBaseModel>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorDocumentName}/${documentId}`)
      .pipe(
        Logger.observableCatchError,
        map(response => SettingBaseModel.deserialize(response))
      );
  }

  @action
  public downloadDocumentFile(vendorId: number, documentUri: string): Observable<IAPIDocumentFile> {
    const data = {
      vendorId: vendorId,
      documentUri,
    };
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementCoreUrl,
      headers: vendorManagementHeaders,
    });
    return http.post<IAPIDocumentFile>(`${apiUrls.vendorOnBoardSlideThreeDocument}/Download`, data).pipe(
      Logger.observableCatchError,
      map(response => ({ ...response, results: response.results }))
    );
  }

  @action
  public deleteDocumentById(documentId: number): Observable<string> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    return http
      .delete<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnBoardSlideThreeDocument}?Id=${documentId}`)
      .pipe(
        Logger.observableCatchError,
        map((response: any) => response),
        tap(() => AlertStore.info('Vendor Document deleted successfully!'))
      );
  }

  @action
  public upsertDocument(payload: VendorOnBoardSlideThreeDocument): Observable<VendorOnBoardSlideThreeDocument> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    payload.id = payload.id === null ? 0 : payload.id;
    const isNewRequest: boolean = !(payload.id != null && payload.id !== 0);
    const upsertRequest: Observable<VendorOnBoardSlideThreeDocument> = isNewRequest
      ? http.post<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnBoardSlideThreeDocument}`, payload)
      : http.put<any>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnBoardSlideThreeDocument}/${payload.id}`,
        payload
      );
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() => AlertStore.info('Document saved successfully!')),
      map(response => VendorOnBoardSlideThreeDocument.deserialize(response))
    );
  }
}
