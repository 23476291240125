import React, { FC, ReactNode, useEffect, useState } from 'react';
import { SettingsStore, VendorLocationStore } from '../../../../Stores';
import { useUnsubscribe } from '@wings-shared/hooks';
import { useBaseUpsertComponent } from '@uplink/shared';
import { UIStore, IAPISearchFiltersDictionary, IOptionValue, IClasses } from '@wings-shared/core';
import { forkJoin } from 'rxjs';
import {
  SETTING_ID,
  SettingBaseModel,
  ViewInputControls,
  SidebarMenuOperationalInfo,
  InformationDialog,
  PassengerLogisticsModel,
} from '../../../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import { inject, observer } from 'mobx-react';
import { ConfirmNavigate, DetailsEditorWrapper, RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import { Box, Button, Dialog, Tooltip, Typography, withStyles } from '@material-ui/core';
import { PassengerLogisticsStore } from '../../../../Stores/PassengerLogistics.store';
import HelpIcon from '@material-ui/icons/Help';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import GuideLineInformationData from './GuideLineInformationData';
import { fields } from '../PassengerLogistics/Fields';
import { useNavigate } from 'react-router';
import { styles } from '../PassengerLogistics/PassengerLogistics.styles';

interface Props {
  classes?: IClasses;
  vendorLocationStore: VendorLocationStore;
  settingsStore: SettingsStore;
  passengerLogisticsStore: PassengerLogisticsStore;
  searchFilters: IAPISearchFiltersDictionary;
}

const AircraftLogistics: FC<Props> = ({
  settingsStore,
  vendorLocationStore,
  passengerLogisticsStore,
  classes,
  searchFilters,
}) => {
  const useUpsert = useBaseUpsertComponent<PassengerLogisticsModel>({}, fields, searchFilters);
  const formRef = useUpsert.form;
  const unsubscribe = useUnsubscribe();
  const navigate = useNavigate();
  const [ aircraftParkingDistanceFBO, setAircraftParkingDistanceFBO ] = useState([]);
  const [ appliedAircraftParkingOptions, setAppliedAircraftParkingOptionsList ] = useState([]);
  const [ appliedAircraftSpotAccommodation, setAppliedAircraftSpotAccommodationList ] = useState([]);
  const [ hangerAvailableUom, setHangerAvailableUomList ] = useState([]);
  const [ appliedTowbarScenarios, setAppliedTowbarScenariosList ] = useState([]);
  const [ appliedInternationalArrivalProceduresList, setAppliedInternationalArrivalProceduresList ] = useState([]);
  const [ yesOrNoList, setYesOrNoListList ] = useState(PassengerLogisticsModel.getYesNo());

  useEffect(() => {
    SidebarStore.setNavLinks(
      SidebarMenuOperationalInfo(),
      'vendor',
      'Operational Information',
      '/vendor/locations',
      'panel2'
    );
    loadInitialData();
    setFormPlaceholders();
    passengerLogisticsStore.towBarFieldRequired = false;
  }, []);

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    settingsStore.getSettings( SETTING_ID.SETTING_AIRCRAFT_PARKING_OPTIONS).subscribe(response => {
      setAppliedAircraftParkingOptionsList(response.results);
    }),
    settingsStore.getSettings( SETTING_ID.SETTING_AIRCRAFT_PARKING_DISTANCE_FBO).subscribe(response => {
      setAircraftParkingDistanceFBO(response.results);
    }),
    settingsStore.getSettings( SETTING_ID.SETTING_AIRCRAFT_SPOT_ACCOMMODATION).subscribe(response => {
      setAppliedAircraftSpotAccommodationList(response.results);
    }),
    settingsStore.getSettings( SETTING_ID.SETTING_TOWBAR_SCENARIOS).subscribe(response => {
      setAppliedTowbarScenariosList(response.results);
    }),
    settingsStore.getSettings( SETTING_ID.SETTING_HANGER_AVAILABLE_UOM).subscribe(response => {
      setHangerAvailableUomList(response.results);
    }),
    settingsStore.getSettings( SETTING_ID.SETTING_INTERNATIONAL_ARRIVAL_PROCEDURES).subscribe(response => {
      setAppliedInternationalArrivalProceduresList(response.results);
    }),
    forkJoin([
      vendorLocationStore.getVendorLocationById(parseInt(RootDataStore.locationData.locationId)),
      vendorLocationStore.getCustomers(),
    ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: [VendorLocationModel, CustomersModel]) => {
        if (response[0].operationalInsight === null) {
          useUpsert.setFormValues(new PassengerLogisticsModel());
        } else {
          useUpsert.setFormValues(PassengerLogisticsModel.deserialize(response[0].operationalInsight));
          setFormValues(response[0].operationalInsight);
        }
      });
  };

  const setFormPlaceholders = () => {
    useUpsert.getField('aircraftParkingField').$placeholder = 'Aircraft Parking on Field';
    useUpsert.getField('hangarAvailableSpace').$placeholder = 'Hangar Available Space';
    useUpsert.getField('towbarRequirement').$placeholder = 'Towbar Requirements';
  };

  const setFormValues = response => {
    if (response.hangarAvailable === true) {
      useUpsert.getField('hangarAvailable').set(
        SettingBaseModel.deserialize({
          id: 1,
          name: 'Yes',
        })
      );
    } else if (response.hangarAvailable === false) {
      useUpsert.getField('hangarAvailable').set(
        SettingBaseModel.deserialize({
          id: 2,
          name: 'No',
        })
      );
    }
    if (response?.towbarRequired === 'Sometimes') {
      if (response.appliedTowbarScenarios.length !== 0) {
        passengerLogisticsStore.towBarFieldRequired = false;
        useUpsert.getField('appliedTowbarScenarios').set('label', 'Towbar Required For');
      } else {
        passengerLogisticsStore.towBarFieldRequired = true;
        useUpsert.getField('appliedTowbarScenarios').set('label', 'Towbar Required For*');
        return;
      }
    } else {
      passengerLogisticsStore.towBarFieldRequired = false;
      useUpsert.getField('appliedTowbarScenarios').set('label', 'Towbar Required For');
    }
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    useUpsert.getField(fieldKey).set(value);
    switch (fieldKey) {
      case 'towbarRequired':
        const towBarRequiredForField = useUpsert.getField('appliedTowbarScenarios').value;
        if (!value) {
          passengerLogisticsStore.towBarFieldRequired = false;
          useUpsert.getField('appliedTowbarScenarios').set('label', 'Towbar Required For');
          return;
        }
        if (value.id === 3) {
          if (towBarRequiredForField.length !== 0) {
            passengerLogisticsStore.towBarFieldRequired = false;
            useUpsert.getField('appliedTowbarScenarios').set('label', 'Towbar Required For');
          } else {
            passengerLogisticsStore.towBarFieldRequired = true;
            useUpsert.getField('appliedTowbarScenarios').set('label', 'Towbar Required For*');
          }
        } else {
          passengerLogisticsStore.towBarFieldRequired = false;
          useUpsert.getField('appliedTowbarScenarios').set('label', 'Towbar Required For');
        }
        break;
      case 'appliedTowbarScenarios':
        const towBarRequiredField = useUpsert.getField('towbarRequired').value;
        if (towBarRequiredField.id === 3) {
          if (value?.length !== 0) {
            passengerLogisticsStore.towBarFieldRequired = false;
            useUpsert.getField('appliedTowbarScenarios').set('label', 'Towbar Required For');
          } else {
            passengerLogisticsStore.towBarFieldRequired = true;
            useUpsert.getField('appliedTowbarScenarios').set('label', 'Towbar Required For*');
          }
          useUpsert.getField('appliedTowbarScenarios').set('label', 'Towbar Required For*');
        } else {
          passengerLogisticsStore.towBarFieldRequired = false;
          useUpsert.getField('appliedTowbarScenarios').set('label', 'Towbar Required For');
        }
        break;
      default:
        break;
    }
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      case 'aircraftParkingDistanceFBO':
        const aircraftParkingDistanceFBOList = settingsStore.aircraftParkingDistanceFBO.filter(item => {
          return item.label?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setAircraftParkingDistanceFBO(aircraftParkingDistanceFBOList);
        break;
      case 'appliedAircraftParkingOptions':
        const appliedAircraftParkingOptionsList = settingsStore.aircraftParkingOptions.filter(item => {
          return item.label?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setAppliedAircraftParkingOptionsList(appliedAircraftParkingOptionsList);
        break;
      case 'appliedAircraftSpotAccommodation':
        const appliedAircraftSpotAccommodationList = settingsStore.aircraftSpotAccommodation.filter(item => {
          return item.label?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setAppliedAircraftSpotAccommodationList(appliedAircraftSpotAccommodationList);
        break;
      case 'hangerAvailableUom':
        const hangerAvailableUomList = settingsStore.hangerAvailableUom.filter(item => {
          return item.label?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setHangerAvailableUomList(hangerAvailableUomList);
        break;
      case 'appliedTowbarScenarios':
        const appliedTowbarScenariosList = settingsStore.towbarScenarios.filter(item => {
          return item.label?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setAppliedTowbarScenariosList(appliedTowbarScenariosList);
        break;
      case 'hangarAvailable':
        const hangarAvailableList = PassengerLogisticsModel.getYesNo().filter(item => {
          return item.name?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setYesOrNoListList(hangarAvailableList);
        break;
      case 'towbarRequired':
        const towbarRequiredList = PassengerLogisticsModel.getYesNo().filter(item => {
          return item.name?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setYesOrNoListList(towbarRequiredList);
        break;
      case 'appliedInternationalArrivalProcedures':
        const arrivalProceduresList = settingsStore.internationalArrivalProcedures.filter(item => {
          return item.name?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setAppliedInternationalArrivalProceduresList(arrivalProceduresList);
        break;
      default:
        break;
    }
    return;
  };

  const openPopupModel = fieldKey => {
    ModalStore.open(
      <InformationDialog
        dialogWrapperWidth={classes.dialogContainerWidth}
        title="Guideline on 'Towbar Required For' options"
        message={<GuideLineInformationData />}
      />
    );
  };

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: 'Aircraft Logistics:',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'aircraftParkingField',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'aircraftParkingDistanceFBO',
            type: EDITOR_TYPES.DROPDOWN,
            options: aircraftParkingDistanceFBO,
            isHalfFlex: true,
          },
          {
            fieldKey: 'appliedAircraftParkingOptions',
            type: EDITOR_TYPES.AUTO_COMPLETE,
            options: passengerLogisticsStore.getOperationalInsightsSettingOptions<SettingBaseModel>(
              appliedAircraftParkingOptions,
              'aircraftParkingOptions'
            ),
            multiple: true,
            isFullFlex: true,
            disableCloseOnSelect: true,
          },
          {
            fieldKey: 'appliedAircraftSpotAccommodation',
            type: EDITOR_TYPES.AUTO_COMPLETE,
            options: passengerLogisticsStore.getOperationalInsightsSettingOptions<SettingBaseModel>(
              appliedAircraftSpotAccommodation,
              'aircraftSpotAccommodation'
            ),
            multiple: true,
            isFullFlex: true,
            disableCloseOnSelect: true,
            customLabel: field => {
              return (
                <div className={classes.flexIcon}>
                  <span>Aircraft Spot Accommodation</span>
                  <Tooltip title="" placement="top">
                    <HelpIcon
                      className={classes?.imageIcon}
                      onClick={() => openPopupModel('Applied Aircraft Accommodation')}
                    />
                  </Tooltip>
                </div>
              );
            },
          },
          {
            fieldKey: 'appliedInternationalArrivalProcedures',
            type: EDITOR_TYPES.AUTO_COMPLETE,
            options: passengerLogisticsStore.getOperationalInsightsSettingOptions<SettingBaseModel>(
              appliedInternationalArrivalProceduresList,
              'arrivalProcedures'
            ),
            isFullFlex: true,
            multiple: true,
            disableCloseOnSelect: true,
          },
          {
            fieldKey: 'hangarAvailable',
            type: EDITOR_TYPES.DROPDOWN,
            options: yesOrNoList,
            isHalfFlex: true,
          },
          {
            fieldKey: 'hangarAvailableSpace',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'hangerAvailableUom',
            type: EDITOR_TYPES.DROPDOWN,
            options: hangerAvailableUom,
            isHalfFlex: true,
          },
          {
            fieldKey: 'towbarRequired',
            type: EDITOR_TYPES.DROPDOWN,
            options: PassengerLogisticsModel.getYesNoSometimes(),
            isHalfFlex: true,
          },
          {
            fieldKey: 'appliedTowbarScenarios',
            type: EDITOR_TYPES.AUTO_COMPLETE,
            options: passengerLogisticsStore.getOperationalInsightsSettingOptions<SettingBaseModel>(
              appliedTowbarScenarios,
              'towbarScenarios'
            ),
            multiple: true,
            isFullFlex: true,
            disableCloseOnSelect: true,
            customErrorMessage:
              passengerLogisticsStore.towBarFieldRequired && 'This Towbar Required For* field is required.',
            customLabel: field => {
              return (
                <div className={classes.flexIcon}>
                  <span>
                    {useUpsert.getField('towbarRequired').value?.id === 3
                      ? 'Towbar Required For*'
                      : 'Towbar Required For'}
                  </span>
                  <Tooltip title="" placement="top">
                    <HelpIcon className={classes?.imageIcon} onClick={() => openPopupModel('Towbar Required For')} />
                  </Tooltip>
                </div>
              );
            },
          },
          {
            fieldKey: 'towbarRequirement',
            type: EDITOR_TYPES.TEXT_FIELD,
            isFullFlex: true,
          },
        ],
      },
    ];
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      case '':
        break;
      default:
        break;
    }
  };

  const headerActions = (): ReactNode => {
    return (
      <>
        <Typography variant="h5">Available Handler Facilities at Airport</Typography>
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => navigate('/vendor/locations')}
              disabled={!formRef.changed}
              size="large"
            >
              Cancel
            </Button>
          </div>
          <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={
                !formRef.isValid || formRef.hasError || !formRef.changed || passengerLogisticsStore.towBarFieldRequired
              }
              onClick={() => upsertVendorLocationAircraftLogistics()}
            >
              Save
            </Button>
          </div>
        </Box>
      </>
    );
  };

  const upsertVendorLocationAircraftLogistics = (): void => {
    const request = new PassengerLogisticsModel({ ...useUpsert.form.values() });
    UIStore.setPageLoader(true);
    passengerLogisticsStore
      ?.upsertVendorLocationOperationalInsights(
        request.serialize(parseInt(RootDataStore.locationData.locationId)),
        true
      )
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: PassengerLogisticsModel) => {
          useUpsert.form.reset();
          useUpsert.setFormValues(response);
          setFormValues(response);
        },
        error: error => {
          if (error?.message) {
            useUpsert.showAlert(error?.message, request.id.toString());
            return;
          }
          errorHandler(error.response?.data?.errors, request.id.toString());
        },
      });
  };

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
  };

  return (
    <>
      <ConfirmNavigate isBlocker={formRef.changed}>
        <DetailsEditorWrapper
          headerActions={headerActions()}
          isEditMode={true}
          classes={{ headerActions: classes.headerActions }}
        >
          <div className={classes.editorWrapperContainer}>
            <ViewInputControls
              isEditable={true}
              groupInputControls={groupInputControls()}
              onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
              onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
              field={fieldKey => useUpsert.getField(fieldKey)}
              onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
              onFocus={fieldKey => onFocus(fieldKey)}
            />
          </div>
        </DetailsEditorWrapper>
      </ConfirmNavigate>
    </>
  );
};

export default inject(
  'settingsStore',
  'vendorLocationStore',
  'passengerLogisticsStore'
)(withStyles(styles)(observer(AircraftLogistics)));
