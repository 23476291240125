import React, { FC, useEffect, useState } from 'react';
import { useBaseUpsertComponent } from '@uplink/shared';
import { IAPISearchFiltersDictionary, IClasses } from '@wings-shared/core';
import { inject, observer } from 'mobx-react';
import { Accordion, AccordionDetails, AccordionSummary, Typography, withStyles } from '@material-ui/core';
import { styles } from './CustomAccordion.style';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

interface Props {
  classes?: IClasses;
  panelName:string;
  panelHeading: string;
  panelContent: any;
  panelBodyHeading: string;
}

const CustomAccordion: FC<Props> = ({
  classes,
  panelName,
  panelHeading,
  panelContent,
  panelBodyHeading
}) => {
  const [ expanded, setExpanded ] = React.useState(true);
    
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  
  useEffect(() => {
    
  }, []);

  return (
    
    <div className={classes.editorWrapperContainer}>
      <Accordion expanded={expanded} onChange={handleChange(panelName)}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosIcon />}
          aria-controls={panelContent}
          id={panelHeading}
        >
          <Typography className={classes.heading}>{panelHeading}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {
            panelBodyHeading ? 
              <Typography component={'h3'} className={classes.secondaryHeading}>
                {panelBodyHeading}
              </Typography>
              :
              ''
          }
          <Typography>
            {panelContent}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default inject(
  
)(withStyles(styles)(observer(CustomAccordion)));