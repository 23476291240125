import { createStyles, Theme } from '@material-ui/core';

export const styles = createStyles((theme: Theme) => ({
  headerActions: {
    justifyContent: 'space-between',
    borderBottom: '1px solid #D3D3D3',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    '& button': {
      width: '150px',
      marginRight: theme.spacing(1),
    },
    '& h5': {
      fontSize: '1.625rem',
      // color: theme.palette.grey.A700
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  customHeight: {
    height: 'calc(100vh - 290px)',
  },
  editorWrapperContainer: {
    overflow: 'auto',
    '& .MuiAutocomplete-input': {
      color: '#7C7C7C',
    },
  },
  gridHeight: {
    paddingBottom: '70px',
  },
  defaultButton: {
    marginLeft: '10px',
    '& button': {
      border: '1px solid #1976D2',
      padding: '4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: '400',
      maxWidth: '140px',
      textTransform: 'capitalize',
      color: '#1976D2',
    },
  },
  customButton: {
    '& button': {
      border: '1px solid #1976D2',
      padding: '4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: '400',
      width: '100px',
      textTransform: 'capitalize',
      color: '#1976D2',
    },
  },
  primaryButton: {
    '& button': {
      background: '#1976D2',
      color: '#fff',
    },
    '& button:hover': {
      backgroundColor: '#63A4FF',
      boxShadow: 'none',
      border: 'none'
    },
    '& button:disabled': {
      background: '#afafaf',
      border: 'none',
    },
  },
  fileBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    backgroundColor: '#F1F1F1',
    borderRadius: '4px',
    alignItems: 'center',
    marginTop: theme.spacing(3),
  },
  fileName: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    color: '#000000',
    minHeight: '50px',
    '& .MuiSvgIcon-root': {
      color: '#1976D2',
    },
    '& .MuiSvgIcon-root:hover': {
      opacity: 1,
      cursor: 'pointer',
    },
  },
}));
