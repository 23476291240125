import { ENVIRONMENT_VARS, EnvironmentVarsStore } from '@uplink-shared/env-store';
import { baseApiPath, HttpClient } from '@uplink/shared';
import { vendorManagementHeaders } from './Base.store';
import { Observable } from 'rxjs';
import { action, observable } from 'mobx';
import { IAPIPageResponse, IAPIPascalResponse, Utilities } from '@wings-shared/core';
import { apiUrls } from './API.url';
import { AuthStore, Logger } from '@uplink-shared/security';
import { UserGroupModel } from '../Modules/Shared';
import { IAPIUserGroupsResponse } from '../Modules/Shared/Interfaces/Response/API-user-groups-response.interface';
import { map } from 'rxjs/operators';
import { IAPIRequestVendorUser } from '../Modules/Shared/Interfaces/Request/API-Request-VendorUser.interface';
import { IAPIResponseVendorUser } from '../Modules/Shared/Interfaces/Response/API-Response-VendorUser';
import { VendorUserModel } from '../Modules/Shared/Models/VendorUser.model';
import { UserModel } from '../Modules/Shared/Models/User.model';

export class UserManagementStore {
    private env = new EnvironmentVarsStore();
    @observable public uvGoAccessToken:string = '';
    @observable public uplinkOktaGroups: UserGroupModel[] = [];
    @observable public isDataAvailable: boolean = false;

    @action
    public getUvGoAccessToken() {
      const http: HttpClient = new HttpClient({
        headers: vendorManagementHeaders,
      });
      return http.post(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.getUvGoAccessToken}?uplinkUIJwt=${AuthStore.user.accessToken}`)
        .pipe(
          Logger.observableCatchError,
          map(response => 
          {
            this.uvGoAccessToken=response.accessToken;
            return response;
          })
        )
        .subscribe();
    }

  
  @action
    public loadUplinkOktaGroups(query?: string): Observable<UserGroupModel[]> {
      vendorManagementHeaders['Authorization'] = `bearer ${this.uvGoAccessToken}`
      const http: HttpClient = new HttpClient({
        baseURL: baseApiPath.userManagement,
        headers: vendorManagementHeaders,
      });
      const params = Utilities.buildParamString({ query });
      return http.get<IAPIUserGroupsResponse>(`${baseApiPath.userManagement}/${apiUrls.oktaGroups}?${params}`).pipe(
        Logger.observableCatchError,
        map((response: IAPIUserGroupsResponse) =>{
          Utilities.customArraySort<UserGroupModel>(UserGroupModel.deserializeList(response.Data), 'name');
          this.uplinkOktaGroups = UserGroupModel.deserializeList(response.Data);
          this.uplinkOktaGroups = this.uplinkOktaGroups.filter(g=>
            g.name==='app.uplink.informationambassador' );
          return { results: this.uplinkOktaGroups };
        })
      );
    }

  @action
  public checkIfUserExist(
    request?: IAPIRequestVendorUser, emailInputValue?:string
  ): Observable<IAPIResponseVendorUser> {
    vendorManagementHeaders['Authorization'] = `bearer ${this.uvGoAccessToken}`
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.userManagement,
      headers: vendorManagementHeaders,
    });
    const params = Utilities.buildParamString({
      ...request,
    });
    return http.get<IAPIPascalResponse<IAPIResponseVendorUser>>(`${apiUrls.createNewUser}?${params}`).pipe(
      Logger.observableCatchError,
      map(response => (
        {
          ...response,
          results: VendorUserModel.deserializeList(response.Data.Results),
        }
      ))
    );
  }

  public generateTempPassword(userEmail: string): Observable<string> {
    vendorManagementHeaders['Authorization'] = `bearer ${this.uvGoAccessToken}`
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.userManagement,
      headers: vendorManagementHeaders,
    });
    const payload = {
      userId : userEmail
    }
    
    return http.put<IAPIPageResponse<string>>(`${apiUrls.createNewUser}/${userEmail}/expirepassword`, payload).pipe(
      Logger.observableCatchError,
      map(response => response.Data)
    );
  }

  public createNewUser(data: UserModel,userName: string): Observable<string> {
    vendorManagementHeaders['Authorization'] = `bearer ${this.uvGoAccessToken}`
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.userManagement,
      headers: vendorManagementHeaders,
    });
    const { csdId, email, firstName, lastName, password, groupIds, preferences } = data;
    const payload = {
      CSDUserId: csdId,
      Email: email,
      UserName: userName || email,
      FirstName: firstName,
      LastName: lastName,
      Password: password,
      Preferences: preferences,
      GroupIds: groupIds,
      SendActivationEmail: false,
      GenerateTempPassword: true,
    };
    return http.post<IAPIPageResponse<string>>(apiUrls.createNewUser, payload).pipe(
      Logger.observableCatchError,
      map(response => response.Data)
    );
  }

  


}
