import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './CustomHeader.style';

const CustomHeader = (props)=>{
  const classes = useStyles();
  return(
    <div className={classes.mainHeader}>
      <Typography variant="h5" className="mainTitle">
        {props.title}
      </Typography>
    </div>
  )
}
export default CustomHeader;