export enum FIELD_NAMES {
    OperationType = 'OperationType',
    ProvideCoordination = 'ProvideCoordination',
    CommsCopyFor = 'CommsCopyFor',
    SupervisoryAgentAvailableOnRequest = 'SupervisoryAgentAvailableOnRequest',
    EndDate = 'EndDate',
    StartDate = 'StartDate',
    AgentDispatchedFrom = 'AgentDispatchedFrom',
    CoordinatingOffice = 'CoordinatingOffice'
}

export const FIELD_NAMES_MAPPING = [
  {
    key:'appliedOperationType',
    apiFieldName:'OperationType',
    entityName:'OperationalEssential',
    refrentialFieldName:'operationTypeId',
    refrentialEntityName:'operationType',
    isJSONObject:true,
    isListType:true
  },
  {
    key:'coordinatingOffice',
    apiFieldName:'CoordinatingOffice',
    entityName:'OperationalEssential',
    refrentialFieldName:'coordinatingOfficeId',
    isSendReferenceId:true
  },
  {
    key:'provideCoordinationFor',
    apiFieldName:'ProvideCoordination',
    entityName:'OperationalEssential',
    refrentialFieldName:'vendorLocationId',
    refrentialEntityName:'vendorLocation',
    isJSONObject:true,
    isListType:true
  },
  {
    key:'commsCopyFor',
    apiFieldName:'CommsCopyFor',
    entityName:'OperationalEssential',
    refrentialFieldName:'vendorLocationId',
    refrentialEntityName:'vendorLocation',
    isJSONObject:true,
    isListType:true
  },
  {
    key:'appliedCreditAvailable',
    apiFieldName:'CreditAvailable',
    entityName:'OperationalEssential',
    refrentialFieldName:'creditAvailableId',
    refrentialEntityName:'creditAvailable',
    isJSONObject:true,
    isListType:true
  },
  {
    key:'appliedPaymentOptions',
    apiFieldName:'PaymentOptions',
    entityName:'OperationalEssential',
    refrentialFieldName:'paymentOptionsId',
    refrentialEntityName:'paymentOptions',
    isJSONObject:true,
    isListType:true
  },
  {
    key:'appliedMainServicesOffered',
    apiFieldName:'MainServicesOffered',
    entityName:'OperationalEssential',
    refrentialFieldName:'mainServicesOfferedId',
    refrentialEntityName:'mainServicesOffered',
    isJSONObject:true,
    isListType:true
  },
  {
    key:'airToGroundFrequency',
    apiFieldName:'AirToGroundFrequency',
    entityName:'OperationalEssential'
  },
  {
    key:'locationAirfield',
    apiFieldName:'LocationOnAirfield',
    entityName:'OperationalEssential'
  },
  {
    key:'managerName',
    apiFieldName:'ManagerName',
    entityName:'OperationalEssential'
  },
  {
    key:'asstManagerName',
    apiFieldName:'AsstManagerName',
    entityName:'OperationalEssential'
  },
  {
    key:'startDate',
    apiFieldName:'StartDate',
    entityName:'OperationalEssential'
  },
  {
    key:'endDate',
    apiFieldName:'EndDate',
    entityName:'OperationalEssential'
  }
]