import React, { FC, useEffect, useState } from 'react';
import { IAPIGridRequest, IClasses, UIStore } from '@wings-shared/core';
import { inject, observer } from 'mobx-react';
import { Box, withStyles } from '@material-ui/core';
import { CustomAccordion, AccordionRadioGroups, SETTING_ID, LocationHoursModel } from '../../../../../Shared';
import MainOperationalHours from './MainOperationalHours/MainOperationalHours';
import { SettingsStore, VendorLocationHoursStore, VendorLocationStore } from '../../../../../../Stores';
import { useGridState } from '@uplink-shared/custom-ag-grid';
import { RootDataStore } from '@uplink-shared/layout';
import { finalize, takeUntil, catchError, mergeMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { AlertStore } from '@uvgo-shared/alert';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { useUnsubscribe } from '@wings-shared/hooks';
import { styles } from '../../AirportHours.styles';
import { NO_SQL_COLLECTIONS } from '@uplink/shared';
import moment from 'moment';

interface Props {
  classes?: IClasses;
  vendorLocationStore: VendorLocationStore;
  vendorLocationHoursStore: VendorLocationHoursStore;
  settingsStore: SettingsStore;
  onNextButtonDisable?: (boolean) => void;
  registerSaveData: (saveData: () => void) => void;
}

const dayNames = [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ];

interface TimeRange {
  id: string;
  hoursId: number;
  scheduleId: number;
  patternedRecurrenceId: number;
  startTime: string;
  endTime: string;
  is24Hours: boolean;
  isNew?: boolean;
  sequence?: number;
  hoursTypeId?: number;
  statusId?: number;
  accessLevelId?: number;
  startDate?: Date;
  endDate?: Date;
  includeHoliday?: boolean;
  dayOfWeekId?: number;
  patternedRecurrenceDaysofWeekId?: number;
  active?: boolean;
}

const MainTerminalOnly: FC<Props> = ({
  classes,
  vendorLocationStore,
  settingsStore,
  onNextButtonDisable,
  registerSaveData,
  vendorLocationHoursStore,
}) => {
  const [ isQuarantineHoursYes, setIsQuarantineHoursYes ] = useState(false);
  const [ ciqRadio, setCiqRadio ] = useState(11);
  const gridState = useGridState();
  const unsubscribe = useUnsubscribe();

  const dayOfWeekIds: { [key: string]: number } = {
    Sunday: 1,
    Monday: 2,
    Tuesday: 3,
    Wednesday: 4,
    Thursday: 5,
    Friday: 6,
    Saturday: 7,
  };

  const loadSettingHoursId = () => {
    settingsStore?.getSettings( SETTING_ID.SETTING_AIRPORT_HOURS_TYPE).subscribe();
    settingsStore?.getSettings( SETTING_ID.SETTING_AIRPORT_HOURS_SUB_TYPE).subscribe();
    const typeRequest: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Name',
          propertyValue: 'Overtime',
        },
      ]),
    };
    const valueRequest: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Name',
          propertyValue: 'On Request',
        },
      ]),
    };
    const operatorRequest: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Operator',
          propertyValue: '=',
        },
      ]),
    };
    settingsStore
      .getAirportHoursConditionTypeSettings(NO_SQL_COLLECTIONS.AIRPORT_CONDITION_TYPE, typeRequest)
      .subscribe();
    settingsStore
      .getAirportHoursConditionTypeSettings(NO_SQL_COLLECTIONS.AIRPORT_CONDITION_VALUE, valueRequest)
      .subscribe();
    settingsStore
      .getAirportHoursConditionTypeSettings(NO_SQL_COLLECTIONS.AIRPORT_CONDITIONAL_OPERATOR, operatorRequest)
      .subscribe();
  };

  const saveData = () => {
    if (vendorLocationHoursStore.quarantineAirportHoursList.length === 0) return;

    const hoursTypeId = settingsStore.airportHoursType.filter(item => {
      return item.name === 'CIQ';
    });

    const hoursSubTypeId = settingsStore.airportHoursSubType.filter(item => {
      return item.name === 'Main Terminal';
    });
    UIStore.setPageLoader(true);
    vendorLocationHoursStore
      ?.updateAirportHours(
        LocationHoursModel.airportHoursSerializeList(
          VendorLocationHoursStore.overTimeHoursData,
          hoursTypeId[0].id || 4,
          hoursSubTypeId[0].id || 20,
          vendorLocationStore.overTimeValue === 1,
          settingsStore.airportHoursConditionType[0].conditionTypeId,
          settingsStore.airportHoursConditionValue[0].overtimeId,
          settingsStore.airportHoursConditionOperator[0].conditionalOperatorId
        )
      )
      .subscribe(response => {
        UIStore.setPageLoader(false);
        loadQuarantineData();
        vendorLocationStore.isHoursValueChange = false;
      });
  };

  useEffect(() => {
    registerSaveData(saveData);
    return () => registerSaveData(null);
  }, []);

  useEffect(() => {
    vendorLocationStore.isHoursValueChange = false;
    loadSettingHoursId();
    if (isQuarantineHoursYes) {
      loadQuarantineData();
    }
    loadMainTerminalData();
  }, [ isQuarantineHoursYes ]);

  useEffect(() => {
    onNextButtonDisable(
      !vendorLocationHoursStore.overTimeHoursData ||
        vendorLocationHoursStore.quarantineAirportHoursList.length === 0 ||
        !vendorLocationStore.isHoursValueChange
    );
  }, [
    vendorLocationStore.overTimeValue,
    vendorLocationHoursStore.quarantineAirportHoursList,
    vendorLocationStore.isHoursValueChange,
  ]);

  const loadMainTerminalData = () => {
    vendorLocationStore.isTimeChanged = false;
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'AirportReference.Id',
          propertyValue: RootDataStore.locationData.airportReferenceId,
        },
        {
          propertyName: 'AirportHoursType.Name',
          propertyValue: 'Operational',
          operator: 'and',
        },
        {
          propertyName: 'AirportHoursScheduleType.Name',
          propertyValue: 'Recurrence',
          operator: 'and',
        },
        {
          propertyName: 'AirportHoursSubType.Name',
          propertyValue: 'Main Terminal',
          operator: 'and',
        },
      ]),
    };
    vendorLocationHoursStore.getAirportHours(request).subscribe(response => {
      UIStore.setPageLoader(false);
      ModalStore.close();
    });
  };

  const loadQuarantineData = () => {
    vendorLocationStore.isTimeChanged = false;
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'AirportReference.Id',
          propertyValue: RootDataStore.locationData.airportReferenceId,
        },
        {
          propertyName: 'AirportHoursType.Name',
          propertyValue: 'CIQ',
          operator: 'and',
        },
        {
          propertyName: 'AirportHoursScheduleType.Name',
          propertyValue: 'Recurrence',
          operator: 'and',
        },
        {
          propertyName: 'AirportHoursSubType.Name',
          propertyValue: 'Main Terminal',
          operator: 'and',
        },
      ]),
    };
    vendorLocationHoursStore.getAirportHours(request, true).subscribe(response => {
      UIStore.setPageLoader(false);
      formatHoursData();
      ModalStore.close();
      if (vendorLocationHoursStore.quarantineAirportHoursList.length === 0) {
        vendorLocationStore.overTimeValue = 2;
        return;
      }
      if (response.results && response.results[0]?.airportHoursCondition !== null) {
        vendorLocationStore.overTimeValue = 1;
      } else {
        vendorLocationStore.overTimeValue = 2;
      }
    });
  };

  const quarantineHoursData = [
    { id: 11, value: 'no', label: 'No, CIQ is available all times the Main Terminal is open' },
    { id: 12, value: 'yes', label: 'Yes, they have limited hours' },
    { id: 13, value: 'n/a', label: 'N/A' },
  ];

  const ciqOvertimeData = [
    { id: 1, value: 'yes', label: 'Yes' },
    { id: 2, value: 'no', label: 'No' },
  ];

  const handleRadioChange = value => {
    setCiqRadio(value);
    if (value === 12) {
      setIsQuarantineHoursYes(true);
    } else {
      setIsQuarantineHoursYes(false);
    }
  };

  const errorHandler = (errors: { [key: string]: string[] }): void => {
    Object.keys(errors).forEach(key => {
      const errorMessages = errors[key];
      errorMessages.forEach(message => {
        AlertStore.info(message);
      });
    });
  };

  const handleErrorResponse = error => {
    if (error.response?.data?.errors) {
      errorHandler(error.response?.data?.errors);
      return;
    }
    if (error?.message) {
      AlertStore.info(error?.message);
    }
  };

  const handleSave = () => {
    const hoursIdZeroData = vendorLocationHoursStore.updatedHoursData.filter(item => item.id === 0);
    const hoursIdOtherData = vendorLocationHoursStore.updatedHoursData.filter(item => item.id !== 0);

    const ids =
      vendorLocationHoursStore.airportHoursList && vendorLocationHoursStore.airportHoursList.map(item => item.id);
    if (hoursIdZeroData.length === 0 && hoursIdOtherData.length === 0) {
      deleteAllRecords(ids);
      return;
    }
    if (hoursIdOtherData.length === 0) {
      deleteAllRecords(ids);
    }
    const hoursTypeId = settingsStore.airportHoursType.filter(item => {
      return item.name === 'Operational';
    });

    const hoursSubTypeId = settingsStore.airportHoursSubType.filter(item => {
      return item.name === 'Main Terminal';
    });

    const apiCalls = [];

    UIStore.setPageLoader(true);
    apiCalls.push(
      vendorLocationHoursStore
        ?.updateAirportHours(
          LocationHoursModel.airportHoursSerializeList(
            hoursIdOtherData,
            hoursTypeId[0].id || 1,
            hoursSubTypeId[0].id || 20,
            false,
            settingsStore.airportHoursConditionType[0].conditionTypeId,
            settingsStore.airportHoursConditionValue[0].overtimeId
          )
        )
        .pipe(
          mergeMap(() => {
            return forkJoin(
              vendorLocationHoursStore?.addAirportHours(
                LocationHoursModel.airportHoursSerializeList(
                  hoursIdZeroData,
                  hoursTypeId[0].id || 1,
                  hoursSubTypeId[0].id || 20,
                  false,
                  settingsStore.airportHoursConditionType[0].conditionTypeId,
                  settingsStore.airportHoursConditionValue[0].overtimeId
                )
              )
            );
          })
        )
        .pipe(
          catchError(error => {
            handleErrorResponse(error);
            return of(null);
          })
        )
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe(responses => {
          const allSuccessful = responses && responses.every(response => response !== null);

          if (allSuccessful) {
            AlertStore.info('Airport Hours saved successfully!');
            if (isQuarantineHoursYes) {
              loadQuarantineData();
            }
            loadMainTerminalData();
            ModalStore.close();
          }
        })
    );
  };

  const deleteAllRecords = (ids: number[]) => {
    if (ids.length === 0) {
      ModalStore.close();
      return;
    }
    UIStore.setPageLoader(true);
    vendorLocationHoursStore
      ?.deleteAirportHours(ids)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: LocationHoursModel[]) => {
          AlertStore.info('Airport Hours saved successfully!');
          if (isQuarantineHoursYes) {
            loadQuarantineData();
          }
          loadMainTerminalData();
          ModalStore.close();
        },
        error: error => {
          handleErrorResponse(error);
        },
      });
  };

  function convertToISOFormat(time: string) {
    if (moment(time, 'HH:mm', true).isValid()) {
      const [ hours, minutes ] = time.split(':');
      const date = new Date();
      date.setUTCHours(hours, minutes, 0, 0);
      return date.toISOString();
    }
  }

  const groupDaysByTimeRange = (editableTimeData: { [key: string]: TimeRange[] }) => {
    const groupedTimeData: { [key: string]: any } = {};

    for (const day in editableTimeData) {
      if (editableTimeData.hasOwnProperty(day)) {
        editableTimeData[day].forEach(
          ({
            hoursId,
            sequence,
            hoursTypeId,
            statusId,
            accessLevelId,
            startTime,
            endTime,
            patternedRecurrenceDaysofWeekId,
            startDate,
            endDate,
            is24Hours,
            includeHoliday,
            patternedRecurrenceId,
            scheduleId,
          }) => {
            const timeKey = `${startTime}-${endTime}`;

            if (groupedTimeData[timeKey]) {
              groupedTimeData[
                timeKey
              ].scheduleRequest.patternedRecurrenceRequest.patternedRecurrenceDaysofWeekRequest.push({
                id: patternedRecurrenceDaysofWeekId,
                dayOfWeekId: dayOfWeekIds[day],
              });
            } else {
              groupedTimeData[timeKey] = {
                id: hoursId,
                userId: '',
                vendorLocationId: RootDataStore.locationData.locationId,
                sequence,
                hoursTypeId,
                statusId,
                accessLevelId,
                hoursScheduleTypeId: 1,
                scheduleRequest: {
                  id: scheduleId,
                  startTime: convertToISOFormat(startTime),
                  endTime: convertToISOFormat(endTime),
                  startDate,
                  endDate,
                  is24Hours: Boolean(
                    moment(startTime)
                      .utc()
                      .format('HH:mm') === '00:01' &&
                      moment(endTime)
                        .utc()
                        .format('HH:mm') === '23:59'
                  ),
                  includeHoliday,
                  patternedRecurrenceRequest: {
                    id: patternedRecurrenceId,
                    patternedRecurrenceDaysofWeekRequest: [
                      {
                        id: 0,
                        dayOfWeekId: dayOfWeekIds[day],
                      },
                    ],
                  },
                },
              };
            }
          }
        );
      }
    }

    const newState = Object.values(groupedTimeData);
    return newState;
  };

  const formatHoursData = () => {
    const updatedTimeData: { [key: string]: TimeRange[] } = dayNames.reduce((acc, day) => {
      acc[day] = [];
      return acc;
    }, {} as { [key: string]: TimeRange[] });

    vendorLocationHoursStore.quarantineAirportHoursList.forEach(item => {
      const { schedule, id: hoursId } = item;
      const { patternedRecurrence } = schedule;
      patternedRecurrence.patternedRecurrenceDaysofWeek.forEach(day => {
        const dayName = day.dayOfWeek.name;
        if (updatedTimeData[dayName]) {
          const startTime = new Date(schedule.startTime).toISOString().slice(11, 16);
          const endTime = new Date(schedule.endTime).toISOString().slice(11, 16);

          updatedTimeData[dayName].push({
            id: `original-${dayName}-${startTime}-${endTime}`,
            startTime,
            endTime,
            is24Hours: Boolean(
              moment(schedule.startTime)
                .utc()
                .format('HH:mm') === '00:01' &&
                moment(schedule.endTime)
                  .utc()
                  .format('HH:mm') === '23:59'
            ),
            hoursId,
            scheduleId: schedule.id,
            patternedRecurrenceId: patternedRecurrence.id,
            isNew: false,
            sequence: item.sequence,
            hoursTypeId: item.hoursType.id,
            statusId: item.status.id,
            accessLevelId: item.accessLevel.id,
            startDate: schedule.startDate,
            endDate: schedule.endDate,
            includeHoliday: schedule.includeHoliday,
            dayOfWeekId: dayOfWeekIds[dayName],
            patternedRecurrenceDaysofWeekId: day.id,
            active: true,
          });
        }
      });
    });
    VendorLocationHoursStore.overTimeHoursData = groupDaysByTimeRange(updatedTimeData).filter(item => {
      const startTime = moment(item.scheduleRequest.startTime)
        .utc()
        .format('HH:mm');
      const endTime = moment(item.scheduleRequest.endTime)
        .utc()
        .format('HH:mm');
      return !(startTime === '00:00' && endTime === '00:00') && item.id !== 0;
    });
  };

  const handleSaveCIQ = () => {
    const hoursIdZeroData = vendorLocationHoursStore.updatedHoursData?.filter(item => item.id === 0);
    const hoursIdOtherData = vendorLocationHoursStore.updatedHoursData?.filter(item => item.id !== 0);
    const ids =
      vendorLocationHoursStore.quarantineAirportHoursList &&
      vendorLocationHoursStore.quarantineAirportHoursList.map(item => item.id);

    if (hoursIdZeroData.length === 0 && hoursIdOtherData.length === 0) {
      deleteAllRecords(ids);
      return;
    }
    if (hoursIdOtherData.length === 0) {
      deleteAllRecords(ids);
    }

    const hoursTypeId = settingsStore.airportHoursType.filter(item => {
      return item.name === 'CIQ';
    });

    const hoursSubTypeId = settingsStore.airportHoursSubType.filter(item => {
      return item.name === 'Main Terminal';
    });

    const apiCalls = [];

    UIStore.setPageLoader(true);
    apiCalls.push(
      vendorLocationHoursStore
        ?.updateAirportHours(
          LocationHoursModel.airportHoursSerializeList(
            hoursIdOtherData,
            hoursTypeId[0].id || 4,
            hoursSubTypeId[0].id || 20,
            vendorLocationStore.overTimeValue === 1,
            settingsStore.airportHoursConditionType[0].conditionTypeId,
            settingsStore.airportHoursConditionValue[0].overtimeId,
            settingsStore.airportHoursConditionOperator[0].conditionalOperatorId
          )
        )
        .pipe(
          mergeMap(() => {
            return forkJoin(
              vendorLocationHoursStore?.addAirportHours(
                LocationHoursModel.airportHoursSerializeList(
                  hoursIdZeroData,
                  hoursTypeId[0].id || 4,
                  hoursSubTypeId[0].id || 20,
                  vendorLocationStore.overTimeValue === 1,
                  settingsStore.airportHoursConditionType[0].conditionTypeId,
                  settingsStore.airportHoursConditionValue[0].overtimeId,
                  settingsStore.airportHoursConditionOperator[0].conditionalOperatorId
                )
              )
            );
          })
        )
        .pipe(
          catchError(error => {
            handleErrorResponse(error);
            return of(null);
          })
        )
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe(responses => {
          const allSuccessful = responses && responses.every(response => response !== null);
          if (allSuccessful) {
            AlertStore.info('Airport Hours saved successfully!');
            if (isQuarantineHoursYes) {
              loadQuarantineData();
            }
            loadMainTerminalData();
            ModalStore.close();
          }
        })
    );
  };

  const onOvertimeValueChange = (value: number) => {
    vendorLocationStore.isHoursValueChange = true;
    vendorLocationStore.overTimeValue = value;
  };

  return (
    <div className={classes.mainTerminalOnlyWrapper}>
      <Box>
        <CustomAccordion
          panelName="mainOperationalHours"
          panelHeading="Main Terminal - Operational Hours (in local time)"
          panelContent={
            <MainOperationalHours
              handleSave={handleSave}
              locationHoursList={vendorLocationHoursStore.airportHoursList}
            />
          }
        />
      </Box>
      <Box>
        <CustomAccordion
          panelName="quarantineHours"
          panelHeading="Customs/Immigration/Quarantine hours"
          panelBodyHeading="Are Customs/Immigration/Quarantine (CIQ) hours limited in the Main Terminal?"
          panelContent={
            <AccordionRadioGroups
              radioValue={ciqRadio}
              onRadioChange={handleRadioChange}
              radioGroupData={quarantineHoursData}
            />
          }
        />
      </Box>
      {isQuarantineHoursYes && (
        <>
          <Box>
            <CustomAccordion
              panelName="ciqOperationalHours"
              panelHeading="CIQ - Operational Hours (in local time)"
              panelContent={
                <MainOperationalHours
                  handleSave={handleSaveCIQ}
                  locationHoursList={vendorLocationHoursStore.quarantineAirportHoursList}
                />
              }
            />
          </Box>
          <Box>
            <CustomAccordion
              panelName="ciqOvertime"
              panelHeading="CIQ overtime"
              panelBodyHeading="Is CIQ overtime possible?"
              panelContent={
                <AccordionRadioGroups
                  radioValue={vendorLocationStore.overTimeValue}
                  onRadioChange={onOvertimeValueChange}
                  radioGroupData={ciqOvertimeData}
                  isOverTime={true}
                />
              }
            />
          </Box>
        </>
      )}
    </div>
  );
};

export default inject(
  'vendorLocationStore',
  'settingsStore',
  'vendorLocationHoursStore'
)(withStyles(styles)(observer(MainTerminalOnly)));
