import React, { FC, ReactNode, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, withStyles } from '@material-ui/core';
import { styles } from '../../OperationalHours.styles';
import { CustomAccordion, LocationHoursModel } from '../../../../../Shared';
import { IAPIGridRequest, IClasses, UIStore } from '@wings-shared/core';
import { useGridState } from '@uplink-shared/custom-ag-grid';
import { RootDataStore } from '@uplink-shared/layout';
import { VendorLocationHoursStore, VendorLocationStore } from '../../../../../../Stores';
import { finalize, takeUntil, catchError, mergeMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { AlertStore } from '@uvgo-shared/alert';
import { useUnsubscribe } from '@wings-shared/hooks';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import MainOperationalHours from 
  '../../../../Airport/AirportHours/Components/MainTerminalOnly/MainOperationalHours/MainOperationalHours';

interface Props {
  classes?: IClasses;
  vendorLocationStore: VendorLocationStore;
  vendorLocationHoursStore: VendorLocationHoursStore;
}

const PrivateHandlerFbo: FC<Props> = ({ classes, vendorLocationStore, vendorLocationHoursStore }) => {
  const gridState = useGridState();
  const unsubscribe = useUnsubscribe();

  useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = () => {
    vendorLocationStore.isTimeChanged = false;
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Id',
          propertyValue: RootDataStore.locationData.locationId,
        },
      ]),
    };
    vendorLocationHoursStore.getVendorLocationHours(request).subscribe(() => UIStore.setPageLoader(false));
  };

  const errorHandler = (errors: { [key: string]: string[] }): void => {
    Object.keys(errors).forEach(key => {
      const errorMessages = errors[key];
      errorMessages.forEach(message => {
        AlertStore.info(message);
      });
    });
  };

  const handleErrorResponse = error => {
    if (error.response?.data?.errors) {
      errorHandler(error.response?.data?.errors);
      return;
    }
    if (error?.message) {
      AlertStore.info(error?.message);
    }
  };

  const deleteAllRecords = () => {
    const ids =
      vendorLocationHoursStore.locationHoursList && vendorLocationHoursStore.locationHoursList.map(item => item.id);
    if (ids.length === 0) {
      ModalStore.close();
      return;
    }
    UIStore.setPageLoader(true);
    vendorLocationHoursStore
      ?.deleteLocationHours(ids)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: LocationHoursModel[]) => {
          AlertStore.info('Airport Hours saved successfully!');
          loadInitialData();
          ModalStore.close();
        },
        error: error => {
          handleErrorResponse(error);
        },
      });
  };

  const handleSave = () => {
    const hoursIdZeroData = vendorLocationHoursStore.updatedHoursData.filter(item => item.id === 0);
    const hoursIdOtherData = vendorLocationHoursStore.updatedHoursData.filter(item => item.id !== 0);

    if (hoursIdZeroData.length === 0 && hoursIdOtherData.length === 0) {
      deleteAllRecords();
      return;
    }

    if (hoursIdOtherData.length === 0) {
      deleteAllRecords();
    }
    const apiCalls = [];

    UIStore.setPageLoader(true);
    apiCalls.push(
      vendorLocationHoursStore
        ?.updateVendorLocationHour(LocationHoursModel.hoursSerializeList(hoursIdOtherData, 1))
        .pipe(
          mergeMap(() => {
            return forkJoin(
              vendorLocationHoursStore?.addVendorLocationHour(LocationHoursModel.hoursSerializeList(hoursIdZeroData, 1))
            );
          })
        )
        .pipe(
          catchError(error => {
            handleErrorResponse(error);
            return of(null);
          })
        )
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe(responses => {
          const allSuccessful = responses && responses.every(response => response !== null);
          if (allSuccessful) {
            AlertStore.info('Vendor Location Hours saved successfully!');
            loadInitialData();
            ModalStore.close();
          }
        })
    );
  };

  return (
    <div className="mainFBOWrapper">
      <Box marginBottom={2}>
        <CustomAccordion
          panelName="fboOperationalHours"
          panelHeading="Private Handler FBO - Operational Hours (in local time)"
          panelContent={
            <MainOperationalHours
              locationHoursList={vendorLocationHoursStore.locationHoursList}
              handleSave={handleSave}
            />
          }
        />
      </Box>
    </div>
  );
};

export default inject(
  'vendorLocationStore',
  'vendorLocationHoursStore'
)(withStyles(styles)(observer(PrivateHandlerFbo)));
