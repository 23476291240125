import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';

@modelProtection
export class DataExpirationSectionTypeModel extends CoreModel implements ISelectOption {
  id: number = 0;
  name: string = '';
  isFirstReminderEnabled: boolean = false;
  isSecondReminderEnabled: boolean = false;
  isThirdReminderEnabled: boolean = false;
  revalidationFrequencyInDays: number = 0;

  constructor(data?: Partial<DataExpirationSectionTypeModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: DataExpirationSectionTypeModel): DataExpirationSectionTypeModel {
    if (!apiData) {
      return new DataExpirationSectionTypeModel();
    }
    const data: Partial<DataExpirationSectionTypeModel> = {
      ...apiData,      
    };
    return new DataExpirationSectionTypeModel(data);
  }

  static deserializeList(apiDataList: DataExpirationSectionTypeModel[]): DataExpirationSectionTypeModel[] {
    return apiDataList ? apiDataList.map(apiData => DataExpirationSectionTypeModel.deserialize(apiData)) : [];
  }

  public get label(): string {
    return this.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
