import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Box, FormControlLabel, Radio, RadioGroup, Typography, withStyles } from '@material-ui/core';
import { styles } from '../../../OperationalInformation/Handler/OperationalHours/OperationalHours.styles';

interface Props {
  radioValue: number;
  onRadioChange: (value: number) => void;
  radioGroupData: { id: number; value: string; label: string }[];
  isOverTime?: boolean;
  label?: string;
}

const AccordionRadioGroups: FC<Props> = ({ radioValue, onRadioChange, radioGroupData, isOverTime = false, label }) => {
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    onRadioChange(value);
  };

  return (
    <Box>
      {label && <Typography className="radioLabel">{label}</Typography>}
      <RadioGroup className="radioGroupWrapper" value={radioValue} onChange={handleRadioChange}>
        {radioGroupData.map(item => (
          <FormControlLabel
            key={item.id}
            value={item.id}
            control={<Radio color={isOverTime ? 'secondary' : 'primary'} />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};

export default withStyles(styles)(observer(AccordionRadioGroups));
