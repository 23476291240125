import React from 'react';
import { useStyles } from './CustomButton.style';
import { CustomLinkButton } from '@wings-shared/layout';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core';

const CustomButton = (props)=>{
  const classes = useStyles();
  return(
    <div className={`${classes.primaryButtonWithIcon} ${classes.defaultButton}`}>
      <CustomLinkButton 
        variant={props.variant} 
        startIcon={props.startIcon} 
        to={props.to} 
        title={props.title} 
        isPrimary={props.isPrimary}
        disabled={props.disabled} 
        onClick={props.onClick}
      />
    </div>
  )
}
export default CustomButton;
// export default inject()(withStyles(styles)(observer(CustomButton)));