import React, { FC, useEffect } from 'react';
import { useUnsubscribe } from '@wings-shared/hooks';
import { CityModel, CountryModel, useBaseUpsertComponent } from '@uplink/shared';
import { fields } from './Fields';
import { IAPISearchFiltersDictionary, IClasses, UIStore } from '@wings-shared/core';
import { RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { Box, FormControlLabel, IconButton, Radio, RadioGroup, Typography, withStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { styles } from './Slide2.styles';
import { SettingsStore, SlideOneAndTwoStore, VendorLocationStore } from '../../../../Stores';
import { newVendorOnboarding, SETTING_ID, VendorOnBoardSlideOneNTwoModel } from '../../../Shared';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import Questionnaire from '../../../Shared/Components/Questionaire/Questionaire';
import { finalize, takeUntil } from 'rxjs/operators';
import { AuthStore } from '@uplink-shared/security';
import { EditIcon } from '@uvgo-shared/icons';

interface Props {
  classes?: IClasses;
  settingsStore: SettingsStore;
  slideOneAndTwoStore: SlideOneAndTwoStore;
  searchFilters: IAPISearchFiltersDictionary;
  registerSaveData: (saveData: () => void) => void;
  activeStep: number;
  setActiveStep: React.Dispatch<number>;
  vendorLocationStore: VendorLocationStore;
  onNextButtonDisable: (boolean) => void;
}

interface Pattern {
  str: string;
  name: string;
  id: number;
}

interface Question {
  str: string;
  identifier: string;
}

const mappedArray: Pattern[] = [
  { str: 'A+1+B+0+', name: 'Supervision Only [On Site] - No Airside Access', id: 5 },
  { str: 'A+0+C+0+', name: 'Coordination', id: 6 },
  { str: 'A+1+B+1+D+1+', name: 'Full Service Ground Handler [On Site] - Airside Access', id: 1 },
  { str: 'A+1+B+1+D+0+', name: 'Supervising Ground Handler [On Site] - Airside Access', id: 3 },
  { str: 'A+0+C+1+E+1+', name: 'Supervising Ground Handler  [Travel Required] - Airside Access', id: 2 },
  { str: 'A+0+C+1+E+0+', name: 'Supervision Only [Travel Required] - No Airside Access', id: 4 },
];

const mappedQuestions: Question[] = [
  { str: 'Do you have an office at this airport?', identifier: 'A' },
  { str: 'Can you provide Airside Services?', identifier: 'B' },
  { str: 'Can an employee be dispatched to the airport to supervise?', identifier: 'C' },
  { str: 'Do you provide underwings services?', identifier: 'D' },
  { str: 'Can you provide Airside Services?', identifier: 'E' },
];

const Slide2: FC<Props> = ({
  classes,
  registerSaveData,
  settingsStore,
  searchFilters,
  slideOneAndTwoStore,
  activeStep,
  setActiveStep,
  vendorLocationStore,
  onNextButtonDisable,
}) => {
  const unsubscribe = useUnsubscribe();
  const useUpsert = useBaseUpsertComponent<VendorOnBoardSlideOneNTwoModel>({}, fields, searchFilters);

  useEffect(() => {
    registerSaveData(saveData);
    return () => registerSaveData(null);
  }, []);

  const saveData = () => {
    upsertSlideOnAndTwo();
  };

  useEffect(() => {
    onNextButtonDisable(!vendorLocationStore.operationTypeId);
  }, [ vendorLocationStore.operationTypeId ]);

  useEffect(() => {
    SidebarStore.setNavLinks(newVendorOnboarding(), 'vendor', 'Vendor Locations', '/vendor/locations');
    settingsStore.getSettings( SETTING_ID.SETTINGS_OPERATON_TYPE).subscribe();
    if (AuthStore.vendorProfile?.id) {
      loadInitialData();
    }
  }, [ activeStep ]);

  useEffect(() => {
    if (activeStep === 1 && !vendorLocationStore.operationTypeId) {
      ModalStore.open(
        <Questionnaire
          isNavigate={true}
          useUpsert={useUpsert}
          settingsStore={settingsStore}
          setActiveStep={setActiveStep}
        />
      );
    }
  }, [ activeStep, vendorLocationStore.operationTypeId ]);

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
  };

  const getStrById = (id: number): string => {
    const pattern = mappedArray.find(item => item.id === id);
    return extractQuestionsAndAnswers(pattern?.str);
  };

  const extractQuestionsAndAnswers = (patternStr: string) => {
    const questionAnswerPairs = [];
    const parts = patternStr.split('+').filter(part => part !== '');

    for (let i = 0; i < parts.length; i += 2) {
      const questionIdentifier = parts[i];
      const answer = parts[i + 1];

      const questionObj = mappedQuestions.find(q => q.identifier === questionIdentifier);

      if (questionObj) {
        questionAnswerPairs.push({
          question: questionObj.str,
          answer: answer === '1' ? 'Yes' : 'No',
        });
      }
    }

    return questionAnswerPairs;
  };

  const upsertSlideOnAndTwo = () => {
    UIStore.setPageLoader(true);
    const request = new VendorOnBoardSlideOneNTwoModel({ ...useUpsert.form.values() });
    const operationTypeId = vendorLocationStore.operationTypeId;
    slideOneAndTwoStore
      .upsertSlideOneNTwo(request.serialize(operationTypeId), operationTypeId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: VendorOnBoardSlideOneNTwoModel) => {
          RootDataStore.setLocation(
            RootDataStore.locationData.locationId ? true : false,
            0,
            response.locationName,
            AuthStore.vendorProfile.code,
            response.airportReference?.id || RootDataStore.locationData.airportReferenceId,
            response.locationUniqueCode
          );
          setActiveStep(2);
          slideOneAndTwoStore.activeStep = 2;
        },
        error: error => {
          if (error.response.data.errors) {
            errorHandler(error.response.data.errors, request.id.toString());
            return;
          }
          useUpsert.showAlert(error.message, request.id.toString());
        },
      });
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    slideOneAndTwoStore
      .getByVendorId(AuthStore.vendorProfile.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: VendorOnBoardSlideOneNTwoModel) => {
        if (response[0]) {
          useUpsert.setFormValues(VendorOnBoardSlideOneNTwoModel.deserialize(response[0]));
          if (response[0]?.vendorLocationCityReference) {
            setFormValues(response[0]?.vendorLocationCityReference);
          }
          RootDataStore.setLocation(
            RootDataStore.locationData.locationId ? true : false,
            0,
            response[0].locationName,
            AuthStore.vendorProfile.code,
            response[0].airportReference?.id || RootDataStore.locationData.airportReferenceId,
            response[0].locationUniqueCode
          );
          if (response[0].operationType) {
            vendorLocationStore.operationTypeId = response[0].operationType?.id;
            vendorLocationStore.operationTypeQuestions = getStrById(response[0].operationType?.id);
          } else {
            vendorLocationStore.operationTypeId = 0;
          }
        }
      });
  };

  const setFormValues = vendorAddress => {
    const countryModel = new CountryModel({
      id: vendorAddress?.countryReference.countryId,
      commonName: vendorAddress?.countryReference?.name,
      isO2Code: vendorAddress?.countryReference?.code,
    });
    const cityModel = new CityModel({
      id: vendorAddress?.cityReference?.cityId,
      cappsCode: vendorAddress?.cityReference?.code,
      commonName: vendorAddress?.cityReference?.name,
    });
    useUpsert.getField('hqAddressCountry').set(countryModel);
    useUpsert.getField('hqAddressCity').set(cityModel);
  };

  return (
    <>
      <div className={classes.editorWrapperContainer}>
        <Box className={classes.container}>
          <Typography gutterBottom className={classes.headerText}>
            Your answers to the questions:
          </Typography>
          {vendorLocationStore.operationTypeQuestions.length > 0 &&
            vendorLocationStore.operationTypeQuestions.map((qa, index) => (
              <div key={index} className={classes.questionLine}>
                <Typography className={classes.indexText}>{index + 1}.</Typography>
                <div>
                  <Typography className={classes.questionText}>{qa.question}</Typography>
                  <RadioGroup className={classes.answerText} value={qa.answer}>
                    {qa.answer === 'Yes' && (
                      <FormControlLabel value="Yes" control={<Radio className={classes.radio} />} label="Yes" />
                    )}
                    {qa.answer === 'No' && (
                      <FormControlLabel value="No" control={<Radio className={classes.radio} />} label="No" />
                    )}
                  </RadioGroup>
                </div>
              </div>
            ))}
          <Typography className={classes.resultText}>Result:</Typography>
          <div className={classes.resultLine}>
            <Typography className={classes.operationTypeText}>
              {useUpsert.getField('appliedOperationType').value?.name ||
                useUpsert.getField('appliedOperationType').value?.operationType?.name}
            </Typography>
            <IconButton
              className={classes.imageIcon}
              onClick={() =>
                ModalStore.open(
                  <Questionnaire
                    isNavigate={true}
                    setActiveStep={setActiveStep}
                    useUpsert={useUpsert}
                    settingsStore={settingsStore}
                  />
                )
              }
            >
              <EditIcon />
            </IconButton>
          </div>
        </Box>
      </div>
    </>
  );
};

export default inject(
  'settingsStore',
  'slideOneAndTwoStore',
  'vendorLocationStore'
)(withStyles(styles)(observer(Slide2)));
