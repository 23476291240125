import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { Airports } from '../Airports.model';
import { VendorLocationCountryModel } from '../VendorLocationCountry.model';
import { SettingBaseModel } from '../SettingBase.model';
import { AuthStore } from '@uplink-shared/security';

@modelProtection
export class VendorOnBoardSlideOneNTwoModel extends CoreModel implements ISelectOption {
  id: number = 0;
  userId: string = '';
  vendorId: number = 0;
  airportReference: Airports = new Airports();
  vendorLocationCityReferenceModel: VendorLocationCountryModel = new VendorLocationCountryModel();
  locationName: string = '';
  companyLegalName: string = '';
  companyWebsite: string = '';
  accountReceivableContactName: string = '';
  accountReceivableContactPhone: string = '';
  appliedOperationType: SettingBaseModel = new SettingBaseModel();
  appliedOperationTypeId: number = 0;
  tempLocationId: string = '';
  locationUniqueCode: string = '';
  slide4Answer: string = ';'
  slide5Answer: string = ';'
  slide6Answer: string = ';'

  constructor(data?: Partial<VendorOnBoardSlideOneNTwoModel>) {
    super(data);
    Object.assign(this, data);
  }

  public serialize(operationTypeId?: number): VendorOnBoardSlideOneNTwoModel {
    return {
      id: this.id || 0,
      userId: this.userId,
      vendorId: AuthStore.vendorProfile.id,
      airportReference: this.airportReference?.airportId
        ? {
          airportId: this.airportReference.airportId,
          airportName: this.airportReference.airportName,
          icaoCode: this.airportReference.icaoCode,
          uwaCode: this.airportReference.uwaCode,
          faaCode: this.airportReference.faaCode,
          iataCode: this.airportReference.iataCode,
          regionalCode: this.airportReference.regionalCode,
          displayCode: this.airportReference.displayCode,
        }
        : null,
      vendorLocationCityReferenceModel: this.hqAddressCountry?.id ? this.serializeCountry() : null,
      locationName: this.locationName,
      companyLegalName: this.companyLegalName,
      companyWebsite: this.companyWebsite,
      accountReceivableContactName: this.accountReceivableContactName,
      accountReceivableContactPhone: this.accountReceivableContactPhone,
      operationTypeId: operationTypeId ? operationTypeId : this.appliedOperationType.id || null,
      tempLocationId: this.tempLocationId ? this.tempLocationId : null,
      slide4Answer: this.slide4Answer,
      slide5Answer: this.slide5Answer,
      slide6Answer: this.slide6Answer,
    };
  }

  public serializeCountry = (): VendorLocationCountryModel => {
    return new VendorLocationCountryModel({
      id: this.id || 0,
      cityId: this.hqAddressCity?.id,
      cityName: this.hqAddressCity?.commonName,
      cityCode: this.hqAddressCity?.cappsCode,
      countryId: this.hqAddressCountry?.id,
      countryCode: this.hqAddressCountry?.isO2Code,
      countryName: this.hqAddressCountry?.commonName,
    });
  };

  static deserialize(apiData: VendorOnBoardSlideOneNTwoModel): VendorOnBoardSlideOneNTwoModel {
    if (!apiData) {
      return new VendorOnBoardSlideOneNTwoModel();
    }
    const data: Partial<VendorOnBoardSlideOneNTwoModel> = {
      ...apiData,
      airportReference: apiData.airportReference
        ? Airports.deserializeAirportReference(apiData.airportReference)
        : null,
      vendorLocationCityReferenceModel: VendorLocationCountryModel.deserialize(
        apiData?.vendorLocationCityReference || apiData?.vendorLocationCityReferenceModel
      ),
      appliedOperationType: SettingBaseModel.deserialize(apiData.operationType),
    };
    return new VendorOnBoardSlideOneNTwoModel(data);
  }

  static deserializeList(apiDataList: VendorOnBoardSlideOneNTwoModel[]): VendorOnBoardSlideOneNTwoModel[] {
    return apiDataList ? apiDataList.map(apiData => VendorOnBoardSlideOneNTwoModel.deserialize(apiData)) : [];
  }

  public get label(): string {
    return this.locationName;
  }

  public get value(): string | number {
    return this.id;
  }
}
