import { auditFields } from '@uplink/shared';

export const fields = {
  ...auditFields,
  id: {
    label: 'Document Id',
  },
  operationTypeDocument: {
    label: 'Document Name*',
    rules: 'required',
  },
  status:{
    label: 'Document Status',
  },
  documentUri:{
    label: 'Document*',
    rules: 'string|required',
  },
  startDate:{
    label: 'Start Date*',
    rules: 'required'
  },
  endDate:{
    label: 'Expiration Date',
  },
  lastUpdated:{
    label: 'Last Updated'
  }
}